import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendMessage, socket, name, waitForSocketConnection, vocalise } from '../../socket';

import './TitleBar.css';

const TitleBar = ({ title, parentNode }) => {
  let history = useHistory();
  const [language, setLanguage] = useState('');
  const [languages, setLanguages] = useState(['Arabic', 'Cantonese', 'English', 'Greek', 'Hindi', 'Italian', 'Japanese', 'Korean', 'Mandarin', 'Russian', 'Serbian', 'Polish', 'Sinhala', 'Filipino', 'Macedonian']);
  const logout = () => {
    console.log("LogoUT")
    localStorage.setItem('remoteId', "");
    localStorage.setItem('peerId', "");

    history.push('/login')
  }

  const onHome = (e) => {
    console.log("HOME ON CLICK", e);
    let name = localStorage.getItem('remoteId');
    var music = `{"api":"serviceTerminate", "action":"get","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(music) })
  

    var cast = `{"api":"handleStopCast", "action":"stop", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(cast) })
    if (e === undefined || e === "undefined") {
      history.push('/home')
    } else {
      history.push(e)
    }
    window.location.reload();
    
  }

  const [gotSongs, setGotSongs] = useState(false);
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    console.log("Use effect");
    if (localStorage.getItem("pageLanguage") === null) {
      setLanguage("English")
    } else {
      setLanguage(localStorage.getItem("pageLanguage"))
    }

  }, [])

  // useEffect(() => {

  //   let name = localStorage.getItem('remoteId');
  //   if (!name) {
  //     // history.push('/home')
  //   }
  //   let isMounted = false; // note this flag denote mount status
  //   if (socket.readyState === socket.OPEN) {
  //     // open
  //   }
  //   if (!gotSongs) {

  //     // var music = `{"api":"music", "action":"get","name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(music) })

  //     // var servicesStop = `{"api":"serviceTerminate", "action":"get","name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(servicesStop) })
  //     // // waitForSocketConnection( socket , () => { vocalise("HELLO I AM MATILDA")  } )
  //     // // Stop Music
  //     // var musicstop = `{"api":"music", "action":"stop", "name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(musicstop) });

  //     // // Stop Book
  //     // var book = `{"api":"book", "action":"stop","name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(book) });

  //     // // Youtube Stop
  //     // var setYouTubeStop = `{"api":"setYouTubeStop", "url": "test", "action":"get","name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(setYouTubeStop) });

  //     // // Motion Stop
  //     // var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(api) });

  //     // // Cast Stop
  //     // var cast = `{"api":"handleStopCast", "action":"stop", "name": "${name}"}`;
  //     // waitForSocketConnection(socket, () => { sendMessage(cast) });
  //   }
  //   socket.onmessage = (message) => {
  //     console.log("MESSAGE HERE TITLE BAR ", message)
  //     if ((message.data) && (message.data.toString().includes("songs"))) {
  //       var str = message.data.substring(0, message.data.length - 1);
  //       console.log(str)
  //       try {
  //         //var obj = JSON.parse(str.replace(/\0/g, ''));
  //         var obj = str.replace(/\0/g, '');

  //         obj = JSON.parse(obj);
  //         console.log("===========")
  //         console.log(obj)
  //         console.log("===========")
  //         console.log(typeof (obj))
  //         console.log("===========")
  //         console.log(obj['type']);
  //         console.log("===========")
  //         console.log(obj['songlist']);

  //         if (!isMounted && obj.type == "songs") {
  //           setGotSongs(true);
  //           setSongs(obj.songlist);
  //           console.log(obj.songlist);
  //         }
  //         return () => {
  //           isMounted = true;
  //         };
  //       } catch (e) {
  //         console.log(e)
  //         return false;
  //       }
  //     }



  //   };
  // }, [gotSongs, songs]);

  const updateLanguage = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem('pageLanguage', e.target.value);
    window.location.reload();
  }

  return (
    <div className="titleBar">
      <a onClick={() => onHome(parentNode)} value={"value"}>
        <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 9.5L12 4L21 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M19 13V19.4C19 19.7314 18.7314 20 18.4 20H5.6C5.26863 20 5 19.7314 5 19.4V13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </a>
      <h3>{title}</h3>
      <div>
        {
          localStorage.getItem("userRole") == "ADMIN" ?
            <>
              <small><a href='/admin'>Admin</a></small>&nbsp;&nbsp;
            </>
            :
            <>
              <small><a href='/manage-profile'>Profile</a></small>&nbsp;&nbsp;
            </>
        }
        {/* <FormControl fullWidth>
          <InputLabel htmlFor="content-label">Language</InputLabel> */}
          <Select value={language} onClick={updateLanguage}>
            {languages.map((categoria, i) => (
              <MenuItem key={i} value={categoria}>{categoria}</MenuItem>
            ))}
          </Select>
        {/* </FormControl> */}
        <Button onClick={logout} variant="outlined" color="error">
          Logout
        </Button>
      </div>
    </div>
  )
};

export default TitleBar;