import React from "react";
import TitleBar from "../Common/TitleBar";
import Button from '@material-ui/core/Button';
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { CardContent, Grid, Card, CardHeader, CardActionArea } from "@material-ui/core";
import { name } from "../../socket";


function CardImage({ faceData }) {
    console.log('face data ', faceData.length);
    return (
        <>
            <br /><br />
            <Grid container spacing={2}>
                {faceData.map((data, i) => {
                    return (
                        <>
                            <Grid>
                                <Card>
                                    <CardContent>
                                        <small>Image {i}</small> <br/>
                                        <img src={`data:image/jpg;base64,${data.base64}`} className="author-image" height={100} width={100} />
                                    </CardContent>
                                    <CardActionArea>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<small>{data.date.split(" ")[0]}</small>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            &nbsp;
                            &nbsp;
                        </>
                    )
                })}
            </Grid>
        </>
    )
}

const FaceDetection = () => {
    const [faceData, setFaceData] = useState([]);

    useEffect(() => {
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/detect-faces?robot_id=' + name,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setFaceData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);


    return (
        <>
            <TitleBar title="Face Detection" parentNode={"/technology-tools"}/>
            <Button size="small" type="primary" variant="outlined">Resync data</Button>&nbsp;
            <CardImage faceData={faceData} />
        </>
    );
};

export default FaceDetection;