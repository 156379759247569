import React from 'react';

function Footer() {
    return (
        <footer className="row col-12" id="footer">
                <ul style={{textAlign:'center'}}>
                   
                    @2021 Human Centred Innovations
                </ul>
        </footer>
    )
}

export default Footer;