import { Button } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import qs from 'qs';

const MFAPage = () => {
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory();

    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can handle the submission of the OTP
        console.log('Submitted OTP:', otp);
        // Reset the OTP field after submission
        let data = qs.stringify({
            'To': '+639654171440',
            'Code': otp
        });

        let config = {
            method: 'post',
            url: 'https://verify.twilio.com/v2/Services/VA06979f39e05b1defdf93b4244c15092b/VerificationCheck',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic QUNiYzBhODAyYzdlNjJkNzNjOWEwMDcyZTY2M2Y4ODEyZTo5MWJiMmMwMmUzYWQ5M2IxNDIyODFmN2VjMmE5Y2UwYQ=='
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setOtp('');
                setMessage('You have successfully authenticated! You will be redirected.');
                history.push('/home')
            })
            .catch((error) => {
                console.log(error);
                setMessage('You have entered invalid otp. Please try again!')
            });
    };

    const handleRegenerate = () => {
        // Here you can trigger the regeneration of a new OTP
        console.log('Regenerate OTP');
        // Set a flag to indicate that regeneration has been triggered

        let data = qs.stringify({
            'To': '+639654171440',
            'Channel': 'sms'
        });

        let config = {
            method: 'post',
            url: 'https://verify.twilio.com/v2/Services/VA06979f39e05b1defdf93b4244c15092b/Verifications?',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic QUNiYzBhODAyYzdlNjJkNzNjOWEwMDcyZTY2M2Y4ODEyZTo5MWJiMmMwMmUzYWQ5M2IxNDIyODFmN2VjMmE5Y2UwYQ=='
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setMessage("New OTP has been generated!")
            })
            .catch((error) => {
                console.log(error);
                setMessage("Failed to generate OTP. Please try again")
            });
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Two-Factor Authentication</h2>
            <p>Please enter the OTP you received</p>
            <form onSubmit={handleSubmit} style={{ display: 'inline-block' }}>
                <label>
                    OTP: &nbsp;&nbsp;&nbsp;
                    <input type="text" value={otp} onChange={handleChange} />
                </label>
                <br />
                <Button onClick={handleSubmit}>Submit OTP</Button>
            </form>
            <br />
            <Button onClick={handleRegenerate}>Regenerate OTP</Button>
            <br />
            {message && <small>{message}</small>}
        </div>
    );
};

export default MFAPage;
