import React, { Component, useEffect, useState } from 'react';
import { sendMessage, socket, vocalise, waitForSocketConnection } from '../../socket';
import axios from 'axios';
import MainContent from './MainContent';
import { set } from 'date-fns';
import Timer from './Timer';
import Hero from './Hero';

function BigThankYouScreen() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Thank you for playing!</h1>
      <p style={styles.message}>Hope to see you again.</p>
      {/* Add any additional content or elements here */}
    </div>
  );
}

// CSS styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  },
  message: {
    fontSize: '24px',
    color: '#666',
    marginBottom: '40px',
  },
};

function ProgressBar({ duration, progressKey }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let intervalId;
    const steps = duration / 100;
    const increment = 100 / steps;

    // Start progress bar animation
    intervalId = setInterval(() => {
      setProgress(prevProgress => {
        const nextProgress = prevProgress + increment;
        return nextProgress > 100 ? 100 : nextProgress;
      });
    }, 100);

    // Clean up interval on unmount
    return () => {
      clearInterval(intervalId);
      setProgress(0);
      console.log('Resetting');
    };
  }, [duration, progressKey]);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
}

const ImageQuiz = () => {
  const [imageRootUrl, setImageRootUrl] = useState(window.location.host + "/images/");
  const [questions, setQuestions] = useState([]);
  const [questionIdx, setQuestionIdx] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [setOfAnswer, setSetOfAnswer] = useState([]);
  const [showContinueBtn, setShowContinueBtn] = useState(false);
  const [enableContinueBtn, setEnableContinueBtn] = useState(false);
  const [timerLimit, setTimerLimit] = useState(5);
  const [showResult, setShowResult] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(true);
  const [question, setQuestion] = useState();
  const [isPlaying, setIsPlaying] = useState(true);
  const [cuRemote, setCuRemote] = useState();

  const [progressKey, setProgressKey] = useState(0); // Initialize the key value

  // Function to change the key value and reset the progress bar
  const resetProgressBar = () => {
    setProgressKey(prevKey => prevKey + 1);
  };

  const getQuiz = (title, imageRoot) => {
    let config = {
      method: 'get',
      url: 'https://cloud-api-389807.ue.r.appspot.com/quiz?name=' + title,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        setQuestions(response.data.data.data);
        playNextQuestion(response.data.data.data, 0, imageRoot)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const playNextQuestionInner = (questions, idx, imageRoot) => {
    var api = `{"api":"QUZASK", "options":"${questions[idx]["options"]}", "answer": "${questions[idx]["answer"]}","name": "${cuRemote}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });

    setTimeout(() => playNextQuestion(questions, idx + 1, imageRoot), 8000);
  }

  const playNextQuestion = (questions, idx, imageRoot) => {
    if (idx < questions.length) {
      resetProgressBar()
      console.log('Question ', questions[idx]);
      // waitForSocketConnection(socket, () => { vocalise(questions[idx]["text"]) });
      setQuestion(questions[idx]["text"]);
      setImageUrl(imageRoot + questions[idx]["image"]);
      setCorrectAnswer(questions[idx]["answer"]);
      setSetOfAnswer(questions[idx]["options"]);
      setQuestionIdx(idx + 1);
      setImageRootUrl(imageRoot);

      const urls = window.location.href.split("/");
      console.log("URLS ", urls)
      const quizTitle = urls[urls.length - 1];
      const remoteId = urls[urls.length - 2];
      // setTimeout(() => playNextQuestionInner(questions, idx, imageRoot), 3000);

      var api = `{"api":"QUZASK", "options":"${questions[idx]["options"]}", "question": "${questions[idx]["text"]}" ,"answer": "${questions[idx]["answer"]}","name": "${remoteId}"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) });

      setTimeout(() => playNextQuestion(questions, idx + 1, imageRoot), 15000);
    } else {
      waitForSocketConnection(socket, () => { vocalise("Thank you for playing quiz with me!") });
      setIsPlaying(false);
    }
  }

  useEffect(() => {
    const urls = window.location.href.split("/");
    console.log("URLS ", urls)
    const quizTitle = urls[urls.length - 1];
    const remoteId = urls[urls.length - 2];

    setCuRemote(remoteId);

    if (window.location.href.startsWith('http')) {
      getQuiz(quizTitle, "http://" + imageRootUrl);
    } else {
      getQuiz(quizTitle, "https://" + imageRootUrl);
    }

    socket.onmessage = (message) => {
      console.log('Websocket message ', message);
    };

  }, []);

  return (<>
    {
      isPlaying ?
        <>
          <MainContent
            imageUrl={imageUrl}
            correctAnswer={correctAnswer}
            setOfAnswer={setOfAnswer}
            showContinueBtn={showContinueBtn}
            enableContinueBtn={enableContinueBtn}
            timerLimit={10}
            showResult={() => {
              playNextQuestion(questions, questionIdx, imageRootUrl);
            }}
            hasPhoto={hasPhoto}
            question={question}
          />

          <ProgressBar duration={12000} progressKey={progressKey} />
        </>
        :
        <BigThankYouScreen />
    }
  </>);
};

export default ImageQuiz;