import React from "react";
import TitleBar from "../Common/TitleBar";
import Button from '@material-ui/core/Button';
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import MonitoringV2 from "./MonitoringV2";
import { name } from "../../socket";
import { useTable } from 'react-table';

function CardImage({ faceData }) {
    console.log('face data ', faceData.length);
    return (
        <div class="wrapperForGrid">
            {faceData.map((data, i) => {
                return (
                    <>
                        <div>
                            <img src={`data:image/jpg;base64,${data.base64}`} className="author-image" height={100} width={100} />
                            <br></br>
                            <small><b>Date: </b>{data.date.split(" ")[0]}</small><br></br>
                            <small><b>Emotion: </b>{data.emotion ? data.emotion : "Unrecognized"}</small><br></br>
                        </div>
                    </>
                )
            })}
        </div>
    )
}

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const EmotionRecognition = () => {
    const [faceData, setFaceData] = useState([]);
    const [content, setContent] = useState("main");
    const [contentlist, setContentlist] = useState([
        { "label": "Main", "value": "main" },
        { "label": "Music", "value": "music" },
        { "label": "Book", "value": "book" },
    ]);
    const [show, setShow] = useState(false);
    const [isMusic, setIsMusic] = useState("off");
    const [isBook, setIsBook] = useState("off");

    const reFetch = () => {
        let emoRecog = localStorage.getItem("emotionRecog");
        if (emoRecog === undefined || emoRecog === null || emoRecog === "undefined") {
            emoRecog = "main"
        }
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/detect-faces?robot_id=' + name + "&service=" + emoRecog,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setFaceData(response.data.data);
                console.log('joanna ', response.data.data);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setTimeout(reFetch, 2000);
            });
    }

    const fetchConfig = (openConfig) => {
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/emotion-config?robot_id=' + name,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setIsBook(response.data.data.emotion_book);
                setIsMusic(response.data.data.emotion_music);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                if (openConfig) {
                    setShow(true);
                }
            });

    }

    useEffect(() => {
        reFetch();

    }, []);

    const computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr';
        }
        return '1fr';
    }

    const computeScreenSize2 = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr 1fr';
        }
        return '1fr';
    }

    const handleContentChange = (e) => {
        localStorage.setItem("emotionRecog", e.target.value);
        setContent(e.target.value);
        reFetch();
    }

    const handleCloseConfig = () => {
        setShow(false);
    }

    const handleSaveConfig = () => {
        let data = JSON.stringify({
            "emotion_music": isMusic,
            "emotion_book": isBook,
            "robot_id": name
        });

        let config = {
            method: 'post',
            url: 'https://cloud-api-389807.ue.r.appspot.com/emotion-config',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setShow(false);
            });
    }

    const handleOpenConfig = () => {
        fetchConfig(true);
    }

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Date and Time',
                accessor: 'date',
            },
            {
                Header: 'Emotion',
                accessor: 'emotion',
            },
            {
                Header: 'Service',
                accessor: 'service',
            },
        ],
        []
    )

    return (
        <>
            <TitleBar title="Emotion Recognition" parentNode={"/technology-tools"} />
            <Button size="small" type="primary" variant="outlined" onClick={handleOpenConfig}>
                Service Configuration
            </Button> &nbsp;&nbsp;
            <FormControl style={{ width: "150px" }}>
                <Select defaultValue={content} value={content} id="select-content" onChange={handleContentChange} fullWidth>
                    {contentlist.map((categoria, i) => (
                        <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br></br>
            <Dialog
                open={show}
                onClose={handleCloseConfig}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCloseConfig}>
                    Emotion Recognition Configuration per Service
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={isMusic}
                            onChange={e => { setIsMusic(e.target.value); }}
                            value={isMusic}
                        >
                            <FormControlLabel value="on" control={<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} label="Music" />
                            <FormControlLabel value="on" control={<Radio />} label="On" />
                            <FormControlLabel value="off" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={isBook}
                            onChange={e => { setIsBook(e.target.value); }}
                            value={isBook}
                        >
                            <FormControlLabel value="on" control={<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} label="Book" />
                            <FormControlLabel value="on" control={<Radio />} label="On" />
                            <FormControlLabel value="off" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfig} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveConfig} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{
                display: 'grid',
                gap: '2rem',
                width: '100%',
                gridTemplateColumns: computeScreenSize(),
            }} id="blocks">
                <div><MonitoringV2 /></div>
                {/* <CardImage faceData={faceData} /> */}
                <Table columns={columns} data={faceData}/>
            </div>
            <small><i><b>Info:&nbsp;&nbsp;</b>Unrecognized images are resulted from too blurred images that can't be read by the system.</i></small>
        </>
    );
};

export default EmotionRecognition;