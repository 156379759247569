import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import AuthService from "../../services/auth.service";
import SelectedList from "./SelectedList";
import Grid from '@material-ui/core/Grid';
import ObjectForm from "./ObjectForm";
import zIndex from "@material-ui/core/styles/zIndex";
import { setDate } from "date-fns";
import QuestionList from "./QuestionList";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageUploadCard from "./ImageUploadCard";

import axios from "axios";
import TitleBar from "./TitleBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1D3557',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#A8DADC',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: '#1D3557',
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function EditQuiz() {
  const [spacing, setSpacing] = React.useState(50);
  const [selectedContent, setSelectedContent] = useState(null);
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const classes = useStyles();
  const [newtitle, setTitle] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openCata, setOpenCata] = React.useState(false);

  const [quizid, setQuizID] = useState('');
  const [question, setQuestion] = useState('');
  const [option1, setOption1] = useState('');
  const [option2, setOption2] = useState('');
  const [option3, setOption3] = useState('');
  const [option4, setOption4] = useState('');
  const [correct, setCorrect] = useState('');
  const [image, setImage] = useState();
  useEffect(() => {
    let mylist = [];
    AuthService.getQuizList().then(
      (response) => {

        response.data.quiz.map((item, i) => {
          mylist.push(
            item.replace(".quiz", ""),

          );
        });

        setData(mylist);
        console.log(response.data.quiz);
      },
      (error) => {

      }
    )
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenCata = () => {
    setOpenCata(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCata = () => {
    setOpenCata(false);
  };

  const handleAddCata = () => {
    setOpenCata(false);

    const newdata = data.push(newtitle);
    console.log(newdata);
    //setData(newdata);
  };


  const handleAddQuestion = () => {
    setOpen(false);
    upload();
    //alert(question+option1+option2+option3+option4+correct);
  };

  const itemClickCallBack = (index) => {
    //alert(data[index]+'.quiz');
    setQuizID(data[index]);
    let mylist = [];
    AuthService.getQuizContent(data[index] + '.quiz')

      .then((response) => {

        // response.data.quiz.map((item,i) => {
        //   mylist.push(
        //     item.text,         
        //   );      
        // });
        setContent(response.data.quiz);
      })
  }

  const contentCallBack = (index) => {
    // alert(content[index].text);

    setSelectedContent(content[index]);

  }

  const editCallBack = (index) => {

  }

  const deleteCallBack = (index) => {
    // data.splice(index, 1);
    const newdata = data.filter(item => item !== index);
    console.log(newdata);
    setData(newdata);

  }

  const editContentCallBack = (index) => {

  }

  const setTextValue = (event) => {

    setTitle(event.target.value);


  }

  const handleQuestion = (event) => {

    setQuestion(event.target.value);
  }
  const setOption = (index, value) => {
    if (index == 1) setOption1(value);
    if (index == 2) setOption2(value);
    if (index == 3) setOption3(value);
    if (index == 4) setOption4(value);
  }

  const handleCorrect = (event) => {
    setCorrect(event.target.value);
  };

  const handleSetImage = (value) => {
    setImage(value);
    alert(value.name);
  };

  const deleteContentCallBack = (index) => {
    const newdata = content.filter(item => item !== index);
    console.log(newdata);
    setContent(newdata);

  }

  const upload = () => {

    let data = new FormData();

    data.append("file", image);
    data.set("quizid", quizid);
    data.set("question", question);
    data.set("options", `${option1}#${option2}#${option3}#${option4}`);
    data.set("correct", correct);


    axios({
      method: "post",
      url: "http://localhost:8082/quiz/question",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

  };

  if (selectedContent) {

    return <div className={classes.root}><ObjectForm question={selectedContent} /></div>
  }
  return (

    <div>
      <TitleBar title={"Edit Quiz"} parentNode={"/technology-tools"}/>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h5>Quiz List</h5>
          <SelectedList input={data} selectCallback={itemClickCallBack} editCallback={editCallBack} deleteCallback={deleteCallBack} />
          <Button variant="outlined" onClick={handleOpenCata}>
            Add Quiz
          </Button>
          <Dialog open={openCata} onClose={handleClose}>
            <DialogTitle>Add Quiz</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Title"
                type="title"
                fullWidth
                variant="standard"
                onChange={setTextValue}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCata}>Cancel</Button>
              <Button onClick={handleAddCata}>Add</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={6}>
          <h5>Question List</h5>
          <QuestionList questions={content} selectCallback={contentCallBack} editCallback={editContentCallBack} deleteCallback={deleteContentCallBack} />
        </Grid>
      </Grid>
{/* 
      <h3>Needs to enhance</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={spacing}>

            <Grid key={0}>
              <SelectedList input={data} selectCallback={itemClickCallBack} editCallback={editCallBack} deleteCallback={deleteCallBack} />
              <div>
                <Button variant="outlined" onClick={handleOpenCata}>
                  Add Quiz
                </Button>
                <Dialog open={openCata} onClose={handleClose}>
                  <DialogTitle>Add Quiz</DialogTitle>
                  <DialogContent>



                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Title"
                      type="title"
                      fullWidth
                      variant="standard"
                      onChange={setTextValue}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseCata}>Cancel</Button>
                    <Button onClick={handleAddCata}>Add</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>

            <Grid key={1} >
              <QuestionList questions={content} selectCallback={contentCallBack} editCallback={editContentCallBack} deleteCallback={deleteContentCallBack} />
              <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                  Add Question
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Add Quiz</DialogTitle>
                  <DialogContent>
                    <RadioGroup aria-label="correct_answer" name="correct_answer" onChange={handleCorrect}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="question"
                            label="Question"
                            type="question"
                            fullWidth
                            variant="standard"
                            onChange={handleQuestion}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="op1"
                            label="Option 1"
                            type="question"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setOption(1, event.target.value) }}

                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="1" control={<Radio />} label="Correct" />
                        </Grid>

                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="op2"
                            label="Option 2"
                            type="question"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setOption(2, event.target.value) }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="2" control={<Radio />} label="Correct" />
                        </Grid>

                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="op3"
                            label="Option 3"
                            type="question"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setOption(3, event.target.value) }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="3" control={<Radio />} label="Correct" />

                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="op4"
                            label="Option 4"
                            type="question"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setOption(4, event.target.value) }}

                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="4" control={<Radio />} label="Correct" />
                        </Grid>

                      </Grid>







                    </RadioGroup>

                    <ImageUploadCard url={""} parentCallback={handleSetImage} />

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddQuestion}>Add</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>


          </Grid>
        </Grid>

      </Grid> */}

    </div>

  );
}
