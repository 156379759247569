import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TitleBar from '../Common/TitleBar';
import { PhotoAlbumOutlined, PlayArrowOutlined, VideoCallOutlined, VoiceChatOutlined, VoiceChatRounded } from '@material-ui/icons';
import './Family.css';
import { Avatar, Button, Card, CardActionArea, CardMedia, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import TranslationService from "../../services/translation.service";
import styled from 'styled-components';
import { useState } from 'react';
import { useTable } from 'react-table';
import { name, sendMessage, socket, waitForSocketConnection } from '../../socket';
import { useEffect } from 'react';
import YouTube from 'react-youtube';

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: 150,
    backgroundSize: 'cover',
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Family = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [isVideo, setIsVideo] = useState(true);
  const [songs, setSongs] = useState([]);
  const [title, setTitle] = useState("No selected item");
  const [url, setUrl] = useState("Please select one from the list");
  const [objectId, setObjectId] = useState();
  const [youtubeURL, setYoutubeURL] = useState("");
  const [showPlayer, setShowPlayer] = useState(false);
  const [isCast, setIsCast] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',

      },
      {
        Header: 'Action',
        accessor: 'url',
        Cell: ({ cell }) => (
          <div>
            <Button size="large" variant="outlined" color="primary" value={cell.row.index} onClick={handleStartClick}>Load</Button>
            <Button size="large" variant="outlined" color="secondary" value={cell.row.index} onClick={handleStopClick}>Unload</Button>
            <Button size="large" variant="outlined" value={cell.row.values.url} onClick={handleStartCast}>Cast to TV</Button>
          </div>
        )

      },
    ],
    []
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }

  const handleVideo = () => {
    setIsVideo(true);
  };

  const handlePhoto = () => {
    setIsVideo(false);
  };

  socket.onmessage = (message) => {
    if ((message.data) && (message.data.toString().includes("family_video"))) {
      var obj = JSON.parse(message.data);
      console.log("Family ", obj["object_data"]);
      setSongs(obj["object_data"]);
      localStorage.setItem('songs', JSON.stringify(obj["object_data"]));
    }
  }

  useEffect(() => {
    var api = `{"api":"getFamilyVideo", "action":"get","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  }, []);

  const handleStartClick = (e) => {
    let value = e.currentTarget.value;
    let items = JSON.parse(localStorage.getItem('songs'))
    let data = items.at(value);
    setTitle(data["title"]);
    setUrl(data["url"]);
    setObjectId(value);

    console.log("Youtube Playing: ", data["url"])
    let url = data["url"]
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    url = url.split("v=")[1]
    url = url.split("&")[0]
    console.log("URL ", url)
    setYoutubeURL(url)
    setShowPlayer(true);
    console.log('Value ', value, items, data);
  }

  const handleStopClick = (e) => {
    console.log("Youtube Stoppping: ", e.currentTarget.value)
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    setYoutubeURL("");
    setTitle("No Selected Item");
    setUrl("Please select one from the list");
    setShowPlayer(false);
  }

  const [player, setPlayer] = useState(null);

  const onReady = (e) => {
    setPlayer(e.target);
  };

  const onCurrentPlay = (e) => {
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    console.log("MONTION")
  };

  const onPlayHandler = () => {
    player.playVideo();
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    console.log("MONTION")
  };

  const onPauseHandler = () => {
    player.pauseVideo();
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  };

  const onCurrentPause = () => {
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  };

  const onCurrentEnd = () => {
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  };

  const handleStartCast = (e) => {
    console.log("Cast Starting ", e.currentTarget.value);
    let value = e.currentTarget.value;
    let items = JSON.parse(localStorage.getItem('songs'))
    let data = items.at(value);
    var cast = `{"api":"handleStartCastSite", "action":"start", "url": "${data['url']}", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(cast) });
    setIsCast(true);
  }

  const handleStopCast = (e) => {
    var cast = `{"api":"handleStopCast", "action":"start", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(cast) });
    setIsCast(false);
  }

  const handlePlayCast = (e) => {
    var cast = `{"api":"handlePlayCast", "action":"start", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(cast) });
  }

  const handlePauseCast = (e) => {
    var cast = `{"api":"handlePauseCast", "action":"start", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(cast) });
  }

  return (
    <Styles>
      <TitleBar title={computeTitle("Family Photos & Videos")}parentNode={"/friends-and-relatives"}  />
      <Button size="small" type="primary" variant="outlined" value="add" onClick={handleVideo}>Video</Button>
      <Button size="small" type="primary" variant="outlined" value="add" onClick={handlePhoto}>Photo</Button>

      {showPlayer ?
        <div style={{ width: "100%", alignItems: "center", alignSelf: "center" }}>
          <h3>{title}</h3>
          <p>{url}</p>
          <div>
            <YouTube
              videoId={youtubeURL}
              onReady={onReady}
              opts={{
                playerVars: {
                  controls: 0,
                  autoplay: 1,
                  rel: 0,
                  enablejsapi: 0,
                  showInfo: 0,
                  autohide: 1
                },
              }}
              onPlay={onCurrentPlay}
              onPause={onCurrentPause}
              onEnd={onCurrentEnd}
            />
          </div>
          <Button onClick={onPlayHandler} className="primary" color="primary">Play</Button>
          <Button onClick={onPauseHandler} className="primary" color="secondary">Pause</Button>
        </div>
        :
        <div></div>
      }

      {isVideo ?
        <div>
          {isCast ?
            <div style={{ width: "100%", alignItems: "center", alignSelf: "center" }}>
              <h3>Cast Controls</h3>
              <Button size="large" variant="outlined" onClick={handlePauseCast}>Pause</Button>
              <Button size="large" variant="outlined" onClick={handlePlayCast}>Play</Button>
              <Button size="large" variant="outlined" onClick={handleStopCast}>Stop Cast</Button>
            </div>
            :
            ""
          }
          <Table columns={columns} data={songs} />
        </div>
        :
        <></>
        // <CardBuild data={photos} />
      }
    </Styles>
  );
}

export default Family;