import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import mapboxgl from 'mapbox-gl';
import './Map.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';




const Map = () => {
  const mapContainerRef = useRef(null);

  const [lng, setLng] = useState(127);
  const [lat, setLat] = useState(-2.3);
  const [zoom, setZoom] = useState(2.5);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });



    var size = 100;

    // implementation of CustomLayerInterface to draw a pulsing dot icon on the map
    // see https://docs.mapbox.com/mapbox-gl-js/api/#customlayerinterface for more info
    var pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd: function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // called once before every frame where the icon will be used
      render: function () {
        var duration = 1000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.7 * t + radius;
        var context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 200, 200,' + (1 - t) + ')';
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };


    var geojson = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-77.032, 38.913]
        },
        properties: {
          title: 'Mapbox',
          description: 'Washington, D.C.'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-122.414, 37.776]
        },
        properties: {
          title: 'Mapbox',
          description: 'San Francisco, California'
        }
      }]
    };
    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.on('load', function () {
      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      map.addSource('points', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {
                'description':
                  '<strong>Robot ID: 00000110 </br>Location: Melbourne Innovation Centre</strong></br><nav> <a href="/switch">Online</a></nav> from 8.31 am on 26/11/2020',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [145.10707045767901, -37.688039225669904]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot2',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [145.02095745767406, -37.77804011980329]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot3',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [145.11797599182376, -37.512826465108276]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot4',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [144.838513352591, -37.813369796132385]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [144.9453004479078, -37.79840563667389]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [114.20814522882858, 22.41855228708002]
              }
            },

            {
              'type': 'Feature',
              'properties': {
                'description':
                  'Robot',
                'icon': 'theatre'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [114.19445952540059, 22.341962661944862]
              }
            }
          ]
        }
      });
      map.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        'layout': {
          'icon-image': 'pulsing-dot'
        }
      });
    });
    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    var markerHeight = 50, markerRadius = 10, linearOffset = 25;
    var popupOffsets = {
      'top': [0, 0],
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom': [0, -markerHeight],
      'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      'left': [markerRadius, (markerHeight - markerRadius) * -1],
      'right': [-markerRadius, (markerHeight - markerRadius) * -1]
    };
    

    map.on('click', 'points', function (e) {

      var coordinates = e.features[0].geometry.coordinates.slice();
      var description = e.features[0].properties.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup({ offset: popupOffsets, className: 'my-class' })
        .setLngLat(coordinates)
        .setHTML(description)
        .setMaxWidth("300px")
        .addTo(map);
    });






    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div >
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div>
      <div className='map-container' ref={mapContainerRef} />
    </div>
  );
};

export default Map;
