import React, { useState } from 'react';
import TitleBar from '../Common/TitleBar';
import { sendMessage, socket, waitForSocketConnection } from '../../socket';

const FileUploadPage = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [selectedCategory, setSelectedCategory] = useState('learning');
	const [message, setMessage] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event) => {
		setMessage('');
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleCategoryChange = (event) => {
		setSelectedCategory(event.target.value);
	};

	const handleSubmission = async () => {
		console.log('JOANNA ...', selectedCategory, selectedFile);
		const formData = new FormData();

		formData.append('file', selectedFile);
		formData.append('robot_id', localStorage.getItem('remoteId'));
		fetch('https://cloud-api-389807.ue.r.appspot.com/upload-file', {
			method: 'POST',
			body: formData,
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to upload file');
				}
				return response.text(); // Resolve the promise to get the text response (file ID)
			})
			.then((fileId) => {
				console.log('Success:', fileId); // This logs the actual file ID
				setMessage('Uploaded successfully')
				var api = `{"action":"upMoToRo","service":"${selectedCategory}" , "file_id":"${fileId}", "name":"${localStorage.getItem('remoteId')}" }`;
				waitForSocketConnection(socket, () =>  sendMessage(api) )
			})
			.catch((error) => {
				console.error('Error:', error); // This handles any errors
			});
	};

	const formContainerStyle = {
		margin: '20px auto',
		maxWidth: '500px',
		backgroundColor: '#ffffff',
		padding: '20px',
		borderRadius: '8px',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
	};

	const labelStyle = {
		display: 'block',
		marginBottom: '10px',
		fontSize: '16px',
		color: '#333',
	};

	const selectStyle = {
		width: '100%',
		padding: '10px',
		borderRadius: '4px',
		border: '1px solid #ccc',
		fontSize: '16px',
		marginBottom: '20px',
	};

	const fileInputStyle = {
		display: 'block',
		marginBottom: '20px',
		padding: '10px',
		width: '100%',
		borderRadius: '4px',
		border: '1px solid #ccc',
		fontSize: '16px',
	};

	const buttonStyle = {
		width: '100%',
		padding: '12px',
		backgroundColor: '#007BFF',
		color: 'white',
		border: 'none',
		borderRadius: '4px',
		cursor: 'pointer',
		fontSize: '16px',
	};

	const messageStyle = {
		marginTop: '20px',
		color: message.includes('successfully') ? 'green' : 'red',
		fontSize: '16px',
	};

	const fileDetailsStyle = {
		marginTop: '20px',
		backgroundColor: '#f7f7f7',
		padding: '10px',
		borderRadius: '4px',
		fontSize: '14px',
		lineHeight: '1.5',
	};

	return (
		<div>
			<TitleBar title={"Upload"} parentNode={"/technology-tools"} />
			<div style={formContainerStyle}>
				<div>
					<label htmlFor="category" style={labelStyle}>
						Choose a category:
					</label>
					<select
						id="category"
						value={selectedCategory}
						onChange={handleCategoryChange}
						style={selectStyle}
					>
						<option value="music">Music</option>
						<option value="book">Book</option>
						<option value="learning">Learning</option>
					</select>
				</div>
				<input
					type="file"
					name="quiz"
					onChange={changeHandler}
					style={fileInputStyle}
				/>
				{message && <p style={messageStyle}>{message}</p>}
				{isFilePicked ? (
					<div style={fileDetailsStyle}>
						<p><strong>Filename:</strong> {selectedFile.name}</p>
						<p><strong>Filetype:</strong> {selectedFile.type}</p>
						<p><strong>Size in bytes:</strong> {selectedFile.size}</p>
						<p><strong>Last modified date:</strong> {selectedFile.lastModifiedDate.toLocaleDateString()}</p>
					</div>
				) : (
					<p>Select a file to show details</p>
				)}
				<button onClick={handleSubmission} style={buttonStyle}>
					Submit
				</button>
			</div>
		</div>
	);
};

export default FileUploadPage;
