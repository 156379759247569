import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";


class Sudoku extends Component {
    constructor() {
        super();
        this.state = {
            showHow: false,
        };
    }

    showHowOpen = (e) => {
        this.setState({ 'showHow': true });
    }

    showHowClose = (e) => {
        this.setState({ 'showHow': false });
    }

    render() {
        return (
            <>
                <TitleBar title={"Sudoku"} />
                <Button onClick={this.showHowOpen}>How to play Sudoku?</Button>
                <Dialog
                    open={this.state.showHow}
                    onClose={this.showHowClose}
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>How To Play Sudoku?</DialogTitle>
                    <DialogContent>
                        <p>
                            Hello there, little Sudoku solver! 🧩 Are you ready to dive into the world of numbers and puzzles? Let's have a blast with Sudoku! Here's how you can do it:
                        </p>
                        <p>
                            <strong>Step 1: Meet the Grid</strong><br />
                            Look at the Sudoku grid. It's like a big square made of smaller squares. Each small square is called a "cell." Your goal is to fill these cells with numbers.
                        </p>
                        <p>
                            <strong>Step 2: Get Your Digits Ready</strong><br />
                            You'll use the numbers from 1 to 9 to fill the cells. But here's the trick: each row, column, and the big 3x3 square inside the grid can only have each number once. No repeats allowed!
                        </p>
                        <p>
                            <strong>Step 3: Start with the Clues</strong><br />
                            Some cells already have numbers in them. These are your clues! They help you get started. Look for rows, columns, and squares with missing numbers.
                        </p>
                        <p>
                            <strong>Step 4: Think, Think, Think</strong><br />
                            For each empty cell, think about what number could go there. Remember, you can't repeat numbers in a row, column, or 3x3 square. So choose wisely!
                        </p>
                        <p>
                            <strong>Step 5: Try and Try Again</strong><br />
                            Fill in the numbers you're sure about. Don't worry if you're not sure about some. Just give it your best shot. If things don't work out, you can always change the numbers later.
                        </p>
                        <p>
                            <strong>Step 6: Check Your Work</strong><br />
                            Once you've filled in numbers, check your rows, columns, and squares. Did you follow the rules? If everything looks good, you're on the right track!
                        </p>
                        <p>
                            <strong>Step 7: Keep Going</strong><br />
                            Use the numbers you've already placed to help you figure out the rest. Slowly, step by step, you'll fill in more and more cells.
                        </p>
                        <p>
                            <strong>Step 8: Celebrate!</strong><br />
                            When you've filled in all the cells and followed the rules, you've cracked the Sudoku code! Give yourself a high-five, because you've become a Sudoku superstar! 🌟
                        </p>
                        <p>
                            Remember, Sudoku is like a puzzle adventure. It might be a bit tricky at first, but with practice, you'll become a Sudoku champion in no time! Have a blast solving those puzzles! 🎉🧩🌈
                        </p>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                        <Button onClick={this.showHowClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Iframe url="https://mczak.com/code/sudoku/suframe/" width="100%" height="1000px" onLoad={() => { console.log("IFRAME ON LOAD") }}></Iframe>
            </>
            // <iframe frameborder="0" scrolling="no" src="https://mczak.com/code/sudoku/suframe/" style="border:0px solid #eee; overflow:hidden; width:400px; height:500px;" width="400"></iframe>
        );
    }


}

export default Sudoku;