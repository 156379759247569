import React, { useEffect, useState } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import TitleBar from '../Common/TitleBar';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`


function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}


const YouTube = () => {
    // Variables
    const [show, setShow] = useState(false);
    const [dialogMessage, setDialogMessage] = useState();
    const [title, setTitle] = useState();
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [url, setUrl] = useState();
    const [currentPlay, setCurrentPlay] = useState("Select a youtube link");
    let history = useHistory();

    // Effects
    useEffect(() => {
        let name = localStorage.getItem('remoteId');
        let isMounted = false;
        if (!name) {
            history.push('/login')
        }
        if (!gotSongs) {
            var api = `{"api":"getYouTubeList", "action":"get","name": "${name}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
        }

        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("setYouTubeStart"))) {
                let obj = JSON.parse(message.data);
                setCurrentPlay("Playing " + obj.url)
            }
            if ((message.data) && (message.data.toString().includes("youtube"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (!isMounted && obj.object_type == "youtube") {
                        console.log(obj.object_data);
                        setGotSongs(true);
                        setSongs(obj.object_data);
                    }
                    return () => {
                        isMounted = true;
                    };
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
        };
    }, [gotSongs, songs]);

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',

            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="large" variant="outlined" value={cell.row.values.url} onClick={handleStartClick}>Start</Button>
                        <Button size="large" variant="outlined" value={cell.row.values.url} onClick={handleStopClick}>Stop</Button>
                        <Button size="large" variant="outlined" value={cell.row.index} onClick={handleDelete}>Delete</Button>
                        <Button size="large" variant="outlined" value={cell.row.index} onClick={handleOpen}>Update</Button>
                    </div>
                )

            },


        ],
        []
    )

    // Functions
    const handleOpen = (e) => {
        if (e.currentTarget.value == "add") {
            setDialogMessage("Add New YouTube Link");
        } else {
            setDialogMessage("Update YouTube Link");
        }
        setShow(true);
    }

    const handleClose = (e) => {
        setShow(false);
    }

    const handleSave = (e) => {
        var data = { "title": title, "url": url };
        var message = `{"action":"setYouTubeData","event":${JSON.stringify(data)}, "name":"${name}" }`;
        waitForSocketConnection(socket, () => { sendMessage(message) });
        setShow(false);
    }

    const handleStartClick = (e) => {
        console.log("Handle Start Click", e.currentTarget.value);
        var api = `{"api":"setYouTubeStart", "url": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const handleStopClick = (e) => {
        console.log("Handle Stop Click");
        var api = `{"api":"setYouTubeStop", "url": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setCurrentPlay("Select a youtube link")
    }

    const handleDelete = (e) => {
        console.log("Handle Delete");
        var data = { "object_id": e.currentTarget.value };
        var api = `{"api":"setYouTubeDelete", "url": "${e.currentTarget.value}", "event":${JSON.stringify(data)},"name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    // Build Page
    return (
        <Styles>
            <TitleBar title="YouTube" />
            <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>Add YouTube</Button>
            <br /><i><small>{currentPlay}</small></i>
            <Dialog
                open={show}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>{dialogMessage}</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Title"
                        defaultValue={title}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setTitle(e.target.value); }}
                        value={title}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="URL"
                        defaultValue={url}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setUrl(e.target.value); }}
                        value={url}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <Table columns={columns} data={songs} />
        </Styles>
    )
}

export default YouTube;