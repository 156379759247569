import React, { Component, useState, useEffect } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';


const theme = createTheme();

const styles = (mtheme) => ({
    formControl: {
        margin: mtheme.spacing(0),
        minWidth: 250,
    },
});

class CastSelector extends Component {
    constructor() {
        super();
        this.state = {
            content: "",
            contentlist: [],
            msg: "Scanning devices",
        };
    }

    componentDidMount() {
        var cast = `{"api":"handleScan", "action":"get", "name": "${name}"}`;
        waitForSocketConnection(socket, () => sendMessage(cast));
        socket.onmessage = (message) => {
            console.log("MESSAGE ", message.data)
            if ((message.data) && (message.data.toString().includes("label"))) {
                var obj = JSON.parse(message.data);
                this.setState({ contentlist: obj });
                this.setState({ msg: "Done scanning devices" });
            }
        };
    }

    handleContentChange = (e) => {
        localStorage.setItem("castDevice", e.target.value);
        this.setState({ 'content': e.target.value });
    }

    render() {
        return (
            <div>
                <small>{this.state.msg}</small>
                <FormControl fullWidth>
                    <InputLabel htmlFor="content-label">Content</InputLabel>
                    <Select defaultValue={this.state.content} value={this.state.content} id="select-content" onChange={this.handleContentChange} fullWidth>
                        {this.state.contentlist.map((categoria, i) => (
                            <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export default withStyles(styles)(CastSelector);