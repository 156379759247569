import React, { useEffect, useState } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import TitleBar from '../Common/TitleBar';
import YouTube from 'react-youtube';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CastSelector from "./CastSelector";
import TranslationService from "../../services/translation.service";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
  console.log("COMPUTE TITLE")
  let language = localStorage.getItem("pageLanguage");
  if (language !== "English" && language !== undefined) {
    title = TranslationService.translateString(title, language);
    console.log("TITLE ", title)
  }
  return title
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{computeTitle(column.render('Header'))}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const TechnologySupport = () => {
  // Variables
  const [show, setShow] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [title, setTitle] = useState();
  const [songs, setSongs] = useState([]);
  const [gotSongs, setGotSongs] = useState(false);
  const [url, setUrl] = useState();
  const [showPlayer, setShowPlayer] = useState(false);
  const [player, setPlayer] = useState(null);
  const [isYoutube, setIsYoutube] = useState(true);
  const [youtubeURL, setYoutubeURL] = useState("");
  const [isCast, setIsCast] = useState(false);
  const [deviceScan, setDeviceScan] = useState(false);
  const [scanMsg, setScanMsg] = useState("Scanning devices...");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [deviceHolder, setDeviceHolder] = useState("");
  let history = useHistory();

  // Inner Function
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  // Effects
  useEffect(() => {
    let name = localStorage.getItem('remoteId');
    let isMounted = false;
    if (!name) {
      history.push('/login')
    }
    if (!gotSongs) {
      var api = `{"api":"getTechnologySupport", "action":"get","name": "${name}"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    socket.onmessage = (message) => {
      console.log("MESSAGE ", message.data)
      if ((message.data) && (message.data.toString().includes("technology_support"))) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log(str);
        try {
          var obj = JSON.parse(message.data);
          if (!isMounted && obj.object_type == "technology_support") {
            console.log(obj.object_data);
            setGotSongs(true);
            setSongs(transformData(obj.object_data));
          }
          return () => {
            isMounted = true;
          };
        } catch (e) {
          console.log(e);
          return false;
        }
      } else if ((message.data) && (message.data.toString().includes("label"))) {
        var obj = JSON.parse(message.data);
        setDeviceList(obj);
      }
    };
  }, [gotSongs, songs]);

  const transformData = (obj) => {
    let finalObject = []
    for (let i = 0; i < obj.length; i++) {
      obj[i]["title"] = computeTitle(obj[i]["title"])
      finalObject.push(obj[i])
    }
    return finalObject
  }

  // Memos
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',

      },
      {
        Header: 'Action',
        accessor: 'url',
        Cell: ({ cell }) => (
          <div>
            <Button size="small" type="primary" variant="outlined" value={cell.row.values.url} onClick={handleStartClick}>Start</Button>
            <Button size="small" type="primary" variant="outlined" value={cell.row.values.url} onClick={handleStopClick}>Stop</Button>
            {/* <Button size="small" type="primary" variant="outlined" value={cell.row.index} onClick={handleDelete}>Delete</Button>
            <Button size="small" type="primary" variant="outlined" value={cell.row.index} onClick={handleOpen}>Update</Button> */}
            <Button size="small" type="primary" variant="outlined" value={cell.row.values.url} onClick={handleStartCast}>Cast to TV</Button>
          </div>
        )

      },


    ],
    []
  )

  // Functions
  const handleOpen = (e) => {
    if (e.currentTarget.value == "add") {
      setDialogMessage("Add New Video");
    }
    setShow(true);
  }

  const handleClose = (e) => {
    setShow(false);
  }

  const handleSave = (e) => {
    var data = { "title": title, "url": url };
    var message = `{"action":"setYouTubeData","event":${JSON.stringify(data)}, "name":"${name}" }`;
    waitForSocketConnection(socket, () => { sendMessage(message) });
    setShow(false);
  }

  const handleDelete = (e) => {
    console.log("Handle Delete");
    var data = { "object_id": e.currentTarget.value };
    var api = `{"api":"setYouTubeDelete", "url": "${e.currentTarget.value}", "event":${JSON.stringify(data)},"name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  }

  const handleStartClick = (e) => {
    let value = e.currentTarget.value;
    setUrl(value);
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api);
    value = value.split("v=")[1];
    value = value.split("&")[0];
    setYoutubeURL(value);
    setShowPlayer(true);
  }

  const handleStopClick = (e) => {
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api);
    setYoutubeURL("");
    setShowPlayer(false);
  }

  const handleStartCast = (e) => {
    setIsCast(true);
    var cast = `{"api":"handleStartCast", "action":"start", "url": "${e.currentTarget.value}", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    socket.send(cast)
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  }

  const handleStopCast = (e) => {
    setIsCast(false);
    var cast = `{"api":"handleStopCast", "action":"stop", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    socket.send(cast)
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  }

  const handlePlayCast = (e) => {
    var cast = `{"api":"handlePlayCast", "action":"stop", "name": "${name}"}`;
    socket.send(cast)
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  }

  const handlePauseCast = (e) => {
    var cast = `{"api":"handlePauseCast", "action":"stop", "name": "${name}"}`;
    socket.send(cast)
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  }

  const handleScan = () => {
    setDeviceScan(true);
    var cast = `{"api":"handleScan", "action":"get", "name": "${name}"}`;
    socket.send(cast)
  }

  const handleCloseScan = () => {
    setScanMsg("Scanning devices...")
    setDeviceScan(false);
    setSelectedDevice();
    setDeviceList([]);
  }

  const handleContentChange = (e) => {
    e.preventDefault()
    console.log("DEVICE LIST ", deviceList, e.currentTarget.value)
    setSelectedDevice(deviceList[e.currentTarget.value].value)
    localStorage.setItem("castDevice", deviceList[e.currentTarget.value].value)
  };

  const handleSaveScan = (e) => {
    console.log("castDevice ", selectedDevice, e.currentTarget.value, deviceHolder)
    // localStorage.setItem("castDevice", selectedDevice);
    setScanMsg("Scanning devices...");
    setDeviceScan(false);
    setSelectedDevice();
    setDeviceList([]);
  };

  // Player Functions
  const onReady = (e) => {
    setPlayer(e.target);
  };

  const onCurrentPlay = (e) => {
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
    console.log("MONTION")
  };

  const onPlayHandler = () => {
    player.playVideo();
    var api = `{"api":"motion", "action":"start", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
    console.log("MONTION")
  };

  const onPauseHandler = () => {
    player.pauseVideo();
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  };

  const onCurrentPause = () => {
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  };

  const onCurrentEnd = () => {
    var api = `{"api":"motion", "action":"stop", "motion_id": "neutral", "name": "${name}"}`;
    socket.send(api)
  };

  const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }

  // Build Page
  return (
    <Styles>
      <TitleBar title={computeTitle("Technology Support")} parentNode={"/technology-tools"} />
      {/* <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>Add Video</Button> */}
      <Button size="small" type="primary" variant="outlined" value="add" onClick={handleScan}>{computeTitle('Scan Cast Devices')}</Button>
      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={handleClose}>{dialogMessage}</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Title"
            defaultValue={title}
            inputProps={{ maxLength: 200, }}
            onChange={e => { setTitle(e.target.value); }}
            value={title}
            fullWidth
          />
          <br />
          <TextField
            label="URL"
            defaultValue={url}
            inputProps={{ maxLength: 200, }}
            onChange={e => { setUrl(e.target.value); }}
            value={url}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deviceScan}
        onClose={handleCloseScan}
        fullWidth
      >
        <DialogTitle id="form-dialog-title" onClose={handleCloseScan}>Scan Devices</DialogTitle>
        <DialogContent dividers>
          {/* {scanMsg} */}
          <CastSelector />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseScan} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveScan} value={selectedDevice} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {isCast ?
        <div style={{ width: "100%", alignItems: "center", alignSelf: "center" }}>
          <b>Cast Controls</b><br />
          <Button size="small" type="primary" variant="outlined" onClick={handlePauseCast}>Pause</Button>
          <Button size="small" type="primary" variant="outlined" onClick={handlePlayCast}>Play</Button>
          <Button size="small" type="primary" variant="outlined" onClick={handleStopCast}>Stop Cast</Button>
        </div>
        :
        ""
      }
      {showPlayer ?
        <div style={{ width: "100%", paddingLeft: "5px" }}>
          <h3>{title}</h3>
          <p>{url}</p>
          <div>
            <YouTube
              videoId={youtubeURL}
              onReady={onReady}
              opts={{
                playerVars: {
                  controls: 0,
                  autoplay: 1,
                  rel: 0,
                  enablejsapi: 0,
                  showInfo: 0,
                  autohide: 1
                },
                allowFullScreen: true,
                height: getWindowDimensions().height,
                width: getWindowDimensions().width * 0.75
              }}
              onPlay={onCurrentPlay}
              onPause={onCurrentPause}
              onEnd={onCurrentEnd}
            />
          </div>
          <Button onClick={onPlayHandler} className="primary" color="primary">Play</Button>
          <Button onClick={onPauseHandler} className="primary" color="secondary">Pause</Button>
        </div>
        :
        ""
      }
      <Table columns={columns} data={songs} />
    </Styles>
  )
}

export default TechnologySupport;