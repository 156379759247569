import React, { Component } from 'react';
import axios from "axios";
import './css/ImageQuiz.css';
import './css/bootstrap-min.css';

import BingoMain from './BingoMain';
import Hero from './Hero';
import Continue from './Continue';
import Footer from './Footer';
import PlayAgain from './PlayAgain';
// import {vocalise, socket} from '../../socket';
// import {getLearningPhoto} from '../../services/auth.service';
import AuthService from "../../services/auth.service";
import { waitForSocketConnection, socket, sendMessage, name, vocalise } from "../../socket";
var stop = false;


const shuffle = (array) => {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const getBingoPhoto = (quizid, id) => {
  var message = `{"action":"getBingoLetter", "name":"${name}", "title": "${quizid}", "letter": "${id}" }`;
  waitForSocketConnection(socket, () => { sendMessage(message) });
  // return AuthService.getBingoPhoto(quizid+'/'+id);
};

const getDataApi = (_self) => {
  const getRandomItem = (arr) => {
    let item = arr[_self.state.qIndex];


    return item;
  };

  let index = _self.state.qIndex;
  if (index === _self.state.questions.length) _self.state.endOfQuiz = true;
  else {
    var qObj = getRandomItem(_self.state.questions);



    getQuestionPhoto(_self, _self.state.quizid, qObj.image);

    _self.setState({
      question: qObj.text,
      correctAnswer: '',
      setOfAnswer: [],
      showContinueBtn: false,
      qIndex: index + 1
    })
    vocalise(qObj.text);
  }


}

const getQuizContent = (_self, quizid) => {
  var message = `{"action":"getBingoSpecific", "name":"${name}", "title": "${quizid}"}`;
  waitForSocketConnection(socket, () => { sendMessage(message) });
}

const getQuestionPhoto = (_self, quizid, photoid) => {
  getBingoPhoto(quizid, photoid);
}

class Bingo extends Component {
  constructor(props) {
    super(props);

    this.enableContinueBtn = this.enableContinueBtn.bind(this);
    this.newPuzzle = this.newPuzzle.bind(this);
    this.showResult = this.showResult.bind(this);
  }

  static initialState = () => ({
    imageUrl: '',

    question: '',
    correctAnswer: '',
    setOfAnswer: [],
    savedResult: [],
    showContinueBtn: false,
    timerLimit: 60,
    isTimerReached: false,
    oneQuesAttemptTime: 60,
    questions: [],
    qIndex: 0,
    endOfQuiz: false,
    hasPhoto: false,
    stop: false

  })

  state = Bingo.initialState();

  showResult = () => {
    this.setState({
      isTimerReached: true,
      showContinueBtn: false
    })
  }

  resetQuiz = () => {
    var _self = this;
    this.setState(Bingo.initialState());
    getQuizContent(_self, this.props.quizid);
  }

  newPuzzle = () => {
    var _self = this;
    const allOptionsObj = document.querySelectorAll('.item-options .option');
    for (var obj of allOptionsObj) {
      obj.classList.remove('red', 'green');
      obj.disabled = false;
    }
    this.setState({
      imageUrl: ''
    })
    getDataApi(_self);
  }

  enableContinueBtn = (isAnswerCorrect) => {
    this.setState((prevState) => ({
      showContinueBtn: true,
      savedResult: prevState.savedResult.concat(isAnswerCorrect)
    }))
  }

  componentDidMount() {
    var _self = this;
    const quizid = this.props.quizid;

    this.setState({
      quizid: quizid

    })
    getQuizContent(_self, quizid);

    socket.onmessage = (message) => {
      var str = message.data.substring(0, message.data.length - 1);
      if (str === 'vocalise_done' && !this.state.stop && !this.state.endOfQuiz) {
        this.enableContinueBtn(true);
      }
      else if (str.includes(".png") && str.includes("text") && str.includes("image")) {
        let listOfItem = JSON.parse(message.data);
        listOfItem = listOfItem.data;

        this.setState({
          questions: shuffle(listOfItem)
        })

        getDataApi(this);
      }
      else {
        console.log('Bingo ', message.data.replaceAll("\"", ""));
        this.setState({
          imageUrl: message.data.replaceAll("\"", ""),
          hasPhoto: true
        })
      }

    }


  }

  componentWillUnmount() {
    socket.onmessage = null;
  }

  render() {
    let { imageUrl, question, hasPhoto } = this.state;
    return (
      <div className="container-fluid">
        <Hero question={question} />
        {!this.state.isTimerReached && (!this.state.endOfQuiz)
          ? <BingoMain
            imageUrl={imageUrl}
            caption={question}
            hasPhoto={hasPhoto}
          />
          : <div>

            <PlayAgain resetQuiz={this.resetQuiz} />
          </div>
        }
        <br />
        {(!this.state.endOfQuiz) && <Continue newPuzzle={this.newPuzzle} />}
        <Footer />
      </div>
    )
  }
}

export default Bingo;
