
import React, { Component, useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import styled from 'styled-components'
import { useTable } from 'react-table'
import { Input } from "reactstrap";
import { Link } from 'react-router-dom';
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const RobotStatus = () => {
    
    const [online_robots, setOnlineRobots] = useState([]);
    
    useEffect(() => {
        AuthService.getRobotStatus().then(
      (response) => {
        setOnlineRobots(response.data);
        
      },
      (error) => {
        const _content = 
          (error.response && error.response.data) || 
          error.message ||
          error.toString();
        
      }
    );
  }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Robot ID',
                accessor: 'id',
                Cell: ({ row }) => <a href= {`/control/${row.original.id}`}>{row.original.id}</a>
            },
            {
              Header: 'Robot Status',
              accessor: 'status',
            },
            {
              Header: 'Description',
              accessor: 'description',
            }
              
        ],
        []
    )
     
    return (


        <Styles>
      
      <Table columns={columns} data={online_robots} />
    </Styles>
 
      );
}

export default RobotStatus;