import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TitleBar from '../Common/TitleBar';
import { sendMessage, socket, waitForSocketConnection } from '../../socket';

const Styles = styled.div`
  padding: 0rem;
`;

const ConversationLog = () => {
  const [logContent, setLogContent] = useState('');

  useEffect(() => {
    socket.onmessage = (message) => {
      let obj = JSON.parse(message.data);
      setLogContent(obj.content);
    }
  }, []);

  useEffect(() => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"get_log", "name": "${name}", "robot_id": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })
  }, []);

  return (
    <Styles>
      <TitleBar title="Conversation Log" />
      <div>
        <h2>Log Content</h2>
        <pre>{logContent}</pre>
      </div>
    </Styles>
  );
};

export default ConversationLog;
