import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
// import Exercise from "./Exercise";
import AuthService from "../../services/auth.service";
import TitleBar from "../Common/TitleBar";
import axios from "axios";
import Cooking from "./Cooking";
import SelectedList from "../Common/SelectedList";


const useStyles = makeStyles((theme) => ({
    root: {

    },
    gridbase: {
        gap: '2rem',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
    },
    image: {
        position: 'relative',
        backgroundColor: '#fff!important',
        padding: '20px 10px!important',
        borderRadius: '0.75rem!important',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
        transition: ' 0.5s all',
        overflow: 'hidden',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1D3557',
    },
    imageSrc: {},
    imageBackdrop: {},
    imageTitle: {
        position: 'relative',
        color: '#333',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {},
}));

export default function CookingList() {

    const [selectquiz, setQuizId] = useState('');
    const [isSelected, setSelected] = useState(false);
    const [data, setData] = useState([]);
    const classes = useStyles();
    const [exerciseOptions, setExerciseOptions] = useState([]);

    useEffect(() => {
        let mylist = [];
        let mylist2 = [];

        let config = {
            method: 'get',
            url: 'http://192.168.163.30:8000/cooking/list',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                response.data.cooking_list.map((item, i) => {
                    mylist.push({
                        url: '/images/button.png',
                        title: item,
                    });
                    mylist2.push({
                        value: item,
                        label: item,
                    });
                });
                setData(mylist);
                setExerciseOptions(mylist2);
                console.log(mylist);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const imageClick = (title) => {
        setSelected(true);
        setQuizId(title);
    }

    if (isSelected) {
        return (
            <div>
                <div className="card m-0">
                    {<Cooking cooking_id={selectquiz} />}
                </div>
            </div>
        );

    } else
        return (

            <div className={classes.root}>
                <TitleBar title={"Cooking"} parentNode={"/school"} />
                <div className={classes.gridbase}>
                    {data.map((image) => (
                        <ButtonBase
                            onClick={() => imageClick(image.title)}
                            focusRipple
                            key={image.title}
                            className={classes.image}
                            focusVisibleClassName={classes.focusVisible}
                            style={{
                                width: image.width,
                            }}
                        >
                            <span
                                className={classes.imageSrc}
                                style={{
                                    backgroundImage: `url(${image.url})`,
                                }}
                            />
                            <span className={classes.imageBackdrop} />
                            <span className={classes.imageButton}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    {image.title}
                                    <span className={classes.imageMarked} />
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>

        );
}
