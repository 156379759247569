import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";

const styles = (theme) => ({});

class NewGames extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M21 3V21H3V3H21Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 16.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 7.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #d00f88, #ff48bb)',
                    title: 'Bingo',
                    slug: '/bingolist',
                    locations: ['games'],
                },
                {
                    svg: `
                        <path d="M7 16L17 16" stroke="currentColor" stroke-linecap="round"/>
                        <path d="M9 11L15 11" stroke="currentColor" stroke-linecap="round"/>
                        <path d="M10 4V6" stroke="currentColor" stroke-linecap="round"/>
                        <path d="M14 4V6" stroke="currentColor" stroke-linecap="round"/>
                        <path d="M17.4 9L6.6 9C6.26863 9 6 8.73137 6 8.4L6 4.6C6 4.26863 6.26863 4 6.6 4L17.4 4C17.7314 4 18 4.26863 18 4.6V8.4C18 8.73137 17.7314 9 17.4 9Z" stroke="currentColor" stroke-linecap="round"/>
                        <path d="M17.901 21L6.099 21C5.6243 21 5.3318 20.4737 5.57763 20.0676C6.7923 18.061 9.5 13.3271 9.5 11V9.59965C9.5 9.26828 9.76863 9 10.1 9H13.9C14.2314 9 14.5 9.26828 14.5 9.59965V11C14.5 13.3271 17.2077 18.061 18.4224 20.0676C18.6682 20.4737 18.3757 21 17.901 21Z" stroke="currentColor" stroke-linecap="round"/>
                    `,
                    background: 'linear-gradient(0deg, #560d0d, #8b1515)',
                    title: 'Chess',
                    slug: '/chess',
                    locations: ['games'],
                },
                {
                    svg: `
                        <path d="M14 15H17M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #1f6776, #2d93a8)',
                    title: 'Sudoku',
                    slug: '/sudoku',
                    locations: ['games'],
                },
                {
                    svg: `
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #1f6776, #2d93a8)',
                    title: 'Scrabble',
                    slug: '/scrabble',
                    locations: ['games'],
                },
                {
                    svg: `
                        <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="25px" viewBox="0 0 24 24" fill="#fff">
                        <path fill="#fff" fill-rule="evenodd" d="M12 3a2 2 0 0 0-1 3.732V8H8c-3.2 0-4 2.667-4 4v7c0 .667.4 2 2 2h1v-4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4h1c1.6 0 2-1.333 2-2v-7c0-3.2-2.667-4-4-4h-3V6.732A2 2 0 0 0 12 3zm3 18v-3h-2v3h2zm-4 0v-3H9v3h2zm10-3v-5c.667 0 2 .4 2 2v1c0 .667-.4 2-2 2zM3 13v5c-1.6 0-2-1.333-2-2v-1c0-1.6 1.333-2 2-2zm6-1a1 1 0 1 0 0 2h.001a1 1 0 1 0 0-2H9zm5 1a1 1 0 0 1 1-1h.001a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1z" clip-rule="evenodd"/>
                        </svg>
                    `,
                    background: 'linear-gradient(0deg, #1b6c72, #2498a1)',
                    title: 'Talk With Matilda',
                    slug: '/talk-with-matilda',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
    }

    componentDidMount() { }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Games")} parentNode={"/entertainment"} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: '1fr 1fr 1fr',
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(NewGames);