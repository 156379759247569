import React, { Component, useEffect, useState } from "react";
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@material-ui/core";
import styled from "styled-components";
import ImageUploadCard from "../Common/ImageUploadCard";
import axios from "axios";
import { name } from "../../socket";

const Styles = styled.div`
card: {
  display: "flex"
},
details: {
  display: "flex",
  flexDirection: "column"
},
content: {
  flex: "1 0 auto"
},
cover: {
  width: 151
},
controls: {
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing.unit,
  paddingBottom: theme.spacing.unit
},
playIcon: {
  height: 38,
  width: 38
}
trans: {
    background: rgba(0,0,0,0.5)
},
table {
  width:100%;
  border-collapse: separate;
  border-spacing: 5px;
  padding-top: 20px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th{
    margin: 0;
    padding: 10px 10px;
    background: #ccc;
    border-radius: 10px;
    font-size: 15px;
  }
  td {
    margin: 0;
    padding: 3px 12px;
    background: #fff;
    font-size: 15px;
    color:#6e6e6e;
    border-radius: 10px;
    :last-child {
      border-right: 0;
    }
  }
}
`

function SectionList({ sections, setSections }) {
    const [phrase, setPhrase] = useState('');

    const handleSetImage = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64 = e.target.result;
                console.log(base64);
                const newSectionData = [...sections];
                newSectionData[sections.length - 1].img = base64;
                setSections(newSectionData);
            };

            reader.readAsDataURL(file);
        }
    };


    const handleUpdatePhrase = (index, newPhraseValue) => {
        const newSectionData = [...sections];
        newSectionData[index].phrase = newPhraseValue;
        setSections(newSectionData);
    };

    const handleDeleteSection = (indexToDelete) => {
        const updatedSections = sections.filter((_, index) => index !== indexToDelete);
        setSections(updatedSections);
    };


    return (
        <div>
            {sections.map((section, index) => (
                <div key={index} className="section">
                    <hr></hr>
                    <strong>Module Page #{index + 1}</strong><br />
                    {
                        sections.length != index + 1 ?
                            <>
                                <small><strong>Image to Show</strong></small><br />
                                {section.img ? <img src={section.img} alt={`Section ${index}`} height={"500px"} width={"500px"} /> : <small>No Image Provided</small>}
                                <br />
                                <small><strong>Phrases to vocalise</strong></small>
                                <br />
                                {section.phrase ? <small>{section.phrase}</small> : <small>No phrase provided</small>}
                            </>
                            :
                            <>
                                <small>Image</small>
                                <br />
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .gif" // Specify accepted file types if needed
                                    onChange={handleSetImage}
                                />
                                <br />
                                <TextField
                                    label="Phrase"
                                    defaultValue={section.phrase}
                                    inputProps={{ maxLength: 1000 }}
                                    onChange={e => { handleUpdatePhrase(index, e.target.value) }}
                                    value={section.phrase}
                                />
                                <br />
                            </>
                    }
                    <br />
                    <Button size="small" type="primary" variant="outlined" onClick={() => handleDeleteSection(index)}>
                        Delete Section
                    </Button>

                </div>
            ))}
        </div>
    );
}

const TrainingModule = () => {
    const [moduleName, setModuleName] = useState('');
    const [sections, setSections] = useState([]);

    const handleAddSection = () => {
        // Create a new array based on the current sections
        const newSectionData = [...sections, { img: '', phrase: '' }];
        // Update the state with the new array
        setSections(newSectionData);
    };

    const handleSaveModule = () => {
        console.log("Module Name ", moduleName);
        console.log("Sections", sections);
        let data = JSON.stringify({
            "robot_id": name,
            "sections": sections,
            "module_name": moduleName
        });

        let config = {
            method: 'post',
            url: 'https://cloud-api-389807.ue.r.appspot.com/training-module',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                window.location = window.location.origin + '/training-module-list'
            })
            .catch((error) => {
                console.log(error);
            });

    }


    return (
        <Styles>
            <TitleBar title={"Create Training Module"} parentNode={"/training-module-list"}/>
            <TextField
                label="Module Name"
                defaultValue={moduleName}
                inputProps={{ maxLength: 200 }}
                onChange={e => { setModuleName(e.target.value) }}
                value={moduleName}
            />
            <br />
            <SectionList sections={sections} setSections={setSections} />
            <Button size="small" type="secondary" variant="outlined" onClick={handleAddSection}>Add Section</Button>
            <br />
            <br />
            <hr />
            <Button size="small" type="secondary" variant="outlined" onClick={handleSaveModule}>Save Training Module</Button>
        </Styles>
    )
};

export default TrainingModule;