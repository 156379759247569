import React, { Component, useState, useEffect } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import { withStyles } from '@material-ui/core/styles';
import TitleBar from '../Common/TitleBar';
import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import { set } from "date-fns";


const styles = (mtheme) => ({
    formControl: {
        margin: mtheme.spacing(0),
        minWidth: 250,
    },
});

class TalkWithMatilda extends Component {
    constructor() {
        super();
        this.state = {
            selectedService: null,
            value: 'one',
            events: [],
            selectedLanguage: 'english',
            contentList: [
                { 'label': 'English', 'value': 'english' },
                { 'label': 'Mandarin', 'value': 'mandarin' },
                { 'label': 'Japanese', 'value': 'japanese' },
                { 'label': 'Cantonese', 'value': 'cantonese' }
            ],
            selectedAction: 'start',
            actionList: [
                { 'label': 'Start', 'value': 'start' },
                { 'label': 'Stop', 'value': 'stop' },
            ],
            selectedBGMusic: '',
            selectedBGAnswer: '',
        };
        this.onChangeAction = this.onChangeAction.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
        this.onChangeBGMusic = this.onChangeBGMusic.bind(this);
        this.onChangeBGAnswer = this.onChangeBGAnswer.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        const name = localStorage.getItem('remoteId');
        const api = `{"api":"getTalkConfig", "action":"get","name": "${name}"}`
        waitForSocketConnection(socket, () => { sendMessage(api) })

        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("bg_thinking"))){
                const str = message.data.substring(0, message.data.length);
                try {
                    const obj = JSON.parse(str.replace(/\0/g, ''));
                    const config = obj.data
                    this.setState({ selectedBGMusic: config.bg_thinking })
                    this.setState({ selectedBGAnswer: config.bg_answer })
                  } catch (e) {
                    return false;
                  }
            }
        }
     }

    onChangeAction(e) {
        this.setState({ selectedAction: e.target.value });
    }

    onChangeLanguage(e) {
        this.setState({ selectedLanguage: e.target.value });
    }

    onSubmit(e) {
        var api = `{"api":"chatConversation", "language":"${this.state.selectedLanguage}", "action": "${e.target.value}","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    onChangeBGMusic(e) {
        this.setState({ selectedBGMusic: e.target.value });
    }

    onChangeBGAnswer(e) {
        this.setState({ selectedBGAnswer: e.target.value });
    }

    onSave(e) {
        const name = localStorage.getItem('remoteId');
        const api = `{"api":"updateTalkConfig", "bg_thinking": "${this.state.selectedBGMusic}", "bg_answer": "${this.state.selectedBGAnswer}", "name": "${name}"}`
        waitForSocketConnection(socket, () => { sendMessage(api) })
    }

    render() {
        return (
            <div>
                <TitleBar title={this.computeTitle("Talk With Matilda")} parentNode={"/entertainment"} />
                <div>
                    <h5>{this.computeTitle("Configuration")}</h5>
                    <br />
                    {this.computeTitle("Select Language")} &nbsp;&nbsp;
                    <Select defaultValue={this.state.selectedLanguage} value={this.state.selectedLanguage} id="select-content" fullWidth onChange={this.onChangeLanguage}>
                        {this.state.contentList.map((categoria, i) => (
                            <MenuItem key={i} value={categoria.value}>{this.computeTitle(categoria.label)}</MenuItem>
                        ))}
                    </Select>
                    <br />
                    {/* {this.computeTitle('Select Action')} &nbsp;&nbsp; */}
                    {/* <Select defaultValue={this.state.selectedAction} value={this.state.selectedAction} id="select-content" fullWidth onChange={this.onChangeAction}>
                        {this.state.actionList.map((categoria, i) => (
                            <MenuItem key={i} value={categoria.value}>{this.computeTitle(categoria.label)}</MenuItem>
                        ))}
                    </Select> */}
                    {/* <br /> */}
                    <br />
                    {/* {this.computeTitle('Select Action')} &nbsp;&nbsp;
                    <br/><br/> */}
                    <button onClick={this.onSubmit} value={'start'}>{this.computeTitle('Start')}</button>
                    &nbsp;
                    <button onClick={this.onSubmit} value={'stop'}>{this.computeTitle('Stop')}</button>
                </div>
                <br />
                <br />
                <div>
                    <h5>Additional Settings</h5>
                    <br />
                    <FormControl component="fieldset">
                        Background music while thinking
                        <RadioGroup
                            name="on-off"
                            value={this.state.selectedBGMusic}
                            onChange={this.onChangeBGMusic}
                            row
                        >
                            <FormControlLabel
                                value="on"
                                control={<Radio color="primary" />}
                                label="On"
                            />
                            <FormControlLabel
                                value="off"
                                control={<Radio color="primary" />}
                                label="Off"
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl component="fieldset">
                        Background music when got answer
                        <RadioGroup
                            name="on-off"
                            value={this.state.selectedBGAnswer}
                            onChange={this.onChangeBGAnswer}
                            row
                        >
                            <FormControlLabel
                                value="on"
                                control={<Radio color="primary" />}
                                label="On"
                            />
                            <FormControlLabel
                                value="off"
                                control={<Radio color="primary" />}
                                label="Off"
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <button onClick={this.onSave}>Save</button>
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(TalkWithMatilda);