import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";
import { Middleware } from "./Middleware";


const styles = (theme) => ({});

class ServiceSchedulingAndManagement extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M15 4V2M15 4V6M15 4H10.5M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10H3Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 10V6C3 4.89543 3.89543 4 5 4H7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7 2V6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21 10V6C21 4.89543 20.1046 4 19 4H18.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>    
                        `,
                    background: 'linear-gradient(0deg, #0f6f11, #0fa112)',
                    title: 'Calendar based Service Scheduling',
                    slug: '/calendar',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-lightbulb" viewBox="0 0 16 16"> <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/> </svg>
                    `,
                    background: 'linear-gradient(0deg, #1d2974, #2f41b2)',
                    title: 'Smart Home',
                    slug: '/smart-home',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg fill="white" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M746.667 106.667H1173.33V1493.33H746.667V106.667ZM533.333 533.333H106.667V1493.33H533.333V533.333ZM1920 1706.67H0V1824H1920V1706.67ZM1813.33 746.667H1386.67V1493.33H1813.33V746.667Z"></path> </g></svg>
                    `,
                    background: 'linear-gradient(0deg, #1f6776, #2d93a8)',
                    title: 'Analytics',
                    slug: '/analytics',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
            allowedAcccess: []
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
    }

    componentDidMount() {
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        Middleware()

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access});
            })
            .catch((error) => {
                console.log(error);
            });
     }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Service Scheduling And Management")} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        this.state.allowedAcccess.includes(app.title) && <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(ServiceSchedulingAndManagement);