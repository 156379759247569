import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import MemoryGame from "./MemoryGame";
import TicTacToe from "./TicTacToe";
import Maths from "./Maths";
import Chess from "./Chess";
import TitleBar from '../Common/TitleBar';
import AppsMeta from '../Common/AppsMeta';
import { sendMessage, socket  , name ,waitForSocketConnection} from '../../socket';
import { useHistory } from "react-router-dom";
import TranslationService from "../../services/translation.service";


const gamesApps = AppsMeta.filter((app) => { 
  return app.locations.indexOf('games') > -1;
})

const computeTitle = (title) => {
  console.log("COMPUTE TITLE")
  let language = localStorage.getItem("pageLanguage");
  if (language !== "English" && language !== undefined) {
    title = TranslationService.translateString(title, language);
    console.log("TITLE ", title)
  }
  return title
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gap: '2rem',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
 
  image: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    color: '#fff',
    justifyContent: 'flex-start',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageSrc': {
        transform: 'scale(1.2)',
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '0',
      },
    },
  },
  '&$focusVisible': {
    background: '#000',
    opacity: 1,
  },
  imageButton: {
  },
  imageSrc: {
    transition: '0.5s ease',
    display: 'flex',
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    background: '#000',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
      width: '150px',
    }
  },
  imageSVG: {
    width: '65px',
    height: '65px',
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
      width: '85px',
    }
  },
  imageTitle: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  imageMarked: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity'),
  },
}));

export default function Games() {
  
  const [selectquiz,setQuizId] = useState('');
  const [isSelected,setSelected] = useState(false);
  var interval = 10;
  var times = 1;
  const history = useHistory();

  const classes = useStyles();

  const appClick = (title, slug) => {
    console.log('Hitting app.click')
    setSelected(true);
    console.log(slug)
    setQuizId(slug);
  } 

  const handler = (val) => {
    setSelected(val);
  }

  useEffect(() => {
    
    let name=localStorage.getItem('remoteId');
    if(!name){
       history.push('/login')
    }
  },[]);
  if (isSelected)
  {
    const name=localStorage.getItem('remoteId');
    if (selectquiz == '/memorygame')
    {

      var prompt = 'Matilda wants to  play a memory game with you?'
      var api = `{"action":"tellPrompt","prompt":"${prompt}" , "time":${times}  , "interval":${interval} , "name":"${name}" }`;
      waitForSocketConnection(socket, () =>  sendMessage(api) )
      return (<div className={classes.root}>{<MemoryGame />}</div>);
    }
    if (selectquiz == '/tic-tac-toe')
    {
      var prompt = 'Matilda wants to  play Tic Tac Toe game with you?'
      var api = `{"action":"tellPrompt","prompt":"${prompt}" , "time":${times}  , "interval":${interval} , "name":"${name}" }`;
      waitForSocketConnection(socket, () =>  sendMessage(api) )
      //return (<div className={classes.root}>{<TicTacToe />}</div>);
    }
    if (selectquiz == '/maths')
    {
      var prompt = 'Matilda wants to  play a math game with you?'
      var api = `{"action":"tellPrompt","prompt":"${prompt}" , "time":${times}  , "interval":${interval} , "name":"${name}" }`;
      waitForSocketConnection(socket, () =>  sendMessage(api) )
      //return (<div className={classes.root}>{<Maths />}</div>);
    }
    if (selectquiz == '/chess')
    {
      var prompt = 'Matilda wants to  play a chess game with you?';
      var api = `{"action":"tellPrompt","prompt":"${prompt}" , "time":${times}  , "interval":${interval} , "name":"${name}" }`;
      waitForSocketConnection(socket, () =>  sendMessage(api) )
      return (<div className={classes.root}>{<Chess />}</div>);
    }
    alert('Message Sent')
    return (
      <>
        <div className="">
          <TitleBar title={computeTitle("Games")} />
        </div>
  
        <div className={classes.root}>
        {gamesApps.map((app) => (
            <ButtonBase
              onClick={() => appClick(app.title, app.slug)}
              focusRipple
              key={app.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
            >
              <span className={classes.imageSrc} style={{
                background: app.background
              }}>
                <svg className={classes.imageSVG}
                   width="24" 
                   height="24" 
                   strokeWidth="1.5" 
                   viewBox="0 0 24 24" 
                   fill="none" 
                   xmlns="http://www.w3.org/2000/svg"
                   dangerouslySetInnerHTML={{ __html: app.svg }}  />
              </span>
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {app.title}
                </Typography>
              </span>
            </ButtonBase>
        ))}
        </div>  
      </>)
    //alert(selectquiz);
    //setSelected(false);
    
   
  }else
    return (
    <>
      <div className="">
        <TitleBar title={computeTitle("Games")} parentNode={"/entertainment"} />
      </div>

      <div className={classes.root} id="blocks">
      {gamesApps.map((app) => (
          <ButtonBase
            onClick={() => appClick(app.title, app.slug)}
            focusRipple
            key={app.title}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
          >
            <span className={classes.imageSrc} style={{
              background: app.background
            }}>
              <svg className={classes.imageSVG}
                 width="24" 
                 height="24" 
                 strokeWidth="1.5" 
                 viewBox="0 0 24 24" 
                 fill="none" 
                 xmlns="http://www.w3.org/2000/svg"
                 dangerouslySetInnerHTML={{ __html: app.svg }}  />
            </span>
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                {app.title}
              </Typography>
            </span>
          </ButtonBase>
      ))}
      </div>  
    </>
          
  );
}
