import React, { Component } from "react";
import Iframe from 'react-iframe'


class MemoryGame extends Component {
	

	render() {
        return (
            <Iframe url="https://gamesv2.de.r.appspot.com" width="100%" height="1000px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
            // <Iframe url="http://192.168.163.30:8001/memory-game-master/" width="100%" height="1000px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
        );
    }

	
}

export default MemoryGame;