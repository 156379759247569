import React, { Component } from "react";
import Iframe from 'react-iframe'


class Maths extends Component {
	

	render() {
        return (
            <Iframe url="http://192.168.163.30:8001/math-game/math.html" width="100%" height="1000px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
        );
    }

	
}

export default Maths;