import React from 'react'

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { sendMessage, socket, waitForSocketConnection, name, vocalise } from '../../socket';
import 'audio-react-recorder/dist/index.css'
import UserService from "../../services/user.service";
import TitleBar from './TitleBar';
import axios from 'axios';

class AudioRecorder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      recordState: null,
      audioData: null
    }
  }

  start = () => {
    this.setState({
      recordState: RecordState.START
    })
  }

  pause = () => {
    this.setState({
      recordState: RecordState.PAUSE
    })
  }

  stop = () => {
    this.setState({
      recordState: RecordState.STOP
    })
  }

  onStop = (data) => {
    this.setState({
      audioData: data
    })
    console.log('onStop: audio data', data)
  }

  send = () => {
    console.log("SEND DATA ", this.state.audioData);
    var reader = new FileReader();
    reader.readAsDataURL(this.state.audioData.blob);
    reader.onloadend = function () {
      var base64String = reader.result;
      console.log('Base64 String - ', base64String);
      // base64String = "123"
      // var snd = new Audio(base64String);
      // snd.play();
      // var api = `{"api":"sendAudioMessage","name": "${name}", "robot_id": "Matilda_872d-g", "email": "marie@gmail.com"}`;
      // var api = `{"api":"sendAudioMessage","name": "${name}"}`;
      // waitForSocketConnection(socket, () => { sendMessage(api) });
      let data = JSON.stringify({
        "robot_id": localStorage.getItem("remoteId"),
        "email": "marie@gmail.com",
        "base64_data": base64String
      });

      let config = {
        method: 'post',
        url: 'https://cloud-api-389807.ue.r.appspot.com/user-vm?robot_id=' + localStorage.getItem("remoteId") + '&email=marie@gmail.com',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      waitForSocketConnection(socket, () => { vocalise("You have incoming message") })

      axios.request(config)
        .then((response) => {
          console.log("sendAudioMessage");
          var api = `{"api":"sendAudioMessage","name": "${name}"}`;
          waitForSocketConnection(socket, () => { sendMessage(api) });
        })
        .catch((error) => {
          console.log(error);
        });

    }
  }

  render() {
    const { recordState } = this.state

    return (
      <div className="">
        {/* <TitleBar title="Messenger" parentNode={"/friends-and-relatives"} /> */}
        <div>
          <AudioReactRecorder
            state={recordState}
            onStop={this.onStop}
            backgroundColor='rgb(255,255,255)'
          />
          <audio
            id='audio'
            controls
            src={this.state.audioData ? this.state.audioData.url : null}
          ></audio>
          <button id='record' onClick={this.start}>
            Start
          </button>
          <button id='pause' onClick={this.pause}>
            Pause
          </button>
          <button id='stop' onClick={this.stop}>
            Stop
          </button>
          <button id='send' onClick={this.send}>
            Send
          </button>
        </div>
      </div>
    )
  }
}

export default AudioRecorder
