import React, { useState, useEffect } from "react";
import TitleBar from '../Common/TitleBar';
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";
import { Button, ButtonBase, Typography, makeStyles } from "@material-ui/core";
import Exercise from "./Exercise";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1D3557',
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#A8DADC',
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: '#1D3557',
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
}));

export default function ExerciseList() {
    // Variables
    const [selectquiz, setQuizId] = useState('');
    const [isSelected, setSelected] = useState(false);
    const [data, setData] = useState([]);
    const classes = useStyles();

    // Effects
    useEffect(() => {
        var api = `{"action":"getExerciseList","name":"${name}"}`;
        waitForSocketConnection(socket, () => sendMessage(api));

        var mylist = [];
        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("learning"))) {
                console.log('joanna ', message.data);
                var obj = JSON.parse(message.data);
                obj.learning.map((item, i) => {
                    mylist.push({
                        url: '/images/button.png',
                        title: item,
                        width: '30%',
                    });
                });
                setData(mylist);
            }
        }
    }, []);

    const imageClick = (title) => {
        setSelected(true);
        setQuizId(title);
    }

    const handler = (val) => {
    
    }

    // Build Page
    if (isSelected) {
        return (
            <div>
                <div className="card m-0">
                    {
                        <Exercise quizid={selectquiz} handler={handler} />
                    }
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <TitleBar title="Exercise" parentNode={"/health-and-wellbeing"} />
                <Button><a href="/exercise-video">Exercise with Video</a></Button>
                <div className={classes.root}>
                    {data.map((image) => (
                        <ButtonBase
                            onClick={() => imageClick(image.title)}
                            focusRipple
                            key={image.title}
                            className={classes.image}
                            focusVisibleClassName={classes.focusVisible}
                            style={{
                                width: image.width,
                            }}
                        >
                            <span
                                className={classes.imageSrc}
                                style={{
                                    backgroundImage: `url(${image.url})`,
                                }}
                            />
                            <span className={classes.imageBackdrop} />
                            <span className={classes.imageButton}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    {image.title}
                                    <span className={classes.imageMarked} />
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }

}