import React, { useEffect } from "react";
import { Device } from '@twilio/voice-sdk';
import TitleBar from '../Common/TitleBar';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import Iframe from 'react-iframe';
import TranslationService from "../../services/translation.service";
import styled from 'styled-components';
import { useTable } from "react-table";
import { useState } from "react";
import axios from "axios";

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const AudioCall = () => {
    const [contacts, setContacts] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [relation, setRelation] = useState();
    const [phrase, setPhrase] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [audioCallURL, setAudioCallUrl] = useState('https://audiocall-ui.ue.r.appspot.com/');

    useEffect(() => {
        getUserContacts();
    }, []);

    const getUserContacts = () => {
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/user-contact?email=' + localStorage.getItem("emailAccess"),
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setContacts(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const deleteUserContact = (e) => {
        let config = {
            method: 'delete',
            url: 'https://cloud-api-389807.ue.r.appspot.com/user-contact?name=' + e.currentTarget.value + '&email=' + localStorage.getItem("emailAccess"),
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                getUserContacts();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const callNumber = (e) => {
        let number = e.currentTarget.value;
        console.log('joanna ', number);
        setAudioCallUrl("https://audiocall-ui.ue.r.appspot.com/?number=" + number);
        window.location.href = "https://audiocall-ui.ue.r.appspot.com/?number=" + number
    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',

            },
            {
                Header: 'Number',
                accessor: 'phone_number',

            },
            {
                Header: 'Relation',
                accessor: 'relation',

            },
            {
                Header: 'Phrase',
                accessor: 'phrase',

            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="small" type="primary" variant="outlined" value={cell.row.values.phone_number} onClick={callNumber}>Call</Button>
                        {/* <Button size="small" type="primary" variant="outlined" value={cell.row.values.url}>Edit</Button> */}
                        <Button size="small" type="primary" variant="outlined" value={cell.row.values.name} onClick={deleteUserContact}>Delete</Button>
                    </div>
                )

            },


        ],
        []
    )

    const handleOpen = (e) => {
        console.log('Open ', e.target.value);
        setName();
        setPhoneNumber();
        setRelation();
        setPhrase();
        setErrorMessage();
        setShow(true);
    }

    const handleClose = (e) => {
        console.log('Close ', e);
        setShow(false);
    }

    const handleSave = (e) => {
        console.log('Save ', e.target.value, name, phoneNumber, phrase, relation);
        if (name === undefined || phoneNumber === undefined || phrase === undefined || relation === undefined) {
            setErrorMessage("Please check required fields!");
        } else {
            const axios = require('axios');
            let data = JSON.stringify({
                "email": localStorage.getItem("emailAccess"),
                "name": name,
                "relation": relation,
                "phone_number": phoneNumber,
                "phrase": phrase
            });

            let config = {
                method: 'post',
                url: 'https://cloud-api-389807.ue.r.appspot.com/user-contact',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            var api = `{"api":"addExportData", "command":"${phrase}", "phoneNumber": "${phoneNumber}","name": "${localStorage.getItem("remoteId")}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    getUserContacts();
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setShow(false);
                });
        }
    }

    return (
        <Styles>
            <TitleBar title={computeTitle("Audio Call")} parentNode={"/friends-and-relatives"} />
            <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>{computeTitle('Add Contact')}</Button>
            <Dialog
                open={show}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>Add New Contact</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Name"
                        inputProps={{ maxLength: 200, }}
                        defaultValue={name}
                        value={name}
                        onChange={e => { setName(e.target.value) }}
                        fullWidth
                        required
                    />
                    <br />
                    <TextField
                        label="Phone Number"
                        inputProps={{ maxLength: 200, }}
                        defaultValue={phoneNumber}
                        value={phoneNumber}
                        onChange={e => { setPhoneNumber(e.target.value) }}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Relation"
                        inputProps={{ maxLength: 200, }}
                        defaultValue={relation}
                        value={relation}
                        onChange={e => { setRelation(e.target.value) }}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Phrase"
                        inputProps={{ maxLength: 200, }}
                        defaultValue={phrase}
                        value={phrase}
                        onChange={e => { setPhrase(e.target.value) }}
                        fullWidth
                        required
                    />
                    <br />
                    <i style={{ color: 'red' }}>{errorMessage}</i>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <br />
            <Table columns={columns} data={contacts} />
            <br />
            <br />
            {/* <Iframe url={audioCallURL} width="100%" height="1000px"></Iframe> */}
        </Styles>
    );
}

export default AudioCall;