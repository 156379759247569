import React from 'react';

import Option from './Option';
import Timer from './Timer';
import './css/loader.css';


function LearningMain(props) {
    return (
        <div className="col-12 main-content clearfix p-0 overflow-hidden">
            <div className="">
                { props.hasPhoto
                    ? <img src={`data:image/jpg;base64,${props.imageUrl}`} className="author-image" alt={props.correctAnswer}  />
                    : <div className="loader"></div>
                }
            </div>
           
        </div>
    )
}

export default LearningMain;