import React, { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import {socket} from '../../socket';

const useStyles = makeStyles({
  root: {
    width: 400,
  },
});

const SwitchLabels = (props) => {

  const [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    //const { name, room } = queryString.parse(location.search);
    console.log(props.location.search);
 
    const name=  'user';// AuthService.getCurrentUser().username;
    const room = 'AgedCare';

    setRoom(room);
    setName(name)

    
  }, []);
  
  useEffect(() => {
    socket.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    socket.onmessage = (message) => {
      console.log(message);
    };
}, []);

const sendMessage = (message) => {
    
  if(message) {
    socket.send(message);
  }
}

const sendMessageEvent = (event) => {
  event.preventDefault();

  if(message) {
    var api = `{"api":"vocalise", "text":"${message}", "emotion":"neutral","sound":"none","language":"en"}`
    socket.send(api); setMessage('');
  }
}


  const classes = useStyles();
  const [state, setState] = React.useState({
    dialog: true,
    humandetec: false,
    ears: false,
  });

  const [value, setValue] = React.useState(30);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.name == "ears")
    {
      if (event.target.checked)
      {
        sendMessage('{"api":"ears","action":"on"}');
      }else
      {
        sendMessage('{"api":"ears","action":"off"}');
      }
      
    }else if (event.target.name == "humandetect")
    {
      if (event.target.checked)
      {
        var api = `{"api":"vocalise", "text":"turn on human detection", "emotion":"neutral","sound":"none","language":"en"}`
      socket.send(api);
      }else
      {
        var api = `{"api":"vocalise", "text":"turn off human detection", "emotion":"neutral","sound":"none","language":"en"}`
      socket.send(api);
      }
    

    }else if (event.target.name == "dialog")
    {
      if (event.target.checked)
      {
        var api = `{"api":"vocalise", "text":"turn on dialog system", "emotion":"neutral","sound":"none","language":"en"}`
      socket.send(api);
      }else
      {
        var api = `{"api":"vocalise", "text":"turn off dialog system", "emotion":"neutral","sound":"none","language":"en"}`
      socket.send(api);
      }
    }

  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    sendMessage(`{"api":"volume","action":"set","value":"${newValue}"}`);
  };

  const handleReboot = () => {
    // alert('Would you like to reboot the robot');
  }

  return (
    <div className={classes.root}>
    <div>
    <FormControl component="fieldset">
      <FormLabel component="legend">Service Management</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={state.gilad} onChange={handleChange} name="dialog" />}
          label="Dialog System"
        />
        <FormControlLabel
          control={<Switch checked={state.jason} onChange={handleChange} name="humandetect" />}
          label="Human Dection"
        />
        <FormControlLabel
          control={<Switch checked={state.antoine} onChange={handleChange} name="ears" />}
          label="Ears"
        />
      </FormGroup>
      {/* <FormHelperText>Be careful</FormHelperText> */}
    </FormControl>
    <Typography id="continuous-slider" gutterBottom>
        Volume
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <VolumeDown />
        </Grid>
        <Grid item xs>
          <Slider value={value} onChange={handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          <VolumeUp />
        </Grid>
      </Grid>
      </div>
      <div>
      <form className="form">
    <input
      className="input"
      type="text"
      placeholder="Type a message..."
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={event => event.key === 'Enter' ? sendMessageEvent(event) : null}
    />

      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={e => sendMessageEvent(e)}
      >
        Send
      </Button>
      </form>
      </div>
      <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleReboot()}
      >
        Reboot
      </Button>
      
      </div>
    
  
    </div>
  );
}

export default SwitchLabels;