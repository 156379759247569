import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import { Chart } from 'react-charts'
import TranslationService from "../../services/translation.service";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const ServicePattern = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [apiURL, setApiURL] = useState("http://127.0.0.1:8080/")
    const [value, setValue] = useState("2021-05-04");
    const [contentlist, setContentlist] = useState([]);
    const [content, setContent] = useState("");
    const [dataValue, setDataValue] = useState([[1, 1], [2, 2]]);

    // Effects
    useEffect(() => {
        if (!gotSongs) {
            var api = `{"api":"AnaUserList","name": "${name}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
            setGotSongs(true);
        }
        socket.onmessage = (message) => {
            console.log("Message ", message);
            if ((message.data) && (message.data.toString().includes("userlist"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (obj.data_type == "userlist") {
                        setGotSongs(true);
                        setContentlist(transformData(obj.data));
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
            if ((message.data) && (message.data.toString().includes("servicepattern"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (obj.data_type == "servicepattern") {
                        setGotSongs(true);
                        setDataValue(obj.data);
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
        };

    }, [contentlist]);

    const transformData = (obj) => {
        let finalObject = []
        for (let i = 0; i < obj.length; i++) {
            obj[i] = computeTitle(obj[i])
            finalObject.push(obj[i])
        }
        return obj
    }

    // Memo
    let data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: dataValue,
            }
        ],
        [dataValue]
    )

    const series = React.useMemo(
        () => ({
            showPoints: true,
        }),
        []
    );

    const axes = React.useMemo(
        () => [
            {
                primary: true,
                type: 'ordinal',
                position: 'bottom',
                format: (d) => {
                    const date = new Date(d);
                    return !isNaN(date) ? date.toLocaleDateString() : '';
                },
            },
            { type: 'linear', position: 'left' },
        ],
        []
    );

    // Functions
    const handleContentChange = (e) => {
        setContent(e.target.value);
        var api = `{"api":"AnaServicePattern","name": "${name}","user": "${e.target.value}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        // let config = {
        //     method: 'get',
        //     maxBodyLength: Infinity,
        //     url: 'http://192.168.163.30:3000/servicepattern?user=' + e.target.value,
        //     headers: {}
        // };

        // axios.request(config)
        //     .then((response) => {
        //         console.log(JSON.stringify(response.data));
        //         setDataValue(response.data.data)
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    // Build Page
    return (
        <Styles>
            <TitleBar title={computeTitle("Analytics: Service Pattern")} parentNode={"/analytics"} />
            <FormControl fullWidth>
                <InputLabel htmlFor="content-label">Select User</InputLabel>
                <Select defaultValue={content} value={content} id="select-content" onChange={handleContentChange} fullWidth>
                    {contentlist.map((categoria, i) => (
                        <MenuItem key={1} value={categoria}>{categoria}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div
                style={{
                    width: '100%',
                    height: '400px'
                }}
            >
                <Chart data={data} series={series} axes={axes} tooltip />
            </div>
        </Styles>
    )
}

export default ServicePattern;