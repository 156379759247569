import React, { Component } from 'react';
import axios from "axios";
import './css/ImageQuiz.css';
import './css/bootstrap-min.css';

import MainContent from './MainContent';
import Hero from './Hero';
import Continue from './Continue';
import Footer from './Footer';
import DisplayResult from './DisplayResult';
import PlayAgain from './PlayAgain';
import {vocalise} from '../../socket';
import AuthService from "../../services/auth.service";
import { waitForSocketConnection, socket, sendMessage, name } from "../../socket";


const getDataApi = (_self) =>{
  const getRandomItem = (arr) => {
    let item = arr[_self.state.qIndex];

    
    return item;
  };

  let index = _self.state.qIndex;
  if (index == _self.state.questions.length) _self.state.endOfQuiz = true;
  else
  {
      var qObj = getRandomItem(_self.state.questions);
      
      
     
      getQuestionPhoto(_self,qObj.image);

      _self.setState({
        question:qObj.text,
        correctAnswer: qObj.answer,
        setOfAnswer: qObj.options,
        showContinueBtn: false,
        qIndex: index+1,
        imageUrl: "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAHwAugMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIDB//EABgQAQEBAQEAAAAAAAAAAAAAAAABETFB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwD2qKkVtkipFBQ0QAAAAWKgiiooCKgBQoiAKAAAAMERYosVFgLAEAAAAFVBFFQAAABBABQAAABziosUVYk4sBQEAAAAAAFAQA0ARUUAAAAANgOcVFaFWMqgoSiCgAAAAAAAAAAAAAAAGAK5LEitIoEQaDSARUWIAAAAAAAAAAAAAACaqA5rGdaaFEVBVjKgqsxQUBAAAAAAAAAAABAVDUByjUZi60mNCKKoioLFQBVTQFEVAAABAX0AAEAKlFAQByixFnWoiqzGohVAFVUEBUUFGdUFNZ1QURNBpNADTRAA1FFQAf/Z",
        hasPhoto: true
      })
      vocalise(qObj.text);
  }

  
}

const getQuizContent = (_self,quizid) =>{
  var message = `{"action":"getQuizSpecific", "name":"${name}", "title": "${quizid}"}`;
  waitForSocketConnection(socket, () => { sendMessage(message) });
}

const getQuestionPhoto = (_self, photoid) =>{
  
     if (photoid == "")
     {
      _self.setState({
        hasPhoto: false
      });
      

     }else
     {
      var api = `{"action":"getITQzPhoto","name":"${name}", "photo":"${photoid}"}`;
      waitForSocketConnection(socket, () => { socket.send(api); });
      AuthService.getPhoto(photoid).then(
        (response) => {
          _self.setState({
            imageUrl: response.data,
            hasPhoto: true
          })
          
        },
        (error) => {
          const _content = 
            (error.response && error.response.data) || 
            error.message ||
            error.toString();
          
        }
      );
     }
      
     
}

class ImageQuizOrig extends Component {
  constructor(props) {
    super(props);

    this.enableContinueBtn = this.enableContinueBtn.bind(this);
    this.newPuzzle = this.newPuzzle.bind(this);
    this.showResult = this.showResult.bind(this);
  }

  static initialState = () => ({
    imageUrl: '',
    
    question: '',
    correctAnswer: '',
    setOfAnswer: [],
    savedResult: [],
    showContinueBtn: false,
    timerLimit: 60,
    isTimerReached: false,
    oneQuesAttemptTime: 60,
    questions:[],
    qIndex:0,
    endOfQuiz: false,
    hasPhoto:false
    
  })

  state = ImageQuizOrig.initialState();

  showResult = () => {
    this.setState({
      isTimerReached: true,
      showContinueBtn: false
    })
  }

  resetQuiz = () => {
    var _self = this;
    this.setState(ImageQuizOrig.initialState());
    getQuizContent(_self,this.props.quizid);
  }

  newPuzzle = () => {
    var _self = this;
    const allOptionsObj = document.querySelectorAll('.item-options .option');
    for(var obj of allOptionsObj) {
      obj.classList.remove('red', 'green');
      obj.disabled = false;
    }
    this.setState({
      imageUrl: ''
    })
    getDataApi(_self);
  }

  enableContinueBtn = (isAnswerCorrect) => {
    this.setState( (prevState) => ({
      showContinueBtn: true,
      savedResult: prevState.savedResult.concat(isAnswerCorrect)
    }))
  }

  componentDidMount() {
    var _self = this;
    const quizid = this.props.quizid;
    
    getQuizContent(_self, quizid);
    
    socket.onmessage = (message) => {
      var str = message.data.substring(0, message.data.length - 1);
      if (str.includes("options") && str.includes("text") && str.includes("image")) {
        console.log('Quiz ', message.data);
        let listOfItem = JSON.parse(message.data);
        listOfItem = listOfItem.quiz;

        this.setState({
          questions: listOfItem
        })

        getDataApi(this);
      } else {
        console.log("DATA ", message.data);
      }
    }
  }

  render() {
    let {imageUrl, question,correctAnswer, setOfAnswer, showContinueBtn, timerLimit, savedResult, oneQuesAttemptTime, hasPhoto} = this.state;
    return (
      <div className="container-fluid">
        { !this.state.isTimerReached && (!this.state.endOfQuiz)
          ? 
         <>
          <Hero question = {question}/>
          <MainContent 
              imageUrl={imageUrl} 
              correctAnswer= {correctAnswer}
              setOfAnswer={setOfAnswer}
              showContinueBtn={showContinueBtn}
              enableContinueBtn={this.enableContinueBtn}
              timerLimit={timerLimit}
              showResult={this.showResult}
              hasPhoto = {hasPhoto}
            />
         </>
          : <div>
              <DisplayResult 
                resultList={savedResult}
                timerLimit={timerLimit}
                oneQuesAttemptTime={oneQuesAttemptTime}
              />
              <PlayAgain resetQuiz={this.resetQuiz}/>
            </div>
        }        
        { (showContinueBtn&&!this.state.endOfQuiz) && <Continue newPuzzle={this.newPuzzle}/>}
        <Footer />
      </div>
    )
  }
}

export default ImageQuizOrig;
