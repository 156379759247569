import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";

const styles = (theme) => ({});

class Analytics extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <svg fill="white" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>logs</title> <path d="M0 24q0 0.832 0.576 1.44t1.44 0.576h1.984q0 2.496 1.76 4.224t4.256 1.76h6.688q-2.144-1.504-3.456-4h-3.232q-0.832 0-1.44-0.576t-0.576-1.408v-20q0-0.832 0.576-1.408t1.44-0.608h16q0.8 0 1.408 0.608t0.576 1.408v7.232q2.496 1.312 4 3.456v-10.688q0-2.496-1.76-4.256t-4.224-1.76h-16q-2.496 0-4.256 1.76t-1.76 4.256h-1.984q-0.832 0-1.44 0.576t-0.576 1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408zM10.016 24h2.080q0-0.064-0.032-0.416t-0.064-0.576 0.064-0.544 0.032-0.448h-2.080v1.984zM10.016 20h2.464q0.288-1.088 0.768-1.984h-3.232v1.984zM10.016 16h4.576q0.992-1.216 2.112-1.984h-6.688v1.984zM10.016 12h16v-1.984h-16v1.984zM10.016 8h16v-1.984h-16v1.984zM14.016 23.008q0 1.824 0.704 3.488t1.92 2.88 2.88 1.92 3.488 0.704 3.488-0.704 2.88-1.92 1.92-2.88 0.704-3.488-0.704-3.488-1.92-2.88-2.88-1.92-3.488-0.704-3.488 0.704-2.88 1.92-1.92 2.88-0.704 3.488zM18.016 23.008q0-2.080 1.44-3.52t3.552-1.472 3.52 1.472 1.472 3.52q0 2.080-1.472 3.52t-3.52 1.472-3.552-1.472-1.44-3.52zM22.016 23.008q0 0.416 0.288 0.704t0.704 0.288h1.984q0.416 0 0.704-0.288t0.32-0.704-0.32-0.704-0.704-0.288h-0.992v-0.992q0-0.416-0.288-0.704t-0.704-0.32-0.704 0.32-0.288 0.704v1.984z"></path> </g></svg>
                    `,
                    background: 'linear-gradient(0deg, #0f6f11, #0fa112)',
                    title: 'Interaction Logs',
                    slug: '/interaction-logs',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg fill="white" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style>.cls-1{fill:none;stroke:#fff5f5;stroke-miterlimit:10;stroke-width:1.91px;}</style></defs><circle class="cls-1" cx="3.41" cy="3.41" r="1.91"></circle><circle class="cls-1" cx="12" cy="3.41" r="1.91"></circle><circle class="cls-1" cx="20.59" cy="3.41" r="1.91"></circle><circle class="cls-1" cx="3.41" cy="12" r="1.91"></circle><circle class="cls-1" cx="12" cy="12" r="1.91"></circle><circle class="cls-1" cx="20.59" cy="12" r="1.91"></circle><circle class="cls-1" cx="3.41" cy="20.59" r="1.91"></circle><circle class="cls-1" cx="12" cy="20.59" r="1.91"></circle><circle class="cls-1" cx="20.59" cy="20.59" r="1.91"></circle><line class="cls-1" x1="10.65" y1="10.65" x2="4.76" y2="4.76"></line><line class="cls-1" x1="12" y1="18.68" x2="12" y2="13.91"></line><line class="cls-1" x1="18.68" y1="3.41" x2="13.91" y2="3.41"></line><line class="cls-1" x1="10.09" y1="3.41" x2="5.32" y2="3.41"></line></g></svg>
                    `,
                    background: 'linear-gradient(0deg, #a8090a, #d33232)',
                    title: 'Service Pattern',
                    slug: '/service-pattern',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg fill="white" viewBox="0 0 100 100" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M72,26H67V24a4,4,0,0,0-8,0v2H41V24a4,4,0,0,0-8,0v2H28a6,6,0,0,0-6,6v2a2,2,0,0,0,2,2H76a2,2,0,0,0,2-2V32A6,6,0,0,0,72,26Zm4,16H24a2,2,0,0,0-2,2V74a6,6,0,0,0,6,6H72a6,6,0,0,0,6-6V44A2,2,0,0,0,76,42ZM50,75.9A14.9,14.9,0,1,1,64.9,61,14.94,14.94,0,0,1,50,75.9Zm-6.8-8.7a1.71,1.71,0,0,1-1.9,1.6H40.1a.67.67,0,0,1-.7-.6V59c0-.4.3-.6.7-.6h2.4a.67.67,0,0,1,.7.6Zm17.2-1.8c0,3.5-3.3,3.8-6.9,3.8-3.3,0-4.5-1.1-7.7-1.2a.65.65,0,0,1-.6-.6v-8c0-.4.3-.6.7-.6a4.18,4.18,0,0,0,4-4.2V52.2c0-.4.3-.6.7-.6h1.2a1.71,1.71,0,0,1,1.9,1.6v4.1a1.71,1.71,0,0,0,1.9,1.6h2.8a1.78,1.78,0,0,1,1.9,1.6h0Z"></path></g></svg>
                    `,
                    background: 'linear-gradient(0deg, #da8309, #ffad39)',
                    title: 'Service Preference',
                    slug: '/service-preference',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
        this.computeScreenSize = this.computeScreenSize.bind(this);
    }

    componentDidMount() { }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Analytics")} parentNode={"/service-scheduling"} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={app.title}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(Analytics);