import React from 'react';

import Option from './Option';
import Timer from './Timer';
import './css/loader.css';


function ExerciseMain(props) {
    return (
        <div className="col-12 p-0 main-content clearfix">
            <div className=" ">
                { props.hasPhoto
                    ? <img src={`data:image/jpg;base64,${props.imageUrl}`} className="author-image w-100" alt={props.correctAnswer} height="450px" />
                    : <div className="loader"></div>
                }
            </div>
           
        </div>
    )
}

export default ExerciseMain;