import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";


class RobotBlocks extends Component {
	

	render() {
        return (
            <div>
                <TitleBar title="Matilda Blocks" />
                <Iframe url="http://192.168.163.30:9000/" width="100%" height="500px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
            </div>
        );
    }

	
}

export default RobotBlocks;