import globalObject from '../Common/globalVars'
import Matilda1 from '../Illustrations/Matilda1';
import TitleBar from '../Common/TitleBar';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from 'axios';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TranslationService from '../../services/translation.service' ;


const EntryPage = () => {
  let history = useHistory();
  const [remoteId, setRemoteId] = useState('');
  const socketURL = 'wss://socket.mymatilda.com.au:3389';
  const ws = useRef(null);
  const [userRobots, setUserRobots] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  console.log('Joanna ... TRANSLATE')

  useEffect(() => {
    TranslationService.translateString();
    console.log("END TRANSLATE")
    let config = {
      method: 'get',
      url: 'https://cloud-api-389807.ue.r.appspot.com/user-robot?email=' + localStorage.getItem("userEmail"),
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setRemoteId(response.data.data[0])
      })
      .catch((error) => {
        console.log(error);
      });

    let config1 = {
      method: 'get',
      url: 'https://cloud-api-389807.ue.r.appspot.com/user-robot?email=' + localStorage.getItem("userEmail"),
      headers: {}
    };

    axios.request(config1)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data == "404" || response.data.data === null) {
          setUserRobots([]);
        } else {
          setUserRobots(response.data.data.robots);
        }

      })
      .catch((error) => {
        console.log(error);
      });


  }, []);


  // function  onchangeSet(){
  //     globalObject.start = false
  //     globalObject.remoteName = remoteId
  //     globalObject.localName = remoteId.replace('Matilda','Caregiver')
  //     Object.freeze(globalObject)
  //     console.log(globalObject.remoteName)
  //     console.log(globalObject.localName)
  //     history.push("/menu")
  //   }

  const updateRemoteId = (e) => {
    setRemoteId(e.target.value);
  }

  const sendToRemote = () => {
    if (remoteId == '' || remoteId === undefined) {
      console.log("NO SELECTED");
      setErrorMessage('No valid robot')
    } else {
      console.log("REMOTE ID ", remoteId);
      localStorage.setItem('remoteId', remoteId);
      const str = remoteId;
      const lastIndex = str.lastIndexOf('_');
      const after = str.slice(lastIndex);
      const peerId = 'Caregiver' + after;
      localStorage.setItem('peerId', peerId);
      ws.current.send(JSON.stringify({
        remoteId: remoteId
      }));
    }
  }

  useEffect(() => {
    const pageLogin = localStorage.getItem('remoteId');
    if (pageLogin) {
      history.push("/menu");
    }
    ws.current = new W3CWebSocket(socketURL);
    console.log("scoket check");
    ws.current.onopen = (event) => {
      console.log("Connection Established!");
    }

    ws.current.onmessage = function (e) {
      //if (typeof e.data === 'string') {
      if (JSON.stringify(e.data)) {
        history.push("/menu");
      }
      //}
    };

    ws.current.onclose = (event) => {
      console.log('disconnected', event);
    };

    ws.current.onerror = (error) => {
      console.log('Socket error', error);
    };

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, [])

  const [inpType, setInptType] = useState('text');
  const showUser = () => {
    if (inpType == "password") {
      setInptType("text")
    } else {
      setInptType("password")
    }
  }


  return (

    <>
      <div className=" ">
        <TitleBar title="Welcome, Please connect to your Robot" />

        <div id="call-box">
          <div className='logo-area'>
            <Matilda1 />
          </div>
          <div className='content-area'>
            <h4>Enter Matilda Id</h4>
            <FormControl fullWidth>
              <InputLabel htmlFor="content-label">Robot IDs</InputLabel>
              <Select value={remoteId} onClick={updateRemoteId} fullWidth>
                {userRobots.map((categoria, i) => (
                  <MenuItem key={i} value={categoria}>{categoria}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password" onClick={showUser}></span>
            <span onClick={sendToRemote} id="connect-btn" className="btn btn-large">
              Connect
            </span>
            <br />
            <small><i>{errorMessage}</i></small>
          </div>
        </div>
      </div>
      <link href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css" rel="stylesheet"></link></>
  )
}

export default EntryPage;