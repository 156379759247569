import React, { useState } from 'react';
import { sendMessage, socket, waitForSocketConnection } from '../../socket';
import TitleBar from "../Common/TitleBar";

function TranscriptionApp() {
  const [transcription, setTranscription] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [isStarted, setIsStarted] = useState(false);

  const startTranscription = () => {
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.lang = 'en-US'; // Set language to English
    recognitionInstance.continuous = true; // Continuously transcribe speech
    recognitionInstance.interimResults = true; // Get interim results
    recognitionInstance.onresult = (event) => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          setTranscription(transcription + ' ' + event.results[i][0].transcript);
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
    };
    recognitionInstance.start();
    setRecognition(recognitionInstance);
    setIsStarted(true);
  };

  const stopTranscription = () => {
    if (recognition) {
      recognition.stop();
      setTranscription('');
      setIsStarted(false);
    }
  };

  const sendTranscription = () => {
    if (recognition) {
      recognition.stop();
      console.log('Final transcription ...', transcription);
      var api = `{"api":"cmdContrl", "phrase":"${transcription}", "name": "${localStorage.getItem("remoteId")}"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) });
      setTranscription('');
      setIsStarted(false);
    }
  };

  return (
    <>
      <TitleBar title={"Command Control"} />
      <div className="phone-container">
        <div className="transcription-app">
          <h3 className="title">Voice Command</h3>
          <br />
          <div className="buttons">
            {
              isStarted ?
                <button onClick={stopTranscription}>Stop Transcription</button>
                :
                <button onClick={startTranscription}>Start Transcription</button>
            }
            <button onClick={sendTranscription}>Send Transcription</button>
          </div>
          <div className="transcription">{transcription}</div>
          {isStarted && (
            <div className="recording-indicator">
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          )}
        </div>
        <style jsx>{`
        .phone-container {
          display: flex;
          justify-content: center;
          align-items: flex-start; /* Adjusted to align items at the top */
          height: 100vh;
          background-color: transparent; /* Removed background color */
          padding-top: 20px; /* Reduced top padding */
        }

        .transcription-app {
          width: 280px; /* Reduced width */
          height: 350px; /* Further reduced height */
          background-color: white;
          border: 2px solid #ccc;
          border-radius: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px; /* Reduced padding */
          box-sizing: border-box;
        }

        .title {
          margin-bottom: 10px; /* Reduced margin */
          font-size: 1.5em;
          color: #333;
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
        }

        .transcription {
          margin-top: 10px; /* Reduced margin */
          width: 100%;
          height: 100px; /* Adjusted height */
          overflow-y: auto;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 10px;
          background-color: #fafafa;
        }

        .recording-indicator {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px; /* Reduced margin */
        }

        .wave {
          width: 10px;
          height: 10px;
          margin: 5px;
          background-color: red;
          border-radius: 50%;
          animation: wave 1.2s infinite ease-in-out;
        }

        .wave:nth-child(1) {
          animation-delay: -0.24s;
        }

        .wave:nth-child(2) {
          animation-delay: -0.12s;
        }

        .wave:nth-child(3) {
          animation-delay: 0;
        }

        @keyframes wave {
          0%, 80%, 100% {
            transform: scale(0);
          }
          40% {
            transform: scale(1);
          }
        }
      `}</style>
      </div>
    </>
  );
}

export default TranscriptionApp;
