import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";
import { Middleware } from "./Middleware";


const styles = (theme) => ({});

class FriendsAndRelatives extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M14 14V6M14 14L20.1023 17.487C20.5023 17.7156 21 17.4268 21 16.9661V3.03391C21 2.57321 20.5023 2.28439 20.1023 2.51296L14 6M14 14H7C4.79086 14 3 12.2091 3 10V10C3 7.79086 4.79086 6 7 6H14" stroke="currentColor" stroke-width="1.5"/>
                        <path d="M7.75716 19.3001L7 14H11L11.6772 18.7401C11.8476 19.9329 10.922 21 9.71716 21C8.73186 21 7.8965 20.2755 7.75716 19.3001Z" stroke="currentColor" stroke-width="1.5"/>
                    `,
                    background: 'linear-gradient(0deg, #1b6c72, #2498a1)',
                    title: 'Phone Call',
                    slug: '/audio-call',
                    locations: ['home'],
                },
                {
                    svg: `
                        <path d="M2 16V8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16Z" stroke="currentColor" />
                        <path d="M6 13V11C6 9.89543 6.89543 9 8 9H11C12.1046 9 13 9.89543 13 11V13C13 14.1046 12.1046 15 11 15H8C6.89543 15 6 14.1046 6 13Z" stroke="currentColor" />
                        <path d="M17.0399 9.22L13.9733 11.52C13.6533 11.76 13.6533 12.24 13.9733 12.48L17.0399 14.78C17.4355 15.0767 17.9999 14.7944 17.9999 14.3V9.7C17.9999 9.20557 17.4355 8.92334 17.0399 9.22Z" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #da8309, #ffad39)',
                    title: 'Video Call',
                    slug: '/call',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z">
                        </path>
                        </svg>
                    `,
                    background: 'linear-gradient(0deg, #741d29, #9c4d58)',
                    title: 'Family Photos & Videos',
                    slug: '/family',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m12 0c-6.627 0-12 4.974-12 11.111.035 3.55 1.774 6.686 4.438 8.633l.031.021v4.235l4.088-2.242c1.031.295 2.215.464 3.439.464h.004c6.627 0 12-4.975 12-11.11s-5.373-11.112-12-11.112zm1.191 14.963-3.055-3.263-5.963 3.26 6.559-6.96 3.131 3.259 5.889-3.259z"></path></g></svg>
                    `,
                    background: 'linear-gradient(0deg, #9d8f12, #dfcc22)',
                    title: 'Messenger',
                    slug: '/messenger',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
            allowedAcccess: []
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
    }

    componentDidMount() {
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        Middleware()

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access});
            })
            .catch((error) => {
                console.log(error);
            });
     }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Friends and Relatives")} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        this.state.allowedAcccess.includes(app.title) && <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(FriendsAndRelatives);