import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import { Chart } from 'react-charts'
import TranslationService from "../../services/translation.service";
import { CardTitle } from "reactstrap";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const Fitbit = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [apiURL, setApiURL] = useState("http://127.0.0.1:8080/")
    const [value, setValue] = useState("2023-01-01");
    const [valueEnd, setValueEnd] = useState("2023-01-31");
    const [contentlist, setContentlist] = useState([]);
    const [content, setContent] = useState("");
    const [dataValue, setDataValue] = useState([]);
    const [dataHeart, setDataHeart] = useState([]);
    // const [fitbitUrl, setFitbitUrl] = useState('https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=23QYTC&scope=activity+cardio_fitness+electrocardiogram+heartrate+location+nutrition+oxygen_saturation+profile+respiratory_rate+settings+sleep+social+temperature+weight&redirect_uri=http%3A%2F%2Flocalhost%3A8081%2Ffitbit');
    const [connectMsg, setConnectMsg] = useState("");
    const [fitbitUrl, setFitbitUrl] = useState('https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=23QYTC&scope=activity+cardio_fitness+electrocardiogram+heartrate+location+nutrition+oxygen_saturation+profile+respiratory_rate+settings+sleep+social+temperature+weight&redirect_uri=https%3A%2F%2Fapp.mymatilda.com.au%2Ffitbit');
    const [isDone, setIsDone] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Effects
    useEffect(() => {
        socket.onmessage = (message) => {
            console.log("data ", message.data);
            if ((message.data) && (message.data.toString().includes("access_token")) && !isDone) {
                var obj = JSON.parse(message.data);
                console.log("Fitbit Socket ", obj['access_token'], obj['user_id']);
                localStorage.setItem('fitbit_access_token', obj['access_token']);
                localStorage.setItem('fitbit_user_id', obj['user_id'])
                getHeartData();
                getStepsData();
                setConnectMsg("");
                setIsDone(true);
            } 
            else if((message.data) && (message.data.toString().includes("is_heart"))) {
                var obj = JSON.parse(message.data);
                console.log("Configuration ", message.data);
                setIsHeart(obj["is_heart"]);
                setIsStep(obj["is_step"]);
                setInterval(obj["interval"]);
            }
        } 

        console.log('Outside fitbit', name === "undefined")
        if (localStorage.getItem("remoteId") === null || localStorage.getItem("remoteId") === "undefined") {
            console.log('Inside fitbit', window.location.href.split("?")[1])
            localStorage.setItem('remoteId', window.location.href.split("?")[1]);
            name = window.location.href.split("?")[1]
            var api = `{"api":"getToken", "action":"get","name": "${window.location.href.split("?")[1]}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
        }
        var api = `{"api":"getToken", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });

        var api = `{"api":"getFitbitConfig", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });

        console.log(localStorage.getItem('fitbit_access_token'), "TOKENACCESS");
        console.log('fitbit ', localStorage.getItem('Fitbit'))
        if (localStorage.getItem('Fitbit') === null) {
            setConnectMsg("Not authenticated. Reconnect and re-sync now");
            let location = window.location.href.split("?");
            if (location.length > 1 && window.location.href.includes("code")) {
                location = location[1].split("=")[1].split("#")[0]
                localStorage.setItem('Fitbit', location);
                setConnectMsg("");
                getAccessToken(location);
            }
        } else {
            getHeartData();
            getStepsData();
            setConnectMsg("");
            let location = window.location.href.split("?");
            if (location.length > 1) {
                location = location[1].split("=")[1].split("#")[0]
                localStorage.setItem('Fitbit', location);
                setConnectMsg("");
                getAccessToken(location);
            }
        }
    }, []);

    const getAccessToken = (code) => {
        let params = {
            client_id: '23QYTC',
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: 'https://app.mymatilda.com.au/fitbit'
        }

        const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

        let config = {
            method: 'post',
            url: 'https://api.fitbit.com/oauth2/token',
            data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic MjNRWVRDOjAxMjQxZTc1MjEyMmVlNDIxYjk0ZGFiNTM3NTc1NTQ3',
            }
        };

        axios.request(config)
            .then((response) => {
                console.log(response.data.access_token, 'access token')
                localStorage.setItem('fitbit_access_token', response.data.access_token)
                localStorage.setItem('fitbit_user_id', response.data.user_id)

                var api = `{"api":"setToken", "action":"get","name": "${name}", "access_token": "${response.data.access_token}", "user_id": "${response.data.user_id}"}`;
                waitForSocketConnection(socket, () => { sendMessage(api) });
            })
            .catch((error) => {
                console.log(error);
                // setConnectMsg("Not authenticated. Please reconnect and resync")
            });

    }

    // Memo
    let data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: dataValue
            }
        ],
        [dataValue]
    )

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ],
        []
    )

    let dataHeartFinal = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: dataHeart
            }
        ],
        [dataHeart]
    )

    // Functions

    const computeTitle = (title) => {
        // console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            // console.log("TITLE ", title)
        }
        return title
    }

    const getStepsData = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.fitbit.com/1/user/' + localStorage.getItem('fitbit_user_id') + '/activities/steps/date/' + value + '/' + valueEnd + '.json',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('fitbit_access_token'),
            }
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                response = response.data["activities-steps"]
                console.log(response);
                let finalData = []
                for (var i = 0; i < response.length; i++) {
                    // console.log("item ", response[i]["dateTime"], response[i]["value"])
                    finalData.push([i, response[i]["value"]])
                }
                setDataValue(finalData);
            })
            .catch((error) => {
                console.log(error);
                setConnectMsg("Not authenticated. Please reconnect and resync")
            });

    }

    const getHeartData = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.fitbit.com/1/user/' + localStorage.getItem('fitbit_user_id') + '/activities/heart/date/' + value + '/' + valueEnd + '.json',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('fitbit_access_token'),
            }
        };

        axios.request(config)
            .then((response) => {
                response = response.data["activities-heart"]
                let finalData = []
                for (var i = 0; i < response.length; i++) {
                    // console.log('Heart ', response[i]['value']);
                    if (response[i]["value"]["restingHeartRate"] === undefined) {
                        finalData.push([i, 0])
                    } else {
                        finalData.push([i, response[i]["value"]["restingHeartRate"]])
                    }
                }
                // console.log('Heart ', finalData);
                setDataHeart(finalData);
            })
            .catch((error) => {
                console.log(error);
                setConnectMsg("Not authenticated. Please reconnect and resync")
            });

    }

    const getData = () => {
        getStepsData();
        getHeartData();
    }

    const onChangeDate = (e) => {
        let day = String(e.getDate());
        let month = String(e.getMonth() + 1);
        let year = String(e.getFullYear());

        if (day.length == 1) {
            day = "0" + day
        }

        if (month.length == 1) {
            month = "0" + month
        }

        let fullDate = year + '-' + month + '-' + day
        setValue(fullDate);
    }

    const onChangeDateEnd = (e) => {
        let day = String(e.getDate());
        let month = String(e.getMonth() + 1);
        let year = String(e.getFullYear());

        if (day.length == 1) {
            day = "0" + day
        }

        if (month.length == 1) {
            month = "0" + month
        }

        let fullDate = year + '-' + month + '-' + day
        setValueEnd(fullDate);
    }

    const [isHeart, setIsHeart] = useState("off");
    const [isStep, setIsStep] = useState("off");
    const [interval, setInterval] = useState("0");

    const handeOpen = (e) => {
        console.log("Opening it");
        setIsOpen(true);
    }

    const handleClose = (e) => {
        console.log("Closing it");
        setIsOpen(false);
    }

    const handleSave = (e) => {
        console.log("Saving it");
        setIsOpen(false);
        console.log("Submitted data ", interval, isHeart, isStep);
        var api = `{"api":"posttFitbitConfig", "action":"get","name": "${name}","interval": "${interval}","is_heart": "${isHeart}","is_step": "${isStep}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    // Build Page
    return (
        <>
            <TitleBar title={computeTitle("Fitbit")} parentNode={"/health-and-wellbeing"} />
            <Button href={fitbitUrl}>Refresh connection...</Button>
            <Button onClick={getData}>Refresh data...</Button>
            <Button onClick={handeOpen}>Remider Configuration</Button>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>Update Reminder Configuration</DialogTitle>
                <DialogContent dividers>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Reminder Interval<small>&nbsp;&nbsp;(in seconds)</small></FormLabel>
                    </FormControl>
                    <TextField
                        defaultValue={interval}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setInterval(e.target.value); }}
                        value={interval}
                        fullWidth
                    />
                    <br />
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Heart Rate Reminder</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={isHeart}
                            onChange={e => { setIsHeart(e.target.value); }}
                            value={isHeart}
                        >
                            <FormControlLabel value="on" control={<Radio />} label="On" />
                            <FormControlLabel value="off" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Steps Reminder</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={isStep}
                            onChange={e => { setIsStep(e.target.value); }}
                            value={isStep}
                        >
                            <FormControlLabel value="on" control={<Radio />} label="On" />
                            <FormControlLabel value="off" control={<Radio />} label="Off" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">Submit</Button>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    label="Start Date"
                    value={value}
                    onChange={(newValue) => onChangeDate(newValue)}
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    label="End Date"
                    value={valueEnd}
                    minDate={value}
                    onChange={(newValue) => onChangeDateEnd(newValue)}
                />
            </MuiPickersUtilsProvider>
            <br />
            <br />
            <i style={{ color: 'red' }}>{connectMsg}</i>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardTitle><h5>Montly Steps Trend</h5></CardTitle>
                        <div
                            style={{
                                width: '100%',
                                height: '400px'
                            }}
                        >
                            <Chart data={data} axes={axes} />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardTitle><h5>Montly Resting Heart Rate Trend</h5></CardTitle>
                        <div
                            style={{
                                width: '100%',
                                height: '400px'
                            }}
                        >
                            <Chart data={dataHeartFinal} axes={axes} />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Fitbit;