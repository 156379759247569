import React, { Component } from "react";
import { HashRouter as Router,Switch, Route, Link ,Redirect} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";
import Login from "./components/Login";
import Register from "./components/Register";
import AdminRegister from "./components/AdminRegister";
import Home from "./components/Home";
import Menu from "./Menu";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Chat from "./components/Chat/Chat";
import SwitchLabels from "./components/SwitchLabels/SwitchLabels";
import Map from "./components/Map/Map";
import StackedBarChart from "./components/analytics/StackedBarChart"
import MyPieChart from "./components/analytics/PieChart"
import MyLineChart from "./components/analytics/MyLineChart";
import Video from "./components/Video/Video";
import Player from "./components/Video/Player";
import LogTable from "./components/analytics/LogTalble";
import RobotStatus from "./components/analytics/RobotStatus";
import Music from "./components/analytics/Music";
import YouTube from "./components/analytics/YouTube";
import YouTubeWithVideo from "./components/analytics/YouTubeWithVideo";
import HealthAndWellBeing from "./components/analytics/HealthAndWellBeing";
import ExerciseList from "./components/Exercise/exerciselist";
import SmartHome from "./components/analytics/SmartHome";
import VoiceCom from "./components/analytics/VoiceCom";
import Book from "./components/analytics/Book";
import FileUploadPage from "./components/ImportExport/FileUploadPage";
import QuizUploadPage from "./components/ImportExport/QuizUploadPage";
import EntryPage from "./components/analytics/entryPage";

import ImageQuiz from "./components/quiz/ImageQuiz";
import QuizList from "./components/quiz/quizlist";
import BingoList from "./components/quiz/bingolist";
import LearningList from "./components/quiz/learninglist";
import ChangePassword from "./components/ChangePassword";
import FaceRecognition from "./components/analytics/FaceRecognition";
import TrainingModule from "./components/analytics/TrainingModule";

import MyCalendar from "./components/calendar/MyCalendar";

import Analytics from "./components/analytics/Analytics";
import WifiSetting from "./components/analytics/WifiSetting";
import NewsApp from "./components/analytics/NewsApp";
import GameList from "./components/games/gamelist";
import Games from "./components/analytics/Games";
import MemoryGame from "./components/analytics/MemoryGame";
import TicTacToe from "./components/analytics/TicTacToe";
import Maths from "./components/analytics/Maths";
import Chess from "./components/analytics/Chess";
import Sudoku from "./components/analytics/Sudoku";
import Scrabble from "./components/analytics/Scrabble";
import RobotBlocks from "./components/analytics/RobotBlocks";
import WorkflowDesinger from "./components/analytics/WorkflowDesigner";
import Call from './components/analytics/Call'
import CallV2 from './components/analytics/CallV2'
// import Call from './components/analytics/CallAlt'
import peer from './components/analytics/Call'
import EditQuiz from "./components/Common/EditQuiz";
import Messenger from "./components/Common/Messenger";
import MessengerAudio from "./components/Common/MessengerAudio";
import AudioRecorder from "./components/Common/AudioRecorder";
import Nav from "./components/Common/Nav";
import Settings from "./components/Settings/settings";
import Family from "./components/Family/Family";
import Interview from "./components/Interview/Interview";
import TechnologySupport from "./components/analytics/TechnologySupport";
import Hobbies from "./components/analytics/Hobbies";
import Entertainment from "./components/analytics/Entertainment";
import School from "./components/analytics/School";
import AudioCall from "./components/analytics/AudioCall";
import EmotionalWellBeing from "./components/analytics/EmotionalWellBeing";
import BrainTraining from "./components/analytics/BrainTraining";
import FriendsAndRelatives from "./components/analytics/FriendsAndRelatives";
import ServiceSchedulingAndManagement from "./components/analytics/ServiceSchedulingAndManagement";
import Safety from "./components/analytics/Safety";
import TechnologyTools from "./components/analytics/TechnologyTools";
import TalkWithMatilda from "./components/analytics/TalkWithMatilda";
import NewGames from "./components/analytics/NewGames";
import UserManage from "./components/analytics/UserManage";
import RobotManage from "./components/analytics/RobotManage";
import ServicePattern from "./components/analytics/ServicePattern";
import AdminPage from "./components/analytics/AdminPage";
import Fitbit from "./components/analytics/Fitbit";
import Monitoring from "./components/analytics/Monitoring";
import ServicePreference from "./components/analytics/ServicePreference";
import Translation from "./components/analytics/Translation";
import FaceDetection from "./components/analytics/FaceDetection";
import SystemSettings from "./components/analytics/SystemSettings";
import EmotionRecognition from "./components/analytics/EmotionRecognition";
import Cognitive from "./components/analytics/Cognitive";
import CognitiveSettings from "./components/analytics/CognitiveSettings";
import EyeGaze from "./components/analytics/EyeGaze";
import ExerciseWithVideo from "./components/analytics/ExerciseWithVideo";
import VoiceCommand from "./components/analytics/VoiceCommand";
import HeartRate from "./components/analytics/HeartRateV2";
import ManageProfile from "./components/analytics/Profile";
import Audiobooks from "./components/analytics/Audiobooks";
import BingoCard from "./components/analytics/BingoCard";
import NumberCard1to20 from "./components/analytics/NumberCard1to20";
import ImageQuizOrig from "./components/quiz/ImageQuizOrig";
import BingoCardSergius from "./components/analytics/BingoCardSergius";
import BingoCardCantonese from "./components/analytics/BingoCardCantonese";
import MFAPage from "./components/analytics/MFAPage";
import ConversationQuestion from "./components/analytics/ConversationQuestion";
import CommandControl from "./components/analytics/CommandControl";
import CallReceptionist from "./components/analytics/CallReceptionist";
import MonitoringV2 from "./components/analytics/MonitoringV2";
import ReceptionistCrud from "./components/analytics/ReceptionistCrud";
import Conversation from "./components/analytics/Conversation";
import Reception from "./components/analytics/Reception";
import ConversationLog from "./components/analytics/ConversationLog";
import CookingList from "./components/Cooking/cookinglist";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  } 

  render() {
    
    // unpacking the object
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    return (
      <div id="wrapper">

        <Switch>
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/home" component={EntryPage} />
          <Route exact path={["/", "/login"]} component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/profile" component={Profile} />
          <Route path="/user" component={Video} />
          <Route path="/chat" component={Chat} />
          <Route path="/map" component={Map} />
          <Route path="/management" component={RobotStatus} />
          <Route path="/control" component={SwitchLabels} />
          <Route exact path="/music" component={Music} />
          <Route exact path="/youtube" component={YouTube} />
          <Route exact path="/videos" component={YouTubeWithVideo} />
          <Route exact path="/technology-support" component={TechnologySupport} />
          <Route exact path="/voice-command" component={VoiceCommand} />
          <Route exact path="/heart-rate" component={HeartRate} />
          <Route exact path="/fitbit" component={Fitbit} />
          <Route exact path="/manage-user" component={UserManage} />
          <Route exact path="/manage-profile" component={ManageProfile} />
          <Route exact path="/manage-robot" component={RobotManage} />
          <Route exact path="/manage-resident" component={ReceptionistCrud} />
          <Route exact path="/reception" component={Reception} />
          <Route exact path="/manage-conversation" component={Conversation} />
          <Route exact path="/conversation-log" component={ConversationLog} />
          <Route exact path="/health-and-wellbeing" component={HealthAndWellBeing} />
          <Route exact path="/emotional-wellbeing" component={EmotionalWellBeing} />
          <Route exact path="/brain-training" component={BrainTraining} />
          <Route exact path="/bingo-card" component={BingoCard} />
          <Route exact path="/bingo-card-sergius" component={BingoCardSergius} />
          <Route exact path="/bingo-card-cantonese" component={BingoCardCantonese} />
          <Route exact path="/otp-authenticator" component={MFAPage} />
          <Route exact path="/number-card-1to20" component={NumberCard1to20} />
          <Route exact path="/friends-and-relatives" component={FriendsAndRelatives} />
          <Route exact path="/service-scheduling" component={ServiceSchedulingAndManagement} />
          <Route exact path="/safety" component={Safety} />
          <Route exact path="/technology-tools" component={TechnologyTools} />
          <Route exact path="/monitoring" component={Monitoring} />
          <Route exact path="/receptionist" component={MonitoringV2} />
          <Route exact path="/admin" component={AdminPage} />
          <Route exact path="/hobbies" component={Hobbies} />
          <Route exact path="/entertainment" component={Entertainment} />
          <Route exact path="/cookinglist" component={CookingList} />
          <Route exact path="/face-recognition" component={FaceRecognition} />
          <Route exact path="training-module-list" component={TrainingModule} />
          <Route exact path="/school" component={School} />
          <Route exact path="/talk-with-matilda" component={TalkWithMatilda} />
          <Route exact path="/audio-call" component={AudioCall} />
          <Route exact path="/exercise" component={ExerciseList} />
          <Route exact path="/new-games" component={NewGames} />
          <Route exact path="/smart-home" component={SmartHome} />
          <Route exact path="/voice-com" component={VoiceCom} />
          <Route path="/book" component={Book} />
          <Route path="/gamelist" component={Games} />
          <Route path="/learninglist" component={LearningList} />
          <Route path="/bingolist" component={BingoList} />
          <Route path="/games" component={Games} />
          <Route path="/memorygame" component={MemoryGame} />
          <Route path="/maths" component={Maths} />
          <Route path="/settings" component={Settings} />
          <Route path="/chess" component={Chess} />
          <Route path="/sudoku" component={Sudoku} />
          <Route path="/scrabble" component={Scrabble} />
          <Route path="/tictactoe" component={TicTacToe} />
          <Route path="/learningupload" component={FileUploadPage} />
          <Route path="/quizupload" component={QuizUploadPage} />
          <Route path="/call" component={Call} />
          <Route path="/call-receptionist" component={CallReceptionist} />
          <Route path="/callv2/:id" component={CallV2} />
          <Route path="/mod" component={BoardModerator} />
          <Route path="/calendar" component={MyCalendar} />
          <Route path="/analytics" component={Analytics} />
          <Route path="/interaction-logs" component={LogTable} />
          <Route path="/service-pattern" component={ServicePattern} />
          <Route path="/service-preference" component={ServicePreference} />
          <Route path="/translation" component={Translation} />
          <Route path="/conversation-question" component={ConversationQuestion} />
          <Route path="/family" component={Family} />
          <Route path="/system-settings" component={SystemSettings} />
          <Route path="/quiz" component={() => <ImageQuiz quizid={'Famous Faces.quiz'} />} />
          <Route path="/quizlist" component={() => <ImageQuizOrig />} />
          <Route path="/specific-quiz" component={() => <ImageQuiz />} />
          <Route path="/wifisetting" component={WifiSetting} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route path="/face-detection" component={FaceDetection} />
          <Route path="/emotion-recognition" component={EmotionRecognition} />
          <Route path="/player/:id" component={Player} />
          <Route path="/news" component={NewsApp} />
          <Route path="/robotblocks" component={RobotBlocks} />
          <Route path="/messenger" component={Messenger} />
          <Route path="/messenger-audio" component={MessengerAudio} />
          <Route path="/workflowdesigner" component={WorkflowDesinger} />
          <Route path="/command-control" component={CommandControl} />
          <Route path="/interview" component={Interview} />
          <Route path="/editquiz" component={EditQuiz} />
          <Route path="/audiorecorder" component={AudioRecorder} />
          <Route path="/cognitive" component={Cognitive} />
          <Route path="/cognitive-settings" component={CognitiveSettings} />
          <Route path="/interview" component={Interview} />
          <Route path="/eye-gaze" component={EyeGaze} />
          <Route path="/exercise-video" component={ExerciseWithVideo} />
          <Route path="/audiobooks" component={Audiobooks} />
        </Switch>
      </div>
    );
  }
}

export default App;