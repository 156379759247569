import React, { Component, useState, useEffect } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from '../Common/TitleBar';
import { Tab, Tabs } from '@material-ui/core';
import { Button } from "reactstrap";
import Box from "@material-ui/core/Box";


const styles = (mtheme) => ({
    formControl: {
        margin: mtheme.spacing(0),
        minWidth: 250,
    },
});

class Hobbies extends Component {
    constructor() {
        super();
        this.state = {
            selectedService: null,
            value: 'one',
            events: [],
            books: [],
            columns: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.renderBooks = this.renderBooks.bind(this);
        this.readBook = this.readBook.bind(this);
        this.stopBook = this.stopBook.bind(this);
    }

    componentDidMount() {
        var api = `{"api":"book", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });

        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("books"))) {
                var str = message.data.substring(0, message.data.length);
                try {
                    var obj = JSON.parse(str.replace(/\0/g, ''));
                    if (obj.type == "books") {
                        var booklist = []
                        var paths = obj.booklist;
                        paths.forEach(function (path) {
                            booklist.push({ 'path': path["path"], 'title': path["path"].replace(/^.*[\\\/]/, '').slice(0, -4) });


                        });
                        this.setState({ books: booklist });
                    }
                } catch (e) {
                    return false;
                }
            }
        }
    }

    handleChange(e) {
        if (e.target.innerText.includes("GAME")) {
            this.setState({ value: "one" });
        }
        else if (e.target.innerText.includes("AUDIOBOOK")) {
            this.setState({ value: "two" });
        }
        else if (e.target.innerText.includes("BOOK")) {
            this.setState({ value: "three" });
        }
    }

    renderBooks = (books) => {
        const renderBooks = (allNames) => {
            return Object.entries(allNames).map(([_key, value]) => (
                <Card variant="outlined">
                    <CardContent>
                        <strong>Book #{_key}</strong><br />
                        Title:  {value.title}<br />
                        Path:  <i>{value.path}</i><br /><br />
                        <Button size="small" variant="outlined" type="primary" value={value.path} onClick={this.readBook}>Read</Button>&nbsp;
                        <Button size="small" variant="outlined" type="secondary" onClick={this.stopBook}>Stop</Button>
                    </CardContent>
                </Card>));
        };

        return (
            <Box sx={{ minWidth: 100 }}>
                {renderBooks(books)}
            </Box>);
    }

    readBook = (e) => {
        let path = e.currentTarget.value;
        let api = `{"api":"book", "action":"start", "file":"${path}", "background":"yes", "soundeffect":"yes","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) })
    }

    stopBook = () => {
        let api = `{"api":"book", "action":"stop","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) })
    }

    render() {
        return (
            <div>
                <TitleBar title="Hobbies" />
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab value="one" label="Games" />
                    <Tab value="two" label="Audiobooks" />
                    <Tab value="three" label="Books" />
                </Tabs>
                {
                    this.state.value == "one" ?
                        <>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <h4>Sudoku</h4><br />
                                    <strong>Description:</strong>  Sudoku is a logic-based, combinatorial number-placement puzzle. In classic Sudoku, the objective is to fill a 9 × 9 grid with digits so that each column, each row, and each of the nine 3 × 3 subgrids that compose the grid contain all of the digits from 1 to 9.<br /><br />
                                    <Button size="small" variant="outlined" href="http://localhost:8081/sudoku">Play Now!</Button>
                                </CardContent>
                            </Card>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <h4>Scrabble</h4><br />
                                    <strong>Description:</strong>  Scrabble, board-and-tile game in which two to four players compete in forming words with lettered tiles on a 225-square board; words spelled out by letters on the tiles interlock like words in a crossword puzzle.<br /><br />
                                    <Button size="small" variant="outlined" href="http://localhost:8081/scrabble">Play Now!</Button>
                                </CardContent>
                            </Card>
                        </>
                        :
                        ""
                }
                {
                    this.state.value == "three" ?
                        <>
                            {this.renderBooks(this.state.books)}
                        </>
                        :
                        ""
                }
            </div>
        )
    }
}
export default withStyles(styles)(Hobbies);