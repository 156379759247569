import React, { Component } from "react";
import axios from "axios";
import TitleBar from "../Common/TitleBar";
import Button from '@material-ui/core/Button';
import { vocalise } from "../../socket";

class Cooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            cooking_list: [],
            photo: '',
        };
    }

    componentDidMount() {
        // Make the API call to fetch cooking_list data
        const data = JSON.stringify({
            "filename": this.props.cooking_id
        });

        const config = {
            method: 'post',
            url: 'http://192.168.163.30:8000/cooking/item',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                this.setState({
                    cooking_list: response.data.cooking_list,
                    // After API call is successful, load the photo
                }, () => {
                    this.getPhoto(this.state.index);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getText() {
        if (this.state.cooking_list.length > 0) {
            vocalise(this.state.cooking_list[this.state.index]["text"]);
            return this.state.cooking_list[this.state.index]["text"];
        }
        return "";
    }

    getPhoto(index) {
        if (this.state.cooking_list.length > 0) {
            const title = this.props.cooking_id;
            const filename = this.state.cooking_list[index]["image"];

            let data = JSON.stringify({
                "filename": title,
                "photo": filename
            });

            let config = {
                method: 'post',
                url: 'http://192.168.163.30:8000/cooking/photo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    this.setState({ photo: response.data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    handlePrev = () => {
        const newIndex = this.state.index - 1;
        if (newIndex >= 0) {
            this.getPhoto(newIndex);
            this.setState({ index: newIndex });
        }
    }

    handleNext = () => {
        const newIndex = this.state.index + 1;
        if (newIndex < this.state.cooking_list.length) {
            this.getPhoto(newIndex);
            this.setState({ index: newIndex });
        }
    }

    handleExit = () => {
        window.location.href = "/school";
    }

    render() {
        return (
            <div>
                <Button variant="outlined" onClick={this.handleExit}>Exit</Button>
                <br />
                <br />
                <img src={`data:image/jpg;base64,${this.state.photo}`} className="author-image w-100" height="500px" />
                <h3>{this.getText()}</h3>
                <br />
                <br />
                <br />
                <Button onClick={this.handlePrev}>Prev</Button>
                <Button onClick={this.handleNext}>Next</Button>
            </div>
        )
    }
}

export default Cooking;
