import React from "react";
import MonitoringV2 from "./MonitoringV2";
import TitleBar from "../Common/TitleBar";
import { useState } from "react";
import { useEffect } from "react";
import { name, sendMessage, socket, vocalise, vocaliseV2, waitForSocketConnection } from "../../socket";
import axios from "axios";
import { Button } from "reactstrap";


const Cognitive = () => {
    // Variables
    const [isStarted, setIsStarted] = useState(false);
    const [fullName, setFullName] = useState(false);
    const [beginQuestion, setBeginQuestion] = useState(false);
    const [screenText, setScreenText] = useState("Good day, welcome to cognitive and emotion assessment");
    // const [questions, setQuestions] = useState([
    //     "Selling provides you with fast money and growth. You have got what it takes. ",
    //     "Selling helps you to transform your potential into actuality. Money is not a primary consideration.",
    //     "You determine who your best prospects are and devote your time and energy on them only?",
    //     "You believe the sale depends more upon one's selling skill rather than what you are selling?",
    //     "It is more important to be associated with people than thinking of success or failure.",
    // ])
    const [questions, setQuestions] = useState([]);
    const [questionIdx, setQuestionIdx] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);

    useEffect(() => {
        waitForSocketConnection(socket, () => { vocalise("Good day, welcome to cognitive and emotion assessment") });
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log('DATA FROM DB', JSON.stringify(response.data), response.data.data.length);
                setTotalQuestions(response.data.data.length);
                setQuestions(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Functions
    const computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr';
        }
        return '1fr';
    }

    const getFullName = () => {
        let config = {
            method: 'get',
            url: 'http://192.168.163.30:8000/interview-greeting',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                checkFullName();
            });
    }

    const checkFullName = () => {
        let config = {
            method: 'get',
            url: 'http://192.168.163.30:8000/interview-name',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log('FULLNAME', JSON.stringify(response.data));
                if (response.data.status === "notok") {
                    setTimeout(checkFullName, 5000);
                } else {
                    setFullName(response.data.fullname);
                    setScreenText("");
                    setTimeout(startQuestion, 23000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const startQuestionByIndex = (idx) => {
        console.log("QUESTION ID ", idx)
        if (idx <= totalQuestions - 1) {
            console.log("STARING QUESTION")
            let config = {
                method: 'get',
                url: 'http://192.168.163.30:8000/interview-questions?idx='+ idx.toString(),
                headers: {}
            };
    
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    isNextQuestion(idx);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            stopInterview();
            setScreenText("End of Assessment. Thank you!");
        }
    }

    const isNextQuestion = (idx) => {
        let config = {
            method: 'get',
            url: 'http://192.168.163.30:8000/interview-next',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log('NEXT', JSON.stringify(response.data));
                if (JSON.stringify(response.data).includes("false")) {
                    setTimeout(isNextQuestion, 3000);
                } else  {
                    console.log("NEXT QUESTION PLEASE")
                    let idxxx = localStorage.getItem("qIdx");
                    startQuestionByIndex(idxxx);
                    localStorage.setItem("qIdx", parseInt(idxxx)+1);
                    setScreenText(questions[parseInt(idxxx)]["question"]);
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const startQuestion = () => {
        localStorage.setItem("qIdx", 1);
        setScreenText(questions[questionIdx]["question"]);
        startQuestionByIndex(questionIdx);
    }

    const startInterview = () => {
        setScreenText("Please say your fullname");
        getFullName();
    }

    const stopInterview = () => {
        let config = {
            method: 'get',
            url: 'http://192.168.163.30:8000/interview-stop',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Render Web Application
    return (
        <>
            <TitleBar title="Cognitive and Emotion Assessment" parentNode={"/health-and-wellbeing"} />
            {/* <b><i>Please click STOP INTERVIEW first always. Still fixing global state changes</i></b> */}
            <div style={{
                display: 'grid',
                gap: '2rem',
                width: '100%',
                gridTemplateColumns: computeScreenSize(),
            }} id="blocks">
                {/* <div><MonitoringV2 /></div> */}
                <div>VIDEO HERE</div>
                <div>
                    <h4>{screenText}</h4>
                    <br /><br />
                    {fullName ? <>Full Name: {fullName}</> : <></>}
                    <br></br>
                    <Button onClick={startInterview}>Start Interview</Button>
                    <Button onClick={stopInterview}>Stop Interview</Button>
                </div>
            </div>
        </>
    )
}

export default Cognitive;