import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { socket, waitForSocketConnection } from '../../socket';
import axios from 'axios';
import translationService from '../../services/translation.service';

const ReceptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
`;

const FormTitle = styled.h1`
    color: #333;
    margin-bottom: 20px;
`;

const ReceptionForm = styled.form`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #666;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const SubmitButton = styled.button`
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const RegisterButton = styled.button`
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;

    &:hover {
        background-color: #0056b3;
    }
`;

const Message = styled.div`
    text-align: center;
    margin-top: 20px;
    color: #333;
`;

const BackButton = styled.button`
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const Reception = () => {
    const [formData, setFormData] = useState({
        name: '',
        familyName: '',
        dateOfVisit: '',
        timeOfVisit: '',
        personToVisit: '',
        roomToVisit: '',
        purposeOfVisit: ''
    });

    const [remoteId, setRemoteId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const location = useLocation();
    const [language, setLanguage] = useState('english'); // Default language

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const remoteId = queryParams.get('remoteId');
        const lang = queryParams.get('language');
        if (remoteId) {
            setRemoteId(remoteId);
        }
        if (lang) {
            setLanguage(lang);
        }
    }, [location.search]);

    const callLog = (message, remid) => {
        // console.log(message, remid);
        console.log("LANGUAGE ", language)
        // var api = { "api": "vocalise", "text": message, "language": "en", "emotion": "neutral", "sound": "none", "name": remid };
        var api = { "api": "vocAlByLan", "phrase": message, "language": language, "name": remid }
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(api)) });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFocus = (field) => {
        callLog(`Please input your ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`, remoteId);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        if (remoteId) {
            formData["robot_id"] = remoteId;
            console.log('Remote ID:', remoteId, formData);

            let data = JSON.stringify(formData);

            let config = {
                method: 'post',
                url: 'https://cloud-api-389807.ue.r.appspot.com/visitor',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
            callLog("You have successfully registered. You may come in now", remoteId);
        }
        setIsSubmitted(true);
    };

    const handleBackToRegistration = () => {
        setIsSubmitted(false);
        setIsRegistering(false);
        setFormData({
            name: '',
            familyName: '',
            dateOfVisit: '',
            timeOfVisit: '',
            personToVisit: '',
            roomToVisit: '',
            purposeOfVisit: ''
        });
    };

    const handleRegister = () => {
        setIsRegistering(true);
        callLog('Please input your first name', remoteId);
    };

    const translate = (title) => {
        const dict = {
            "english": {
                "Name": "Name",
                "Family Name": "Family Name",
                "Date of Visit": "Date of Visit",
                "Time of Visit": "Time of Visit",
                "Person to Visit": "Person to Visit",
                "Room to Visit": "Room to Visit",
                "Purpose of Visit": "Purpose of Visit",
                "Submit": "Submit",
                "Register": "Register",
                "You have successfully registered. Please come in now.": "You have successfully registered. Please come in now.",
                "Reception Form": "Reception Form"
            },
            "russian": {
                "Name": "Имя",
                "Family Name": "Фамилия",
                "Date of Visit": "Дата визита",
                "Time of Visit": "Время визита",
                "Person to Visit": "К кому",
                "Room to Visit": "Номер комнаты",
                "Purpose of Visit": "Цель визита",
                "Submit": "Отправить",
                "Register": "Регистрация",
                "You have successfully registered. Please come in now.": "Вы успешно зарегистрировались. Пожалуйста, входите.",
                "Reception Form": "Форма приема"
            },
            "serbian": {
                "Name": "Ime",
                "Family Name": "Prezime",
                "Date of Visit": "Datum posete",
                "Time of Visit": "Vreme posete",
                "Person to Visit": "Osoba za posetu",
                "Room to Visit": "Broj sobe",
                "Purpose of Visit": "Svrha posete",
                "Submit": "Pošalji",
                "Register": "Registracija",
                "You have successfully registered. Please come in now.": "Uspešno ste se registrovali. Molimo vas, uđite.",
                "Reception Form": "Obrazac za prijem"
            },
            "greek": {
                "Name": "Όνομα",
                "Family Name": "Επώνυμο",
                "Date of Visit": "Ημερομηνία επίσκεψης",
                "Time of Visit": "Ώρα επίσκεψης",
                "Person to Visit": "Πρόσωπο για επίσκεψη",
                "Room to Visit": "Αριθμός δωματίου",
                "Purpose of Visit": "Σκοπός επίσκεψης",
                "Submit": "Υποβολή",
                "Register": "Εγγραφή",
                "You have successfully registered. Please come in now.": "Έχετε εγγραφεί με επιτυχία. Παρακαλώ εισέλθετε.",
                "Reception Form": "Έντυπο Υποδοχής"
            },
            "arabic": {
                "Name": "الاسم",
                "Family Name": "اسم العائلة",
                "Date of Visit": "تاريخ الزيارة",
                "Time of Visit": "وقت الزيارة",
                "Person to Visit": "الشخص للزيارة",
                "Room to Visit": "رقم الغرفة",
                "Purpose of Visit": "غرض الزيارة",
                "Submit": "إرسال",
                "Register": "تسجيل",
                "You have successfully registered. Please come in now.": "لقد تم تسجيلك بنجاح. من فضلك تفضل بالدخول.",
                "Reception Form": "نموذج الاستقبال"
            }
        }        
        
        return dict[language][title]
    }

    return (
        <ReceptionContainer>
            <FormTitle>{translate("Reception Form")}</FormTitle>
            {isRegistering ? (
                isSubmitted ? (
                    <Message>
                        {translate("You have successfully registered. Please come in now.")} <br />
                        <BackButton onClick={handleBackToRegistration}>{translate("Register")}</BackButton>
                    </Message>
                ) : (
                    <ReceptionForm onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label htmlFor="name">{translate("Name")}:</Label>
                            <Input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                onFocus={() => handleFocus('name')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="familyName">{translate("Family Name")}:</Label>
                            <Input
                                type="text"
                                id="familyName"
                                name="familyName"
                                value={formData.familyName}
                                onChange={handleChange}
                                onFocus={() => handleFocus('familyName')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="dateOfVisit">{translate("Date of Visit")}:</Label>
                            <Input
                                type="date"
                                id="dateOfVisit"
                                name="dateOfVisit"
                                value={formData.dateOfVisit}
                                onChange={handleChange}
                                onFocus={() => handleFocus('dateOfVisit')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="timeOfVisit">{translate("Time of Visit")}:</Label>
                            <Input
                                type="time"
                                id="timeOfVisit"
                                name="timeOfVisit"
                                value={formData.timeOfVisit}
                                onChange={handleChange}
                                onFocus={() => handleFocus('timeOfVisit')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="personToVisit">{translate("Person to Visit")}:</Label>
                            <Input
                                type="text"
                                id="personToVisit"
                                name="personToVisit"
                                value={formData.personToVisit}
                                onChange={handleChange}
                                onFocus={() => handleFocus('personToVisit')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="roomToVisit">{translate("Room to Visit")}:</Label>
                            <Input
                                type="text"
                                id="roomToVisit"
                                name="roomToVisit"
                                value={formData.roomToVisit}
                                onChange={handleChange}
                                onFocus={() => handleFocus('roomToVisit')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="purposeOfVisit">{translate("Purpose of Visit")}:</Label>
                            <Input
                                type="text"
                                id="purposeOfVisit"
                                name="purposeOfVisit"
                                value={formData.purposeOfVisit}
                                onChange={handleChange}
                                onFocus={() => handleFocus('purposeOfVisit')}
                                required
                            />
                        </FormGroup>
                        <SubmitButton type="submit">{translate("Submit")}</SubmitButton>
                    </ReceptionForm>
                )
            ) : (
                <RegisterButton onClick={handleRegister}>{translate("Register")}</RegisterButton>
            )}
        </ReceptionContainer>
    );
};

export default Reception;
