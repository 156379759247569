import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";


const styles = (theme) => ({});

class BrainTraining extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 18.01L12.01 17.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #741d6d, #aa299f)',
                    title: 'Quiz',
                    slug: '/quizlist',
                    locations: ['games'],
                },
                {
                    svg: `
                        <path d="M12 3.03998L0 9.99998L5 12.9V16.3094C5 16.836 5.13861 17.3533 5.40192 17.8094C5.66522 18.2655 6.04394 18.6442 6.5 18.9075L12 22.0829L17.5 18.9075C17.9561 18.6442 18.3348 18.2655 18.5981 17.8094C18.8614 17.3533 19 16.836 19 16.3094V12.9L22 11.16V17H24V9.99998L12 3.03998ZM12 5.35208L18.29 8.99998H12V11H18.29L12 14.6479L3.9863 9.99998L12 5.35208ZM17 16.3094C16.9997 16.4849 16.9534 16.6572 16.8657 16.8092C16.7779 16.9611 16.6518 17.0874 16.5 17.1754L12 19.7735L7.5 17.1754C7.34815 17.0874 7.22206 16.9611 7.13432 16.8092C7.04657 16.6572 7.00026 16.4849 7 16.3094V14.06L12 16.96L17 14.06V16.3094Z" fill="currentColor"/>
                    `,
                    background: 'linear-gradient(0deg, #9d8f12, #dfcc22)',
                    title: 'Learning',
                    slug: '/learninglist',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
            allowedAcccess: []
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
    }

    componentDidMount() {
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access});
            })
            .catch((error) => {
                console.log(error);
            });
     }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Brain Training")} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        this.state.allowedAcccess.includes(app.title) && <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(BrainTraining);