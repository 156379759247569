import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TitleBar from '../Common/TitleBar';
import { sendMessage, socket, waitForSocketConnection } from '../../socket';

const Styles = styled.div`
  padding: 0rem;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color: #6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  padding: 10px;
  margin: 10px;
  font-size: 16px;
`;

const Table = styled.table`
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #4caf50;
  color: white;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const Conversation = () => {
  const [newString, setNewString] = useState('');
  const [service, setService] = useState('conv_music.json');
  const [strings, setStrings] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    socket.onmessage = (message) => {
      let obj = JSON.parse(message.data);
      if (obj.strings) {
        setStrings(obj.strings);
      }
    }
  })

  useEffect(() => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"conSetGet", "service":"${service}", "name": "${name}", "robot_id": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })
  }, [service]);

  const handleAddString = () => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"conSetPost", "service":"${service}", "new_string": "${newString}", "name": "${name}", "robot_id": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })
  };

  const handleDeleteString = (stringToDelete) => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"conSetDelete", "service":"${service}", "new_string": "${stringToDelete}", "name": "${name}", "robot_id": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })
  };

  return (
    <Styles>
      <TitleBar title="Conversation" parentNode={"/technology-tools"} />
      <Input
        type="text"
        value={newString}
        onChange={(e) => setNewString(e.target.value)}
        placeholder="Enter a string"
      />
      <Select value={service} onChange={(e) => setService(e.target.value)}>
        <option value="conv_music.json">MUSIC</option>
        <option value="conv_book.json">BOOK</option>
        <option value="conv_detection.json">DETECTION</option>
        <option value="conv_movie.json">MOVIE</option>
        <option value="conv_weather.json">WEATHER</option>
        <option value="conv_time.json">TIME</option>
        <option value="conv_date.json">DATE</option>
        <option value="conv_call.json">CALL</option>
        <option value="conv_topic.json">TOPIC</option>
      </Select>
      <Button onClick={handleAddString}>Add String</Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Table>
        <thead>
          <tr>
            <TableHeader>#</TableHeader>
            <TableHeader>String</TableHeader>
            <TableHeader>Service</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {strings.map((str, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{str}</TableCell>
              <TableCell>{service}</TableCell>
              <TableCell>
                <Button onClick={() => handleDeleteString(str)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Styles>
  );
};

export default Conversation;
