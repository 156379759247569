import React from 'react';

const Matilda1Illustration = () => {
  return (<svg strokeMiterlimit="10" version="1.1" viewBox="0 0 600 750" id="matilda1">
  <g opacity="1">
    <path id="L" d="M174.027 296.419C171.135 293.128 169.981 273.708 167.092 277.001C143.084 304.362 131.344 322.092 121.139 354.083C110.935 386.073 98.7242 420.379 95.1982 435.611C76.669 515.658 66.3817 609.903 75.9278 622.386C88.3592 638.643 231.076 710.201 246.128 709.448C257.108 708.899 298.217 710.73 356.809 718.177C415.365 725.62 489.299 731.753 491.178 730.534C544.747 695.771 565.634 648.741 568.065 643.88C575.744 628.521 563.435 538.123 562.018 533.872C560.504 529.33 548.347 468.034 543.966 443.546C535.528 396.389 519.213 318.815 506.857 283.805C505.365 279.579 484.368 232.956 480.172 231.382C475.598 229.667 470.23 261.436 431.689 278.483C393.149 295.53 361.789 296.903 340.526 301.459C319.773 305.906 233.797 314.059 222.679 311.836C212.668 309.833 185.621 309.612 174.027 296.419Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" d="M166.923 96.7273C146.046 126.085 138.427 159.055 141.723 195.303C143.67 216.723 161.997 287.727 179.522 299.067C195.361 309.315 238.762 313.151 256.604 312.408C272.819 311.732 322.56 310.787 364.829 301.342C407.818 291.737 443.257 273.596 449.308 268.679C456.374 262.938 463.065 255.563 467.838 247.926C475.477 235.703 476.972 216.276 479.696 201.973C483.98 179.483 481.9 167.354 476.732 145.645C470.304 118.649 439.245 74.2835 416.697 55.963C408.313 49.1508 387.791 32.9867 341.097 24.8338C314.771 20.2371 292.451 20.8728 267.722 29.2808C230.663 41.8807 190.64 63.3747 166.923 96.7273Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" d="M390.015 74.4922C375.449 78.9923 362.418 88.4718 350.733 98.2097C308.422 133.468 302.211 198.146 321.827 247.185C327.682 261.821 352.871 301.506 358.383 302.479C360.626 302.875 390.246 294.86 392.469 294.366C396.916 293.378 437.979 277.869 448.432 270.682C465.978 258.62 480.083 229.665 483.402 208.644C485.781 193.575 483.527 178.305 481.179 163.433C480.481 159.013 466.585 122.589 465.614 118.221C463.129 107.04 445.3 87.5678 441.155 81.1627C436.989 74.7241 411.76 67.7738 390.015 74.4922Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
    <path id="L" d="M433.344 108.469C414.252 108.469 395.538 125.62 388.86 134.23C375.418 151.562 374.229 164.237 372.839 171.647C368.522 194.65 370.338 218.09 377.858 240.296C382.652 254.454 390.8 266.736 402.732 275.961C407.838 279.909 413.732 283.528 420.451 282.524C424.01 281.992 432.662 281.388 445.568 273.002C464.608 260.63 474.725 248.048 483.087 220.24C484.406 215.851 488.701 180.562 480.985 149.117C480.143 145.684 466.561 108.469 433.344 108.469Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
    <path id="L" className="matilda-ear" d="M427.666 150.464C403.854 166.632 400.988 203.263 413.302 227.76C417.491 236.093 424.265 247.437 434.721 248.349C447.229 249.441 458.187 239.157 463.735 229.226C470.263 217.542 471.706 211.614 473.095 197.114C473.651 191.297 474.866 181.023 468.747 168.348C461.514 153.365 451.697 150.177 445.179 147.466C440.872 145.675 432.422 147.235 427.666 150.464Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
    <path id="L" className="matilda-eye" d="M170.597 139.678C161.667 152.013 160.869 165.77 163.366 185.068C165.864 204.366 175.248 211.706 184.859 211.404C194.47 211.101 199.371 201.86 201.811 183.857C204.251 165.854 201.355 151.803 194.62 139.392C187.884 126.98 179.527 127.342 170.597 139.678Z" fill="#000000" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" className="matilda-eye" d="M270.868 134.299C256.781 140.973 249.502 162.867 250.382 183.336C251.038 198.586 262.973 224.091 278.334 213.489C297.064 200.56 297.67 166.4 294.344 151.007C291.784 139.16 284.547 127.819 270.868 134.299Z" fill="#000000" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" d="M180.392 80.9802C180.392 80.9802 209.914 62.6426 247.913 70.1767C285.912 77.7108 334.426 113.223 334.426 113.223" fill="none" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
    <path id="L" className="matilda-cheek" d="M275.659 229.354C271.284 229.354 266.595 230.647 263.159 233.354C260.58 235.386 259.433 237.802 258.394 240.916C257.423 243.832 257.329 248.286 257.159 251.354C256.538 262.53 263.327 281.618 275.159 285.354C291.604 290.547 298.868 272.116 298.159 259.354C297.702 251.12 295.968 238.419 288.159 233.354C284.489 230.973 280.034 229.354 275.659 229.354Z" fill="#F70D7C" fillRule="evenodd" opacity="1" stroke="none" />
    <path id="L" className="matilda-cheek" d="M162.112 234.026C167.155 233.297 175.843 245.727 177.423 248.23C182.038 255.537 191.154 271.091 182.653 278.592C181.545 279.569 176.639 280.786 175.062 280.983C169.598 281.666 167.93 275.244 165.672 271.406C160.246 262.182 157.042 253.704 154.839 243.24C153.642 237.553 155.726 234.948 162.112 234.026Z" fill="#F70D7C" fillRule="evenodd" opacity="1" stroke="none" />
    <path id="L" d="M362.433 330.781C346.029 345.375 344.472 355.027 307.593 373.094C263.465 394.713 246.074 402.504 198.159 403.52C144.945 404.648 124.139 371.775 121.275 366.242C119.331 362.486 111.773 391.898 101.625 423.628C97.8523 435.426 111.011 455.574 125.773 465.503C159.459 488.16 190.503 488.75 206.574 483.025C242.301 470.297 273.246 463.851 306.386 433.453C357.446 386.619 401.299 333.226 415.396 306.597C417.462 302.695 428.612 287.52 424.808 285.618C419.963 283.196 397.06 296.38 392.266 298.903C384.81 302.827 378.837 316.187 362.433 330.781Z" fill="#b0b0b0" fillRule="evenodd" opacity="1" stroke="none" />
    <path id="L" d="M293 640.382C285.831 653.303 257.386 701.532 263.942 712.061C267.22 717.327 307.976 721.996 322.123 723.765C335.088 725.386 410.898 730.509 419.349 731.187C427.364 731.83 476.327 736.558 490.802 729.38C493.541 728.021 497.306 724.344 499.074 721.813C505.21 713.029 500.469 703.732 497.611 694.617C495.94 689.285 464.451 635.866 449.339 624.4C430.582 610.168 412.281 599.246 389.176 594.318C371.346 590.514 314.441 601.743 293 640.382Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" d="M83.8871 583.476C65.6367 579.196 17.4151 655.74 22.1125 679.227C22.908 683.204 24.3981 687.281 27.0545 690.346C36.9817 701.801 57.4325 699.876 71.0966 700.445C91.6894 701.303 139.417 703.343 143.535 703.343C147.654 703.343 186.013 705.679 189.896 704.309C193.477 703.045 222.156 705.041 221.332 701.335C220.485 697.521 112.835 590.266 83.8871 583.476Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="#000000" strokeDasharray="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path id="L" className="matilda-eye-highlight" d="M264.403 144.189C262.748 146.527 259.682 149.614 257.78 153.503C255.423 158.324 254.32 164.074 253.577 167.572C252.234 173.892 254.611 174.098 258.317 168.862C260.424 165.887 259.626 161.482 261.827 156.862C264.592 151.058 266.803 149.226 267.352 146.491C267.693 144.788 268.11 138.953 264.403 144.189Z" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="none" />
    <path id="L" className="matilda-eye-highlight" d="M173.563 140.485C166.339 145.419 164.334 159.892 164.505 165.107C164.675 170.322 168.698 165.908 168.698 165.908C168.698 165.908 167.561 161.184 169.096 155.483C170.232 151.263 173.161 145.986 174.147 144.298" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="none" />
  </g>
</svg>
  );
}

export default Matilda1Illustration;