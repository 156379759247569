import React, { useState, useEffect } from "react";
import { socket, vocalise, waitForSocketConnection } from "../socket";

const userMediaConfig = {
  video: true,
  audio: {
    autoGainControl: false,
    channelCount: 2,
    echoCancellation: true,
    latency: 0,
    noiseSuppression: false,
    sampleRate: 48000,
    sampleSize: 16,
    volume: 1.0
  }
};

export default function useUserMedia() {
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    const enableStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(userMediaConfig);
        setMediaStream(stream);
      } catch (error) {
        setTimeout(() => {
          window.location = window.location.origin + "/friends-and-relatives";
          alert("I can't start the video call. Please allow camera and microphone permissions.")
        }, 1500);
        console.log(error);
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return () => {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    }
  }, [mediaStream]);

  return mediaStream;
}