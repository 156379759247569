
import React, { Component, useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { useTable } from 'react-table'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input } from "reactstrap";
import { Link } from 'react-router-dom';
import { sendMessage, socket, name, waitForSocketConnection } from '../../socket';
import TitleBar from '../Common/TitleBar';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import TranslationService from "../../services/translation.service";

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `

const Styles = styled.div`
  padding: 0rem;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color: #6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
    th{
      margin: 0;
    padding: 10px 10px;
    background: #ccc;
    border-radius: 10px;
    font-size: 16px;
    }
  }
`


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {/* {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))} */}
        <tr>
          <th>Book List</th>
          <th style={{ width: '200px' }}>Action</th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Book = () => {
  localStorage.setItem("isMounted", "YES")
  const [gotBooks, setGotBooks] = useState(false);
  const [songs, setSongs] = useState([]);
  const [currentPlay, setCurrentPlay] = useState("Select a book");
  let history = useHistory();
  useEffect(() => {
    const pageLogin = localStorage.getItem('remoteId');
    if (!pageLogin) {
      history.push("/login");
    }
    if (!gotBooks) {
      let name = localStorage.getItem('remoteId');
      var api = `{"api":"book", "action":"get","name": "${name}"}`
      waitForSocketConnection(socket, () => { sendMessage(api) })

    }
    socket.onmessage = (message) => {
      if ((message.data) && (message.data.toString().includes("book")) && (message.data.toString().includes("start"))  && (!message.data.toString().includes("startEmoREco"))) {
        let obj = JSON.parse(message.data);
        console.log("PLAYING ", obj.file.split("/")[3])
        setCurrentPlay("Reading " + obj.file.split("/")[3])
      }
      if ((message.data) && (message.data.toString().includes("books"))) {
        var str = message.data.substring(0, message.data.length);
        // console.log(str);
        try {
          var obj = JSON.parse(str.replace(/\0/g, ''));
          if (obj.type == "books") {
            setGotBooks(true);
            var booklist = []
            var paths = obj.booklist;
            paths.forEach(function (path) {
              // console.log(path["path"]);
              booklist.push({ 'path': path["path"], 'title': path["path"].replace(/^.*[\\\/]/, '').slice(0, -4) });


            });
            setSongs(transformData(booklist));
            // console.log(booklist);
          }
        } catch (e) {
          return false;
        }
      }



    };
  }, [songs]);

  const transformData = (obj) => {
    let finalObject = []
    for (let i = 0; i < obj.length; i++) {
      obj[i]["title"] = computeTitle(obj[i]["title"])
      finalObject.push(obj[i])
    }
    return obj
  }

  const handleStartClick = (e) => {
    let name = localStorage.getItem('remoteId');
    //  alert(e.currentTarget.value);
    var api = `{"api":"book", "action":"start", "file":"${e.currentTarget.value}", "background":"yes", "soundeffect":"yes","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })

    var api2 = `{"api":"startEmoREco", "service":"book", "name": "${name}", "robot_id": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api2) })

    // var startHeadPose = `{"api":"startHeadPose", "action":"get","name": "${name}"}`
    // waitForSocketConnection(socket, () => { sendMessage(startHeadPose) })
  }

  const handleStopClick = (e) => {
    let name = localStorage.getItem('remoteId');
    // alert(e.target.value);
    var api = `{"api":"book", "action":"stop","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })

    var api2 = `{"api":"stopEmoREco", "service":"book", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api2) })
    setCurrentPlay("Select a book")

  }

  //     const columns = React.useMemo(
  //         () => [
  //             {
  //                 Header: 'Title',
  //                 accessor: 'title',

  //             },
  //             {
  //               Header: 'Action',
  //               accessor: 'path',
  //               Cell: ({ cell }) => (
  //                 <div>
  //                     <Button size="large" variant="outlined" color="primary" value={cell.row.values.path} onClick={handleStartClick}>Start</Button>
  //                     <Button size="large" variant="outlined" color="secondary" value={cell.row.values.path} onClick={handleStopClick}>Stop</Button>
  //                 </div>
  //               )

  //             },


  //         ],
  //         []
  //     )

  //     return (
  //         <Styles>

  //       <Table columns={columns} data={songs} />
  //     </Styles>

  //       );
  // }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
      },
      {
        accessor: 'path',
        Cell: ({ cell }) => (
          <div>
            <Button size="small" color="primary" value={cell.row.values.path} onClick={handleStartClick}>
              <svg style={{ marginRight: '3px' }} width="18" height="18" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              Play
            </Button>
            <Button size="small" color="secondary" value={cell.row.values.path} onClick={handleStopClick}>
              Stop
            </Button>
          </div>
        )

      },


    ],
    []
  )
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }

  return (
    <>
      <TitleBar title={computeTitle("Books")} parentNode={"/entertainment"} />
      <Button size="small" variant="outlined" color="primary" onClick={handleClickOpen}>
        {computeTitle("Add Books")}
      </Button>
      <Button size="small" variant="outlined" color="primary"><a href="/audiobooks">Audiobooks</a></Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Books</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            helperText="Please add your Book Title"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Book Link"
            type="text"
            helperText="Please add the books links from YouTube, Dailymotion, or any links"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Styles>
        <i><small>{computeTitle(currentPlay)}</small></i>
        <Table columns={columns} data={songs} />
      </Styles>
    </>
  );
}

export default Book;