import React, { useEffect, useState } from "react";
import TitleBar from "../Common/TitleBar";
import { Button } from "@material-ui/core";
import axios from "axios";
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";

const ConversationQuestion = () => {
    // Sample questions data
    const [questions, setQuestions] = useState([]);

    socket.onmessage = (message) => {
        console.log("MESSAGE ", message.data)
        if (message.data) {
            var obj = JSON.parse(message.data);
            var objStr = message.data.toString();
            if (objStr.includes("conve-questions") && obj['conve-questions']) {
                const conversationData = obj['conve-questions']["conversationData"]
                setQuestions(conversationData)
            }
        }
    }

    const handleDelete = (index) => {
        // Filter out the question at the specified index
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
        let data = JSON.stringify(updatedQuestions);
        var api = `{"api":"conve-createques", "action":"get", "name": "${name}", "questions": ${data}}`;
        console.log('Joanna ...', api)
        waitForSocketConnection(socket, () => { sendMessage(api) });

        // let config = {
        //     method: 'post',
        //     url: 'http://192.168.163.30:8000/save_json',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: data
        // };

        // axios.request(config)
        //     .then((response) => {
        //         console.log(JSON.stringify(response.data));
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    };

    const handleAdd = () => {
        const newQuestion = prompt("Enter the new question:");
        if (newQuestion) {
            setQuestions([...questions, newQuestion]);
            console.log('NEW QUESTION ', [...questions, newQuestion]);

            let data = JSON.stringify([...questions, newQuestion]);
            var api = `{"api":"conve-createques", "action":"get", "name": "${name}", "questions": ${data}}`;
            console.log('Joanna ...', api)
            waitForSocketConnection(socket, () => { sendMessage(api) });
            // let config = {
            //     method: 'post',
            //     url: 'http://192.168.163.30:8000/save_json',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     data: data
            // };

            // axios.request(config)
            //     .then((response) => {
            //         console.log(JSON.stringify(response.data));
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
        }
    };

    useEffect(() => {
        var api = `{"api":"conve-questions", "action":"get", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        // let config = {
        //     method: 'get',
        //     url: 'http://192.168.163.30:8000/get_json',
        //     headers: {}
        // };

        // axios.request(config)
        //     .then((response) => {
        //         console.log(JSON.stringify(response.data));
        //         setQuestions(response.data.conversationData);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

    }, []);

    return (
        <>
            <TitleBar title="Conversation Question" parentNode={"/technology-tools"} />
            <div style={{ padding: "20px" }}> {/* Adding padding for spacing */}
                <Button onClick={handleAdd} variant="outlined">Add New Entry</Button>
                <Button onClick={() => { window.location = window.location.origin + '/manage-conversation' }} variant="outlined">Conversation Context</Button>
                <table style={{ width: "100%", marginTop: "10px" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }}>Question</th>
                            <th style={{ textAlign: "left" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map((question, index) => (
                            <tr key={index}>
                                <td>{question}</td>
                                <td>
                                    <Button onClick={() => handleDelete(index)} variant="outlined">Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ConversationQuestion;
