import React from 'react';

import Option from './Option';
import Timer from './Timer';
import './css/loader.css';


function MainContent(props) {
    return (
        <div className="col-12 main-content clearfix p-0 overflow-hidden">
            {/* <div className="col-lg-4 float-left item-image"> */}
            <div className="float-left">
                { props.hasPhoto
                    ? <img src={`data:image/jpg;base64,${props.imageUrl}`} className="author-image" alt={props.correctAnswer} height="250px" />
                    : <div className="loader"></div>
                }
            </div>
            <div className="float-left col-lg-2 timer-comp">
                <Timer totalSecs={props.timerLimit} showResult={props.showResult}/>
            </div>
            {props.setOfAnswer
                ? <div className="col-lg-5 float-right item-options">
                    {props.setOfAnswer.map( (item, index) => <Option item={item} key={index} enableContinueBtn={props.enableContinueBtn} answer={props.correctAnswer}/>)}
                  </div>
                : <div className="col-lg-5 float-right item-options option-loader">
                    <div className="loader"></div>
                  </div>
            }
        </div>
    )
}

export default MainContent;