import React, { Component, useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from '../Common/TitleBar';
import { Button } from "@material-ui/core";


const styles = (mtheme) => ({
    formControl: {
        margin: mtheme.spacing(0),
        minWidth: 250,
    },
});

class VoiceCom extends Component {
    constructor() {
        super();
        this.state = {
            selectedService: null,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <TitleBar title="Voice Command" />
                <Button size="small" type="primary" variant="outlined" value="add">Add New Command</Button>
                <Button size="small" type="primary" variant="outlined" value="add">Train</Button>
            </div>
        )
    }
}
export default withStyles(styles)(VoiceCom);