import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import AuthService from "../../services/auth.service";
import SelectedList from "./SelectedList";
import Grid from '@material-ui/core/Grid';
import ImageUploadCard from "./ImageUploadCard";

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import PromptList from "./PromptList";
// import AudioRecorder from 'react-audio-recorder';
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import AudioRecorder from "./AudioRecorder";
import { useHistory } from "react-router-dom";
import TitleBar from "./TitleBar";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },


  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0),
      width: '100%',
      margin: '10px 0!important',
    },
  },

}));

const MessengerAudio = () => {
  const [spacing, setSpacing] = React.useState(50);
  const [prompts, setPrompts] = React.useState([]);
  const [prompt, setPrompt] = React.useState("type your message here or select from below list");

  const [times, setTimes] = React.useState('2');
  const [interval, setInterval] = React.useState('15');
  const classes = useStyles();
  const [isAudio, setIsAudio] = React.useState(true);



  let history = useHistory();

  // useEffect(() => {
  //   // AuthService.getPrompts()

  //   // .then( (response) => {   

  //   //   setPrompts(response.data.prompts);  
  //   // })
  //   let samplePrompts = ["Good morning!", "Good night!", "How are you?", "Please call me"]
  //   setPrompts(samplePrompts);
  // }, []);




  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleTimesChange = (event) => {
    setTimes(event.target.value);
  };

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleSelect = (text) => {
    setPrompt(text);
  }

  const handleToggle = (e) => {
    // setIsAudio(true);
    history.push("/messenger-audio")
  }

  const handleToggleText = (e) => {
    // setIsAudio(false);
    history.push("/messenger")
  }

  const handleClick = () => {

    AuthService.sendPrompt(prompt, times, interval);
    // alert(`send prompt ${prompt} time = ${times}, interval = ${interval}`);
  };

  return (
    <>
    <TitleBar title={"Audio Message"} parentNode={"/messenger"}/>
      <div className={classes.root}>
        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={6} className="">
                <div className="card">
                  <div>
                    <p>Record your voice message</p>
                    <KeyboardVoiceIcon onClick={handleToggle} />
                    <BorderColorRoundedIcon onClick={handleToggleText} />
                  </div>
                  <AudioRecorder />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default MessengerAudio;