import React, { Component } from 'react';
import axios from "axios";
import './css/ImageQuiz.css';
import './css/bootstrap-min.css';

import LearningMain from './LearningMain';
import Hero from './Hero';
import LearningList from './learninglist';
import Footer from './Footer';
import ImageQuiz from './ImageQuiz';
import PlayAgain from './PlayAgain';
import { vocalise, socket, waitForSocketConnection, toCode, sendMessage } from '../../socket';
// import {getLearningPhoto} from '../../services/auth.service';
import AuthService from "../../services/auth.service";
import Button from '@material-ui/core/Button';
var stop = false;


const getLearningPhoto = (quizid, id) => {
  return AuthService.getLearningPhoto(quizid + '/' + id);
};

const getDataApi = (_self) => {
  const getRandomItem = (arr) => {
    let item = arr[_self.state.qIndex];


    return item;
  };

  let index = _self.state.qIndex;
  if (index === _self.state.questions.length - 1) {
    _self.setState({ endOfQuiz: true });

  }
  else {
    var qObj = getRandomItem(_self.state.questions);



    getQuestionPhoto(_self, _self.state.quizid, qObj.image);

    _self.setState({
      question: qObj.text,
      correctAnswer: '',
      setOfAnswer: [],
      showContinueBtn: false,
      qIndex: index + 1
    })
    // var speak = { "api": "vocalise", "text": toCode(qObj.text).replaceAll("\"", "").replaceAll("'", ""), "language": "en"};
    // waitForSocketConnection(socket, () => { vocalise(qObj.text) });
    // vocalise(qObj.text);
    var data = { "text": qObj.text };
    var message = `{"action":"vocalisationCustom","event":${JSON.stringify(data)}, "name":"${localStorage.getItem("remoteId")}" }`;
    waitForSocketConnection(socket, () => { sendMessage(message) });
  }


}

const getQuizContent = (_self, quizid) => {


  AuthService.getLearningContent(quizid)
    .then((response) => {
      let listOfItem = [];

      listOfItem = response.data.learning;
      console.log(listOfItem);

      _self.setState({
        questions: listOfItem
      })

      getDataApi(_self);
    })
}

const handleStopCast = () => {
  this.setState({ hasQuiz: false });
  this.setState({ endOfQuiz: false });
}
const getQuestionPhoto = (_self, quizid, photoid) => {

  if (photoid === "") {
    _self.setState({
      hasPhoto: false
    });


  } else {
    getLearningPhoto(quizid, photoid).then(
      (response) => {
        _self.setState({
          imageUrl: response.data,
          hasPhoto: true
        })

      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
      }
    );
  }


}

class Learning extends Component {
  constructor(props) {
    super(props);

    this.enableContinueBtn = this.enableContinueBtn.bind(this);
    this.newPuzzle = this.newPuzzle.bind(this);
    this.showResult = this.showResult.bind(this);
  }

  static initialState = () => ({
    imageUrl: '',

    question: '',
    correctAnswer: '',
    setOfAnswer: [],
    savedResult: [],
    showContinueBtn: false,
    timerLimit: 60,
    isTimerReached: false,
    oneQuesAttemptTime: 60,
    questions: [],
    qIndex: 0,
    endOfQuiz: false,
    hasPhoto: false,
    stop: false

  })

  state = Learning.initialState();

  showResult = () => {
    this.setState({
      isTimerReached: true,
      showContinueBtn: false
    })
  }

  resetQuiz = () => {
    var _self = this;
    this.setState(Learning.initialState());
    getQuizContent(_self, this.props.quizid);
  }

  handler = (val) => {

  }


  newPuzzle = () => {
    var _self = this;
    const allOptionsObj = document.querySelectorAll('.item-options .option');
    for (var obj of allOptionsObj) {
      obj.classList.remove('red', 'green');
      obj.disabled = false;
    }
    this.setState({
      imageUrl: ''
    })
    getDataApi(_self);
  }

  enableContinueBtn = (isAnswerCorrect) => {
    this.setState((prevState) => ({
      showContinueBtn: true,
      savedResult: prevState.savedResult.concat(isAnswerCorrect)
    }))
  }

  componentDidMount() {
    var _self = this;
    const quizid = this.props.quizid;

    this.setState({
      quizid: quizid

    })
    getQuizContent(_self, quizid);

    socket.onmessage = (message) => {
      var str = message.data.substring(0, message.data.length);
      console.log(str);
      if (str === 'vocalise_done' && !this.state.stop && !this.state.endOfQuiz) {

        setTimeout(function () { //Start the timer
          this.newPuzzle();
        }.bind(this), 1000)

      }

    }


  }

  componentWillUnmount() {
    socket.onmessage = null;
  }

  render() {
    let { imageUrl, question, correctAnswer, setOfAnswer, showContinueBtn, timerLimit, hasPhoto } = this.state;
    if (this.state.endOfQuiz) {
      var hasQuiz = false;
      AuthService.getQuizList().then(
        (response) => {
          response.data.quiz.map((item, i) => {

            if (item == this.state.quizid + '.quiz') {
              this.setState({ hasQuiz: true });
              //alert(item + '=' + this.state.quizid+'.quiz');
            }
          });

        },
        (error) => {

        });

      if (this.state.hasQuiz) {
        //  alert("start quiz");
        return <ImageQuiz quizid={this.state.quizid + '.quiz'} handler={this.handler} />
      }
      else {
        // alert("return quiz list");
        return <LearningList />
      }

    } else
      return (
        <div className="container-fluid">
          {/* <Button size="small" variant="outlined" color="primary" onClick={handleStopCast}>
            Stop Cast
          </Button> */}
          <Hero question={question} />

          <LearningMain
            imageUrl={imageUrl}
            correctAnswer={correctAnswer}
            setOfAnswer={setOfAnswer}
            showContinueBtn={showContinueBtn}
            enableContinueBtn={this.enableContinueBtn}
            timerLimit={timerLimit}
            showResult={this.showResult}
            hasPhoto={hasPhoto}
          />




          <Footer />
        </div>
      )
  }
}

export default Learning;
