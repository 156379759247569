import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Bingo from "./Bingo";
import AuthService from "../../services/auth.service";
import { waitForSocketConnection, socket, sendMessage, name } from "../../socket";
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1D3557',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#A8DADC',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: '#1D3557',
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function BingoList() {

  const [selectquiz, setQuizId] = useState('');
  const [isSelected, setSelected] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [showHow, setShowHow] = useState(false);
  let remoteId = localStorage.getItem("remoteId") || "Matilda_aa53-g"
  let selectedTitle = null

  useEffect(() => {
    let mylist = [];
    const currentUrl = window.location.href;
    if (currentUrl.includes("remoteId")) {
      remoteId = currentUrl.split("?")[1].split("=")[1].trim()
      console.log("Mounted ", remoteId)
      localStorage.setItem("remoteId", remoteId)

      selectedTitle = currentUrl.split("?")[1].split("=")[2].trim().replace("%20", " ")
      console.log("Selected title ...", selectedTitle)
      if (selectedTitle) {
        imageClick(selectedTitle);
      }
    }
    var message = `{"action":"getBingoList", "name":"${remoteId}" }`;
    waitForSocketConnection(socket, () => { sendMessage(message) });

    socket.onmessage = (message) => {
      if ((message.data) && (message.data.toString().includes("ABC Bingo"))) {
        var obj = JSON.parse(message.data);
        obj.bingo.map((item, i) => {
          mylist.push({
            url: '/images/button.png',
            title: item,
            width: '30%',
          });
        });
        setData(mylist);
      }
    }
  }, []);

  const imageClick = (title) => {
    setSelected(true);
    setQuizId(title);


  }

  const handler = (val) => {
    setSelected(val);
  }

  const [deviceCast, setDeviceCast] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();

  const handleCastLearn = () => {
    setDeviceCast(true);
  }

  const handleCloseCast = () => {
    setDeviceCast(false);
  }

  const handleSaveCast = (e) => {
    console.log("Scanning devices...", e.currentTarget.value)
    let url = "https://app.mymatilda.com.au/bingolist?remoteId=" + remoteId + "=" + e.currentTarget.value.replaceAll(" ", "%20")
    console.log("URLTHIS ", url)
    setSelectedDevice();
    setDeviceCast(false);
    var cast = `{"api":"handleStartCastSite", "action":"start", "url": "${url}", "ip": "${localStorage.getItem("castDevice")}", "name": "${remoteId}"}`;
    socket.send(cast)
  }

  const handleContentChangeCast = (e) => {
    setSelectedDevice(data[e.currentTarget.value].title)
  };

  if (isSelected) {
    //alert(selectquiz);
    //setSelected(false);
    return (<><TitleBar title="Bingo List" parentNode={"/new-games"} /><div className={classes.root}>{<Bingo quizid={selectquiz} handler={handler} />}</div></>);

  } else
    return (
      <>
        <TitleBar title="Bingo List" parentNode={"/new-games"} />
        <Button size="large" variant="outlined" onClick={() => setShowHow(true)}>How to play?</Button>
        <Button size="large" variant="outlined" onClick={handleCastLearn}>Cast</Button>
        <Dialog
          open={deviceCast}
          onClose={handleCloseCast}
          fullWidth
        >
          <DialogTitle id="form-dialog-title" onClose={handleCloseCast}>Choose Item to Cast</DialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <InputLabel htmlFor="content-label">Content</InputLabel>
              <Select defaultValue={selectedDevice} value={selectedDevice} id="select-content" onChange={handleContentChangeCast} fullWidth>
                {data.map((categoria, i) => (
                  <MenuItem key={i} value={categoria.title}>{categoria.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCast} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveCast} value={selectedDevice} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showHow}
          onClose={() => setShowHow(false)}
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>How To Play BINGO?</DialogTitle>
          <DialogContent>
            <div>
              <p>
                Hey there, future Bingo champ! 🎉 Get ready to dive into the world of Bingo, a super fun and exciting game. Here's a simple step-by-step guide to get you started:
              </p>
              <p>
                <strong>Step 1: Gather Your Materials</strong><br />
                Grab your Bingo cards and some markers. You can use coins, buttons, or even colorful candies - just something to cover the numbers on your card when they're called out.
              </p>
              <p>
                <strong>Step 2: Get Your Bingo Cards </strong><br />
                Each player gets a Bingo card with a grid of numbers. These numbers are all different and are randomly placed on the card. Every card is a winner in its own unique way!
              </p>
              <p>
                <strong>Step 3: Matilda’s Role</strong><br />
                Get Matilda to be the caller. Its job is to pick a number at random and announce it to everyone.
              </p>
              <p>
                <strong>Step 4: Mark Your Card</strong><br />
                Listen carefully to Matilda. If it announces a number that matches one on your card, cover it up with your marker. Remember, you're trying to get a line of covered numbers in a row, column, or diagonal.
              </p>
              <p>
                <strong>Step 5: Shout BINGO! </strong><br />
                Keep covering numbers on your card as Matilda announces them. Once you've covered a full row, column, or diagonal, you've got BINGO! Be quick to shout "BINGO!" so everyone knows you've won.
              </p>
              <p>
                <strong>Step 6: Check Your Card</strong><br />
                When you shout BINGO, Matilda will stop. Check your card to make sure you really got a winning line. If you did, you're the winner of that round!
              </p>
              <p>
                <strong>Step 7: Play Again</strong><br />
                Bingo is so much fun that you'll want to play again and again. Clear your card, gather your markers, and get ready for another round of excitement!
              </p>
              <p>
                Remember, Bingo is all about having a blast and enjoying the thrill of the game. So go on, grab your friends and family, and let the Bingo adventure begin! 🎉🎊🎈
              </p>
            </div>
          </DialogContent>
          <Divider></Divider>
          <DialogActions>
            <Button onClick={() => setShowHow(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <div className={classes.root}>
          {data.map((image) => (
            <ButtonBase
              onClick={() => imageClick(image.title)}
              focusRipple
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: image.width,
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {image.title}
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
      </>


    );
}
