import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Dialog, DialogActions, Checkbox, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography, Radio, RadioGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import translationService from "../../services/translation.service";

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
        title = translationService.translateString(title, language);
        console.log("TITLE ", title)
    }
    return title
}

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{computeTitle(column.render('Header'))}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function AssignRobot({ robots }) {
    return (
        <>
            {robots && robots.map((row, i) => {
                return (
                    <small>{row} <Button size="small" type="primary" variant="outlined" value={row} onClick={deleteRobot} >Remove</Button><br /></small>
                )
            })}
        </>
    )
}

function deleteRobot(e) {
    let userEmail = localStorage.getItem("selected");
    let robotIdentity = e.currentTarget.value;
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: 'https://cloud-api-389807.ue.r.appspot.com/' + '/user-robot?email=' + userEmail + '&robot_id=' + robotIdentity,
        headers: {}
    };

    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });

}

const UserManage = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [apiURL, setApiURL] = useState("https://cloud-api-389807.ue.r.appspot.com/")
    const [dialogMessage, setDialogMessage] = useState("");
    const [show, setShow] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userRobot, setUserRobot] = useState([]);
    const [robot, setRobot] = useState([]);
    const [content, setContent] = useState("");
    const [selected, setSelected] = useState("");
    const [userRole, setUserRole] = useState("ADMIN");
    const [userRoles, setUserRoles] = useState([{ 'label': 'Admin', 'value': 'ADMIN' }, { 'label': 'User', 'value': 'USER' }]);
    let history = useHistory();
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [newPass, setIsNewPass] = useState();
    const [isOpenService, setIsOpenService] = useState(false);

    // Effects
    useEffect(() => {
        if (localStorage.getItem("userRole") !== "ADMIN") {
            history.push("/home");
        }
        axios.get(apiURL + "user").then(result => {
            setSongs(transformData(result.data.data));
            setGotSongs(true);
        });
    }, []);

    const transformData = (obj) => {
        let finalObject = []
        for (let i = 0; i < obj.length; i++) {
            obj[i]["name"] = computeTitle(obj[i]["name"])
            obj[i]["role"] = computeTitle(obj[i]["role"])
            finalObject.push(obj[i])
        }
        return obj
    }

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',

            },
            {
                Header: 'Email',
                accessor: 'email',

            },
            {
                Header: 'Role',
                accessor: 'role',
            },
            {
                Header: 'Username',
                accessor: 'username',
            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="small" variant="outlined" value={cell.row.values.username} onClick={handleAssign}>Assign Robot</Button>
                        <Button size="small" variant="outlined" value={cell.row.values.username} onClick={handleAssignService}>Assign Service</Button>
                        <Button size="small" variant="outlined" value={cell.row.values.email} onClick={handleDelete}>Delete</Button>
                        <Button size="small" variant="outlined" value={cell.row.values.email} onClick={handleResetPassword}>Reset Password</Button>
                    </div>
                )

            },


        ],
        []
    )

    //  Functions
    const handleOpen = (e) => {
        if (e.currentTarget.value == "add") {
            setDialogMessage("Add User");
            passwordGenerator();
        } else {
            setDialogMessage("Update User");
        }
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleSave = () => {
        let data = JSON.stringify({
            "email": email,
            "password": password,
            "name": name,
            "role": userRole,
            "username": username
        });

        let config = {
            method: 'post',
            url: apiURL + 'user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setName("");
                setEmail("");
                setPassword("");
                axios.get(apiURL + "user").then(result => {
                    setSongs(result.data.data);
                    setGotSongs(true);
                    setShow(false);
                });
            });
    }

    const handleDelete = (e) => {
        let config = {
            method: 'delete',
            url: apiURL + 'user?email=' + e.currentTarget.value,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                axios.get(apiURL + "user").then(result => {
                    setSongs(result.data.data);
                    setGotSongs(true);
                });
            });

    }

    const handleResetPassword = (e) => {
        console.log("Handle Reset Password", e.currentTarget.value);
        setIsPasswordReset(true);
        setEmail(e.currentTarget.value);
    }

    const handleSavePassword = (e) => {
        // console.log(email, newPass);
        let data = JSON.stringify({
            "email": email,
            "password": newPass
        });

        let config = {
            method: 'put',
            url: 'https://cloud-api-389807.ue.r.appspot.com/user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setIsPasswordReset(false)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleAssign = (e) => {
        let user = e.currentTarget.value;
        localStorage.setItem("selected", user);
        setSelected(user);
        axios.get(apiURL + "user-robot?email=" + user).then(result => {
            if (result.data.data !== null) {
                setUserRobot(result.data.data.robots);
            } else {
                setUserRobot([]);
            }

        }).then(() => {
            axios.get(apiURL + "robot").then(result => {
                setRobot(result.data.data);
            }).then(() => {
                setShowAssign(true);
            });
        });
    }

    const handleAssignService = (e) => {
        setEmail(e.currentTarget.value);
        let user = e.currentTarget.value;
        localStorage.setItem("selected", user);
        handleGetServiceAccess();
        setIsOpenService(true);
    }

    const passwordGenerator = (e) => {
        var chars = "0123456789!@#$%^&*()-<>?";
        var passwordLength = 8;
        var passwordTemp = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            passwordTemp += chars.substring(randomNumber, randomNumber + 1);
        }
        setPassword(passwordTemp);
    }

    const handleCloseAssign = (e) => {
        setShowAssign(false);
    }

    const handleContentChange = (e) => {
        setContent(e.target.value);
        localStorage.setItem("robot", e.target.value);
    }

    const handleUserRoleChange = (e) => {
        setUserRole(e.target.value);
    }

    const addRobot = (e) => {
        let current = localStorage.getItem("robot")
        let data = JSON.stringify({
            "robot_id": current,
            "email": selected
        });

        let config = {
            method: 'post',
            url: apiURL + 'user-robot',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const deleteRobot = (e) => {
        console.log("Delete Robot ", e.currentTarget.value);
    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = translationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    const serviceLabels = ["Analytics", "Books", "Calendar based Service Scheduling", "Cognitive and Emotion Assessment", "Edit Quiz", "Emotion Recognition", "Exercise", "Eye Gaze", "Face Detection", "Family Photos & Videos", "Fitbit", "Games", "Heart Rate", "Interview", "Learning", "Matilda", "Messenger", "Monitoring", "Music", "News", "Phone Call", "Quiz", "Quiz Upload", "Robot Blocks", "Smart Home", "System Settings", "Talk With Matilda", "Technology Support", "Translation", "Upload", "Video Call", "Videos & Movies", "Voice Command", "WiFi Setting", "Workflow Designer", "Manage Residents"];
    const [allowAccess, setAllowAccess] = useState([]);

    const handleCheckboxChange = (label) => {
        const updatedAllowAccess = [...allowAccess];

        if (allowAccess.includes(label)) {
            // If the checkbox is checked, remove label from allowAccess
            const index = updatedAllowAccess.indexOf(label);
            if (index !== -1) {
                updatedAllowAccess.splice(index, 1);
            }
        } else {
            // If the checkbox is unchecked, add label to allowAccess
            updatedAllowAccess.push(label);
        }

        setAllowAccess(updatedAllowAccess);
    }

    const handleSaveServiceAccess = () => {
        console.log("Saving Access ", allowAccess);
        const axios = require('axios');
        let data = JSON.stringify({
            "robot": localStorage.getItem('remoteId'),
            "username": localStorage.getItem('selected'),
            "access": allowAccess
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setIsOpenService(false);
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const handleGetServiceAccess = () => {
        console.log("USER EMAIL ", localStorage.getItem("selected"))
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + localStorage.getItem('remoteId') + '&username=' + localStorage.getItem("selected"),
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("SERVOCE ACCESS DATA", response.data.data.access);
                setAllowAccess(response.data.data.access)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // Build Page
    return (
        <Styles>
            <TitleBar title={computeTitle("Manage User")} />
            <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>
                {computeTitle("Add User")}
            </Button>
            <Table columns={columns} data={songs} />
            <Dialog
                open={isOpenService}
                onClose={() => setIsOpenService(false)}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Assign Service</DialogTitle>
                <DialogContent>
                    Assign service for <strong>{email}</strong> <br /><br />
                    <small>Check those services you want to assign</small> <br />
                    {serviceLabels.map((label) => (
                        <>
                            <FormControlLabel
                                key={label}
                                control={
                                    <Checkbox
                                        checked={allowAccess.includes(label)}
                                        onChange={() => handleCheckboxChange(label)}
                                        color="primary"
                                    />
                                }
                                label={label}
                            />
                            <br />
                        </>
                    ))}
                    <br /><b />
                    <Button onClick={handleSaveServiceAccess}>Save</Button>
                    <Button onClick={() => setIsOpenService(false)}>Cancel</Button>
                </DialogContent>
            </Dialog>
            <Dialog
                open={isPasswordReset}
                onClose={() => setIsPasswordReset(false)}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Reset User Password</DialogTitle>
                <DialogContent>
                    Reset password for <strong>{email}</strong> <br />
                    <TextField
                        label="New Password"
                        defaultValue={newPass}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setIsNewPass(e.target.value); }}
                        value={newPass}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPasswordReset(false)}>Cancel</Button>
                    <Button onClick={handleSavePassword}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={show}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>{dialogMessage}</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Name"
                        defaultValue={name}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setName(e.target.value); }}
                        value={name}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Email"
                        defaultValue={email}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setEmail(e.target.value); }}
                        value={email}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Username"
                        defaultValue={username}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setUsername(e.target.value); }}
                        value={username}
                        fullWidth
                    />
                    <br />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="content-label">Role</InputLabel>
                        <Select value={userRole} onClick={handleUserRoleChange} fullWidth>
                            {userRoles.map((categoria, i) => (
                                <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <small>Password will be {password} (copy this to send to client)</small>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showAssign}
                onClose={handleCloseAssign}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleCloseAssign}>Assign Robot to User</DialogTitle>
                <DialogContent dividers>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="content-label">Content</InputLabel>
                        <Select value={content} onClick={handleContentChange} fullWidth>
                            {robot.map((categoria, i) => (
                                <MenuItem key={i} value={categoria.robot_id}>{categoria.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button size="small" type="primary" variant="outlined" value={content} onClick={addRobot}>Add Robot</Button>
                    <br />
                    <br />
                    <b>Robots</b>
                    <br />
                    <AssignRobot robots={userRobot} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAssign} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Styles>
    )
}

export default UserManage;