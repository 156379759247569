import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";
import { Middleware } from "./Middleware";


const styles = (theme) => ({});

class EmotionalWellBeing extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M14 14V6M14 14L20.1023 17.487C20.5023 17.7156 21 17.4268 21 16.9661V3.03391C21 2.57321 20.5023 2.28439 20.1023 2.51296L14 6M14 14H7C4.79086 14 3 12.2091 3 10V10C3 7.79086 4.79086 6 7 6H14" stroke="currentColor" stroke-width="1.5"/>
                        <path d="M7.75716 19.3001L7 14H11L11.6772 18.7401C11.8476 19.9329 10.922 21 9.71716 21C8.73186 21 7.8965 20.2755 7.75716 19.3001Z" stroke="currentColor" stroke-width="1.5"/>
                    `,
                    background: 'linear-gradient(0deg, #1b6c72, #2498a1)',
                    title: 'Entertainment',
                    slug: '/entertainment',
                    locations: ['home'],
                },
                {
                    svg: `
                        <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 18.01L12.01 17.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #741d6d, #aa299f)',
                    title: 'Brain Training',
                    slug: '/brain-training',
                    locations: ['games'],
                },
                // {
                //     svg: `
                //         <path d="M15 4V2M15 4V6M15 4H10.5M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10H3Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                //         <path d="M3 10V6C3 4.89543 3.89543 4 5 4H7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                //         <path d="M7 2V6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                //         <path d="M21 10V6C21 4.89543 20.1046 4 19 4H18.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>    
                //         `,
                //     background: 'linear-gradient(0deg, #0f6f11, #0fa112)',
                //     title: 'Calendar based Service Scheduling',
                //     slug: '/calendar',
                //     locations: ['home'],
                // },
            ],
            selected: false,
            quizId: null,
            allowedAcccess: []
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
        this.computeScreenSize = this.computeScreenSize.bind(this);
    }

    componentDidMount() { 
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        Middleware()

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access});
            })
            .catch((error) => {
                console.log(error);
            });
    }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Emotional Well Being")} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            <span style={{
                                background: app.background,
                                transition: '0.5s ease',
                                display: 'flex',
                                borderRadius: '50%',
                                height: '100px',
                                width: '100px',
                                backgroundSize: 'cover',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <svg style={{
                                    width: '65px',
                                    height: '65px',
                                    color: '#fff',
                                }}
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{ __html: app.svg }} />
                            </span>
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(EmotionalWellBeing);