import React, { useEffect } from 'react'
import useStream from '../../hooks/useStream';
import Call  from '../analytics/Call';
import "./remoteStream.css"

export default function RemoteStream({ remoteStream,muteOrNot }) {

  const [setStream, remoteVideoRef, handleCanPlayRemote] = useStream();

  useEffect(() => {
    if (remoteStream)
      setStream(remoteStream);
  }, [setStream, remoteStream])

  return (
    <div className="remote">
        <video onContextMenu={event => event.preventDefault()} ref={remoteVideoRef} onCanPlay={handleCanPlayRemote} autoPlay playsInline muted={muteOrNot}  />
    </div>
  )
}