import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import AuthService from "../../services/auth.service";
import SelectedList from "./SelectedList";
import Grid from '@material-ui/core/Grid';
import ImageUploadCard from "./ImageUploadCard";

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import PromptList from "./PromptList";
import TitleBar from '../Common/TitleBar';
import { sendMessage, socket, name, waitForSocketConnection } from '../../socket';
import AudioRecorder from "./AudioRecorder";
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },


  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0),
      width: '100%',
    },
  },

}));

const Messenger = () => {
  const [spacing, setSpacing] = React.useState(50);
  const [prompts, setPrompts] = React.useState([]);
  const [prompt, setPrompt] = React.useState("type your message here or select from below list");

  const [times, setTimes] = React.useState('2');
  const [interval, setInterval] = React.useState('15');
  const classes = useStyles();
  let history = useHistory();
  const [isAudio, setIsAudio] = React.useState(false);

  const handleToggle = (e) => {
    // setIsAudio(true);
    history.push("/messenger-audio")
  }

  const handleToggleText = (e) => {
    // setIsAudio(false);
    history.push("/messenger")
  }

  useEffect(() => {

    let name = localStorage.getItem('remoteId');
    if (!name) {
      history.push('/login')
    }

    var request = `{"action":"getPrompt","name":"${name}"}`;

    waitForSocketConnection(socket, () => sendMessage(request))



    socket.onmessage = (message) => {
      console.log(message.data.toString())
      if ((message.data) && (message.data.toString().includes("prompts"))) {
        setPrompts(JSON.parse(message.data).prompts);
      }
    }
  }, []);

  socket.onmessage = (message) => {
    console.log(message.data.toString())
    if ((message.data) && (message.data.toString().includes("prompts"))) {
      setPrompts(JSON.parse(message.data).prompts);
    }
  }

  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleTimesChange = (event) => {
    setTimes(event.target.value);
  };

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleSelect = (text) => {
    setPrompt(text);
  }

  const handleClick = () => {
    let name = localStorage.getItem('remoteId');
    var api = `{"action":"tellPrompt","prompt":"${prompt}" , "time":${times}  , "interval":${interval} , "name":"${name}" }`;
    waitForSocketConnection(socket, () => sendMessage(api))
  };
  return (

    <div className="">
      <TitleBar title="Messenger" parentNode={"/friends-and-relatives"} />
      <div className={classes.root}>
        {isAudio ?
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container justifyContent="center" spacing={spacing}>
              <Grid item xs={6} className="">
                <div className="card">
                  <div><p>Record your voice message</p></div>
                  <AudioRecorder />
                </div>
              </Grid>
            </Grid>
          </form>
          :
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container className={classes.root} spacing={2}>

              <Grid item xs={12} sm={6} lg={6}>

                <Grid key={0}>
                  <div className="message-box">
                    <div className="card">
                      <div>
                        <KeyboardVoiceIcon onClick={handleToggle} />
                        <BorderColorRoundedIcon onClick={handleToggleText} />
                        <p>Type your message in the box or select one from the list</p>
                      </div>
                    </div>

                    <div className="card">
                      <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        multiline
                        rows={4}
                        value={prompt}
                        // defaultValue={prompt}
                        variant="outlined"
                        onChange={handleChange}
                      />

                    </div>
                    <div className="card">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Times</InputLabel>
                        <Select
                          native
                          value={times}
                          onChange={handleTimesChange}
                          label="Time"
                          inputProps={{
                            name: 'time',
                            id: 'times',
                          }}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="card">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-interval-native-simple">Interval</InputLabel>
                        <Select
                          native
                          value={interval}
                          onChange={handleIntervalChange}
                          label="Interval"
                          inputProps={{
                            name: 'interval',
                            id: 'interval',
                          }}
                        >
                          <option value={15}>15 seconds</option>
                          <option value={30}>30 seconds</option>
                          <option value={45}>45 seconds</option>
                          <option value={60}>60 seconds</option>
                          <option value={75}>75 seconds</option>
                          <option value={90}>90 seconds</option>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<SendIcon />}
                        onClick={handleClick}
                      >
                        Send
                      </Button>
                    </div>
                  </div>

                </Grid>

              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <PromptList prompts={prompts} selectCallback={handleSelect} />
              </Grid>

            </Grid>
          </form>
        }
      </div>
    </div>


  );
}

export default Messenger;