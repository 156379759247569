import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://192.168.163.30:8082/api/";
//const API_URL = "http://34.92.77.213:8082/api/test/";
const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};


const getRobotStatus = () => {
  //return axios.get("http://210.3.12.74:8082/loglist");
  return axios.get(API_URL + "robots");
};


const getCalendars = (ownerId) => {
  let headers = new Headers();

   
    headers.append('Accept', 'application/json');
   
  return axios
    .get(API_URL + "calendars", {
      params: {'ownerId':ownerId}
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const getCalendar = (calId) => {
  let headers = new Headers();

    
    headers.append('Accept', 'application/json');
 
  return axios
    .get(API_URL + "calendar", {
      params: {'calId':calId}
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const getMyCalendar = () => {
  
  return axios
    .get(API_URL + "mycalendar")
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const updateMyCalendar = (events) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post(API_URL + "mycalendar", {      
      events
    },
    {
      headers: headers
    })
    .then((response) => {
      

      return response.data;
    });
};

const createCalendar = (ownerId, title) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post(API_URL + "calendar", {      
      ownerId,
      title
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const addCalEvent = (calId,title, start, end, desc,repeating, service, content) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post(API_URL + "calendar/event", {
      calId,      
      title,
      start,
      end,
      desc,
      repeating,
      service,
      content
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const postlog = (the_user, activity, desc) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');    
  headers.append('Origin','http://192.168.163.30:8082');
    var currentdate = new Date(); 
    var thedate =  formatDate(currentdate);
    var thetime = currentdate.getHours()+":"+currentdate.getMinutes()+":"+currentdate.getSeconds();
    console.log(thetime + "@"+thedate)
  return axios
    .post("http://192.168.163.30:3000/log", {
        UserID:the_user,
        Activity:activity,
        Date:thedate,
        Time:thetime,
        VideoFile:"None",
        CommunicationMode:"WebApp",
        Description:desc
    },
    {
      headers: headers
    })
    .then((response) => {
     

      return response.data;
    });
};

const updateCalEvent = (calId,eventId,title, start, end, desc,repeating, service, content) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .put(API_URL + "calendar/event", {
      calId,    
      eventId,  
      title,
      start,
      end,
      desc,
      repeating,
      service,
      content
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};


const deleteCalEvent = (calId, eventId) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .delete(API_URL + "calendar/event", { data: { calId: calId, eventId,eventId }},
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

// Joanna Update
const REMOTE_API = "http://127.0.0.1:5000/"
const createAppointment = (event) => {
  var data = JSON.stringify(event);
  return axios
    .post(REMOTE_API + "calendar", data)
    .then((response) => {
      return response.data;
  });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,

  getRobotStatus,
  getCalendar,
  getMyCalendar,
  updateMyCalendar,
  getCalendars,
  addCalEvent,
  updateCalEvent,
  deleteCalEvent,
  postlog,
  createAppointment
};
