import { useEffect } from "react";
import { musicList, name, sendMessage, socket, waitForSocketConnection } from "../../socket";

export const Middleware = () => {
    const remoteId = localStorage.getItem("remoteId");
    console.log('Joanna middle ', remoteId)
    if (!remoteId) {
        console.log("Joanna Middleware..", remoteId);
        const homeUrl = window.location.origin + '/home';

        if (window.location.href !== homeUrl) {
            window.location = homeUrl;
        } else {
            console.log('Already on the home page, no redirection needed.');
        }
    }
    // var api = `{"api":"music", "action":"get","name": "${remoteId}"}`;
    // waitForSocketConnection(socket, () => { sendMessage(api) }, 0);

    // setTimeout(() => {
    //     console.log('Joanna middle timeout ', remoteId, musicList)
    //     if (!musicList) {
    //         localStorage.removeItem('remoteId');
    //         const remoteId = localStorage.getItem("remoteId");
    //         console.log('Joanna middle timeout ', remoteId)
    //         window.location.reload();
    //     }
    // }, 5000); // 15 seconds delay

}

export const MiddlewareStorage = () => {
    const remoteId = localStorage.getItem("remoteId");
    console.log('Joanna middle ', remoteId)
    if (!remoteId) {
        console.log("Joanna Middleware..", remoteId);
        const homeUrl = window.location.origin + '/home';

        if (window.location.href !== homeUrl) {
            window.location = homeUrl;
        } else {
            console.log('Already on the home page, no redirection needed.');
        }
    }
}