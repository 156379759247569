import React, { useState, useRef, useEffect } from "react";
import './CallStyle.css'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Matilda1 from '../Illustrations/Matilda1';
import useWebSocket from '../../hooks/useWebSocket';
import useRemoteStream from '../../hooks/useRemoteStream';
import usePeerV2 from '../../hooks/usePeerV2';
import useUserMediaV2 from '../../hooks/useUserMediaV2';
import TitleBar from '../Common/TitleBar';
import RemoteStream from '../Call/RemoteStream';
import LocalStream from '../Call/LocalStream';
import GetUserMediaMock from '@theopenweb/get-user-media-mock'
import { sendMessage, socket, waitForSocketConnection, name} from '../../socket';
import { Button } from "reactstrap";
import { Box } from "@material-ui/core";
import CryptoJS from 'crypto-js';

const MonitoringV2 = () => {
    let pageLogin=name;
    if (name === undefined || name === null || name=== "undefined") {
        pageLogin = window.location.href.split("?")[1]
    }
    console.log("id ", window.location.href.split("?"))
    const createMediaStreamFake = () => {
        return new MediaStream([createEmptyAudioTrack(), createEmptyVideoTrack({ width: 320, height: 240 })]);
    }

    const createEmptyAudioTrack = () => {
        const ctx = new AudioContext();
        const oscillator = ctx.createOscillator();
        const dst = oscillator.connect(ctx.createMediaStreamDestination());
        oscillator.start();
        oscillator.mute = true;
        const track = dst.stream.getAudioTracks()[0];
        return Object.assign(track, { enabled: false });
    }

    const createEmptyVideoTrack = ({ width, height }) => {
        const canvas = Object.assign(document.createElement('canvas'), { width, height });
        canvas.getContext('2d').fillRect(0, 0, width, height);

        const stream = canvas.captureStream();
        const track = stream.getVideoTracks()[0];

        return Object.assign(track, { enabled: false });
    };

    const localstream = createMediaStreamFake()
    const [remoteStream, addRemoteStream, removeRemoteStream] = useRemoteStream();
    const [peer, peerId, peerConnected] = usePeerV2(addRemoteStream, removeRemoteStream);
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [remoteId, setRemoteId] = useState(pageLogin);
    const [headPosition, setHeadPosition] = useState({ horizontal: 0, vertical: 15 });
    const [call, setCall] = useState(null);
    const [conn, setConn] = useState(null); 
    const [mute, setMute] = useState(true);
    const [muteOrNot, setmuteOrNot] = useState(true);
    const [doneset, setdoneset] = useState(true);
    const [remID, setRemID] = useState();

    useEffect(() => {
        const currentUrl = window.location.href;
        let remoteId = "Matilda_872d-g"
        console.log("Mounting ...", currentUrl);
        if (currentUrl.includes("remoteId")) {
            remoteId = currentUrl.split("?")[1].split("=")[1].trim()
            console.log("Mounted ", remoteId)
            localStorage.setItem("remoteId", remoteId)
            setRemID(remoteId);
            setRemoteId(remoteId);
        }
    }, [])

    useEffect(() => {
        console.log("LOCATION ", pageLogin)
        if (peer != null && doneset == true) {
            // if(doneset == true) {
                setdoneset(false)
                callUser()
            // }
        }
    }, [peerConnected])
    
    const updateRemoteId = (e) => { 
        setRemoteId(e.target.value);
    }
    //autoGainControl: false,
    //channelCount: 2,
    //echoCancellation: true,
    //latency: 0,
    //noiseSuppression: true,
    //sampleRate: 48000,
    //sampleSize: 16,
    //volume: 1.0

    // Send message via Web RTC connection
    const sendMessage2 = async (message) => {
        console.log('Sending message: ', message);
        if (conn === null) { 
            return;
        }
        conn.send(JSON.stringify(message));
    }

    const openConnection = async () => {

        // This opens the data channel
        // It's not needed for the call, but is needed for sending messages 
        // (eg, controlling the robot's head movement)
        const thisConnection = peer.connect(remoteId);

        setConn(thisConnection);

        // Test a data connection
        // Test a data connection
        thisConnection.on('open', () => {
            console.log('Connection Opened');
            const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let randomString = "";
            
            for (let i = 0; i < 8; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              randomString += charset.charAt(randomIndex);
            }

            console.log("RANDOM STRING ", randomString);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }), randomString).toString();
            console.log("encryptedData ", encryptedData.concat("+++").concat(randomString));
            thisConnection.send(encryptedData.concat("+++").concat(randomString));
            // thisConnection.send(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }));
        });

        thisConnection.on('error', (error) => {
            console.log('Connection error', error)
        });

        thisConnection.on('data', (data) => {
            const parsedMessage = JSON.parse(data);
            if (parsedMessage) { 
                if (parsedMessage.type === 'photo') { 
                    downloadImage(parsedMessage.data, 'image.jpg');
                }
                else if(parsedMessage.includes('error')){
                    console.log(parsedMessage)
                    console.log(parsedMessage.error)
                }
            }
            
        });
    }

    function toggleMute() {
        const newMuteState = !mute;
        localstream.getAudioTracks().forEach(track => track.enabled = !newMuteState);
        setMute(newMuteState);
        setmuteOrNot(newMuteState)
    }


    const callUser = async () => {
        // let name = localStorage.getItem('remoteId');
        var api = `{"api":"echoCancel", "action":"get","name": "${name}"}`;
        waitForSocketConnection( socket , () => { sendMessage(api)  } )

        setIsConnecting(true);
        
        openConnection();
        
        const options = {
            constraints: {
                'mandatory': {
                    'OfferToReceiveAudio': false,
                    'OfferToReceiveVideo': true
                },
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1,
            }
        }
        const thisCall = peer.call(remoteId, localstream, options);

        setCall(thisCall);

        thisCall.on('connect', () => {
            console.log("NEW CONNECTED DEVICE")
        })
        
        thisCall.on('stream', (stream) => {
            
            addRemoteStream(stream);
            setIsConnecting(false);
            setIsConnected(true);
        });
    
        thisCall.on('close', () => {
            console.log("call closed");
            removeRemoteStream();
            setIsConnecting(false);
            setIsConnected(false);
            if (thisCall !== null) { 
                thisCall.close();
            }
        });
    
        thisCall.on('error', (error) => {
            console.log("call error", error);
            removeRemoteStream();
            setIsConnecting(false);
            setIsConnected(false);
            if (thisCall !== null) { 
                thisCall.close();
            }
        });
    }

    function endCall() {
        if (call === null) { 
            return;
        }
        removeRemoteStream(call.peer);
        call.close();
        sendMessage2('Close Call');
        setCall(null);
    }
    
    const moveHead = (newHeadPosition) => {
        const message = Object.assign(newHeadPosition, { api: "head" });

        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";
        
        for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          randomString += charset.charAt(randomIndex);
        }

        console.log("RANDOM STRING ", randomString);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(message), randomString).toString();
        conn.send(encryptedData.concat("+++").concat(randomString));
        setHeadPosition(newHeadPosition);
    }

    //The below functions are for controlling the Robot Movements 
    const moveHeadLeft = () => { 
        if (headPosition.horizontal < 80) { 
            const newHeadPosition = { horizontal: headPosition.horizontal + 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadRight = () => { 
        if (headPosition.horizontal > -80) { 
            const newHeadPosition = { horizontal: headPosition.horizontal - 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadUp = () => { 
        console.log(headPosition);
        if(headPosition.vertical < 50){ 
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical + 10 };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadDown = () => { 
        if (headPosition.vertical > -50) { 
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical - 10 };
            moveHead(newHeadPosition);
        }
    }

    const resetHead = () => { 
        const newHeadPosition = { horizontal: 0, vertical: 15 };
        moveHead(newHeadPosition);
    }
 
    function takePhoto(){
        sendMessage2({ "api": "take_photo", "send_to": "192.168.163.30:8000/photo" });
    }

    const downloadImage = (base64Data, fileName) => { 
        // Creates a DOM element with the "download" attribute to force 
        // the image to download rather than replace the location.
        const linkSource = `data:image/jpg;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const handleAccept = () => {
        // const phrase = "You are allowed to come in but please fill up the registration form first. Before that, i need your phone number";
        // var speak = { "api": "vocalise", "text": phrase, "language": "en", "emotion": "neutral", "sound": "none", "name": remID };
        // console.log("SPEAK ", speak)
        // waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });

        var accept = { "api": "VCGRT", "language": "en", "name": remID}
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(accept)) });
    }

    const handleReject = () => {
        // const phrase = "Sorry! The resident cant meet you today";
        const phrase = "The staff member is unavailable to meet you today. Please come another day to meet the staff member."
        var speak = { "api": "vocalise", "text": phrase, "language": "en", "emotion": "neutral", "sound": "none", "name": remID };
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });  
    }

    const handleVideoCall = () => {
        window.location.href = '/call';
    }

    return (

        <>
        <TitleBar title={"Receptionist"} />
        <Box>
            <Button onClick={handleAccept}>Accept</Button> &nbsp;
            <Button onClick={handleReject}>Don't Accept</Button> &nbsp;
            <Button onClick={handleVideoCall}>Start Video Call</Button>
        </Box>
        <br />
        <div className="">
            {/* <div id="call-controls" className={isConnected ? "" : "hidden"}>
                <button onClick={endCall}>Stop</button>
            </div> */}
            <div id="call-box" className={isConnected ? "hidden" : ""}>
                <div >
                    <Matilda1 />
                </div>
                <div>
                    <div>
                        <div id="connection-status" className={isConnecting ? "" : "hidden"}>
                            {isConnecting  ? 'Connecting ...' : ''}
                        </div>
                        
                        <div className={isConnected ? "hidden" : "call-form, content-area"}>
                            <h4>Matilda ID to View</h4>
                            <input type="text" className="form-control form-rounded" value={remoteId} onChange={updateRemoteId} />
                            <span onClick={callUser} id="connect-btn" className="btn btn-large">
                                <img src="/images/loading.svg" alt="Loading" className={isConnecting ? "loading" : "hidden"} />
                                { isConnecting ? 'Connecting' : 'Connect'}
                            </span>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className="row">            
                <div className='col-12'>

                    <div className={isConnected ? "" : "hidden"} id="video-container">
                        <div id="controls-container">
                            <div id='menu'>
                                <div className="keys">
                                    <button className="centre arr" onClick={takePhoto}><i className="fa fa-camera"></i></button>
                                    <br />
                                    <button className="up arr" onClick={moveHeadUp}><i className="fa fa-arrow-up"></i></button>
                                    <br />
                                    <button className="left arr" onClick={moveHeadLeft}><i className="fa fa-arrow-left "></i></button>
                                    <button className="centre arr" onClick={resetHead}><i className="fa fa-dot-circle-o"></i></button>
                                    <button className="right arr" onClick={moveHeadRight}><i className="fa fa-arrow-right"></i></button>
                                    <br />
                                    <button className="down arr" onClick={moveHeadDown}><i className="fa fa-arrow-down"></i></button>
                                </div>
                            </div>
                        </div>
                        <div id="remote-video">
                            <RemoteStream remoteStream={remoteStream} muteOrNot ={muteOrNot} />
                            {/* <LocalStream userMedia={localstream}/> */}
                        </div>
                    </div>
                </div>
            </div>

        </div><link href="//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css" rel="stylesheet"></link></>
    )
    
}

export default MonitoringV2;
// import React, { useState, useRef, useEffect } from "react";
// import './CallStyle.css'

// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

// import Matilda1 from '../Illustrations/Matilda1';
// import useWebSocket from '../../hooks/useWebSocket';
// // import useRemoteStream from '../../hooks/useRemoteStream';
// import usePeerV2 from '../../hooks/usePeerV2';
// import useUserMediaV2 from '../../hooks/useUserMediaV2';
// import TitleBar from '../Common/TitleBar';
// import LocalStream from '../Call/LocalStream';
// import { sendMessage, socket, waitForSocketConnection, name} from '../../socket';

// const Monitoring = () => {
//     const videoRef = useRef(null);
  
//     useEffect(() => {
//       getVideo();
//     }, [videoRef]);
  
//     const getVideo = () => {
//       navigator.mediaDevices
//         .getUserMedia({ video: { width: 300 } })
//         .then(stream => {
//           let video = videoRef.current;
//           video.srcObject = stream;
//           video.play();
//         })
//         .catch(err => {
//           console.error("error:", err);
//         });
//     };
  
//     return (
//       <div>
//         <TitleBar title="Call" />
//         <video ref={videoRef} width="100%" height="240" muted/>
//       </div>
//     );
// }

// export default Monitoring;