import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { useHistory } from 'react-router-dom';
import TitleBar from '../Common/TitleBar';
import { AppBar, Box, Button, ButtonBase, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, Input, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@material-ui/core';
import Iframe from "react-iframe";
import './Settings.css';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    alignItems: 'center',    
  },
  image:{
    marginBottom: '20px',
    marginRight: '20px',
    width: '150px',
  },
  img:{
    width: '100px',
  },
  imageList: {
    width: 500,
    height: 500,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

 
export default function Settings() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const [openAddAccount, setOpenAddAccount] = useState(false);
  const [openWifi, setOpenWifi] = useState(false);
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSpeechSensitivity, setOpenSpeechSensitivity] = useState(false);
  const [openDialogueDatabase, setOpenDialogueDatabase] = useState(false);
  const [openSynchDialogue, setOpenSynchDialogue] = useState(false);
  const [openSyncWebApp, setOpenSyncWebApp] = useState(false);
  const [openSmartHome, setOpenSmartHome] = useState(false);
  const [openControl, setOpenControl] = useState(false);

  const handleClickAccountOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [sensitivity, setsensitivity] = React.useState('');

  const handleChange = (event) => {
    setsensitivity(event.target.value);
  };
  const imageClick = (title) => {
    console.log('Click');
    if (title == 'tictactoe')
    {
        window.location.href='/tictactoe'
    }
    else if (title == 'memorygame')
    {
        window.location.href='/memorygame'
    }
    else if (title == 'maths')
    {
        window.location.href='/maths'
    }

    else if (title == 'chess')
    {
        window.location.href='/chess'
    }
   
  } 
  const Top = (props) => {
    return (
      <React.Fragment>
        <div>
           
          <div>
            <ButtonBase focusRipple className={classes.image} onClick={props.onClickAddAccount}>
              <span className="grids bg-red">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M8.602 3.7c-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 3.321 0 5.97 2.117 5.97 6.167 0 3.555-1.949 6.833-2.383 7.833h-2.115c.392-1.536 2.499-4.366 2.499-7.842 0-5.153-5.867-4.985-7.369-2.458zm15.398 15.8c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2-.5h-2v-2h-1v2h-2v1h2v2h1v-2h2v-1z"/>
                </svg>
              </span>
              <ImageListItemBar title="Add Account" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickWifi}>
              <span className="grids bg-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M8.213 16.984c.97-1.028 2.308-1.664 3.787-1.664s2.817.636 3.787 1.664l-3.787 4.016-3.787-4.016zm-1.747-1.854c1.417-1.502 3.373-2.431 5.534-2.431s4.118.929 5.534 2.431l2.33-2.472c-2.012-2.134-4.793-3.454-7.864-3.454s-5.852 1.32-7.864 3.455l2.33 2.471zm5.534-11.13c3.949 0 7.681 1.517 10.607 4.291l-1.021 1.083c-2.656-2.452-6.023-3.791-9.586-3.791s-6.93 1.339-9.586 3.791l-1.021-1.083c2.926-2.774 6.658-4.291 10.607-4.291zm0-1c-4.687 0-8.929 2.015-12 5.272l2.388 2.533c2.46-2.609 5.859-4.222 9.612-4.222s7.152 1.613 9.612 4.222l2.388-2.533c-3.071-3.257-7.313-5.272-12-5.272z"/>
                </svg>
              </span>
              <ImageListItemBar title="WiFi Setup" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickAnalytics}>
              <span className="grids bg-purple">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M7 24h-6v-6h6v6zm8-9h-6v9h6v-9zm8-4h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z"/>
                </svg>
              </span>
              <ImageListItemBar title="Analytics" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickSettings}>
              <span className="grids bg-dark-red">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm5 3c0-2.761-2.238-5-5-5h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14zm-13 12h-2v3h-2v-3h-2v-3h6v3zm-2-13h-2v8h2v-8zm10 5h-6v3h2v8h2v-8h2v-3zm-2-5h-2v3h2v-3z"/>
                </svg>
              </span>
              <ImageListItemBar title="Settings" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickSpeechSensitivity}>
              <span className="grids bg-blue">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M17.454 12.537c2.782 0 5.046 1.779 5.046 3.967 0 1.12-.462 1.745-1.102 2.509-.021.746-.049 1.054.139 1.866-.891-.306-.986-.396-1.666-.813-.894.218-1.489.38-2.465.38-3.087 0-4.998-2.046-4.998-3.942 0-2.188 2.264-3.967 5.046-3.967zm0-1.5c-3.436 0-6.546 2.292-6.546 5.467 0 2.799 2.633 5.442 6.498 5.442.699 0 1.44-.087 2.213-.275.914.561 2.933 1.128 4.352 1.385-.53-1.044-1.117-2.479-1.088-3.479.714-.853 1.117-1.953 1.117-3.073 0-3.158-3.089-5.467-6.546-5.467zm-8.485 4.614c-1.138-.11-1.611-.247-2.611-.491-.97.596-1.26.815-3.008 1.374.418-1.514.364-2.183.333-3.183-.834-1-1.683-2.07-1.683-3.943 0-3.502 3.589-6.352 8-6.352 4.264 0 7.748 2.664 7.978 6.004.698.038 1.377.14 2.021.315-.022-4.834-4.762-8.319-9.999-8.319-5.281 0-10 3.527-10 8.352 0 1.71.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.313 2.168-.391 5.252-1.258 6.649-2.115.802.196 1.578.314 2.33.374-.135-.749-.148-1.317-.054-2.024z"/>
                </svg>
              </span>
              <ImageListItemBar title="Speech Sensitivity" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickDialogueDatabase}>
              <span className="grids bg-green">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M18 15.422v.983c0 .771-1.862 1.396-4 1.396s-4-.625-4-1.396v-.983c.968.695 2.801.902 4 .902 1.202 0 3.035-.208 4-.902zm-4-1.363c-1.202 0-3.035-.209-4-.902v.973c0 .771 1.862 1.396 4 1.396s4-.625 4-1.396v-.973c-.968.695-2.801.902-4 .902zm0-5.86c-2.138 0-4 .625-4 1.396 0 .77 1.862 1.395 4 1.395s4-.625 4-1.395c0-.771-1.862-1.396-4-1.396zm0 3.591c-1.202 0-3.035-.209-4-.902v.977c0 .77 1.862 1.395 4 1.395s4-.625 4-1.395v-.977c-.968.695-2.801.902-4 .902zm-.5-9.79c-5.288 0-9.649 3.914-10.377 9h-3.123l4 5.917 4-5.917h-2.847c.711-3.972 4.174-7 8.347-7 4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5c-3.015 0-5.662-1.583-7.171-3.957l-1.2 1.775c1.916 2.536 4.948 4.182 8.371 4.182 5.797 0 10.5-4.702 10.5-10.5s-4.703-10.5-10.5-10.5z"/>
                </svg>
              </span>
              <ImageListItemBar title="Dialogue Database" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickSynchDialogue}>
              <span className="grids bg-dark-green">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M12 1c6.074 0 11 4.925 11 11 0 1.042-.154 2.045-.425 3h-2.101c.335-.94.526-1.947.526-3 0-4.962-4.037-9-9-9-1.706 0-3.296.484-4.654 1.314l1.857 2.686h-6.994l2.152-7 1.85 2.673c1.683-1.049 3.658-1.673 5.789-1.673zm4.646 18.692c-1.357.826-2.944 1.308-4.646 1.308-4.963 0-9-4.038-9-9 0-1.053.191-2.06.525-3h-2.1c-.271.955-.425 1.958-.425 3 0 6.075 4.925 11 11 11 2.127 0 4.099-.621 5.78-1.667l1.853 2.667 2.152-6.989h-6.994l1.855 2.681zm-2.646-8.692v-2c0-1.104-.896-2-2-2s-2 .896-2 2v2h-1v5h6v-5h-1zm-3-2c0-.551.448-1 1-1s1 .449 1 1v2h-2v-2z"/>
                </svg>
              </span>
              <ImageListItemBar title="Synchronise Dialogue" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickSyncWebApp}>
              <span className="grids bg-light-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M15.408 23h-15.408v-22h22v9.683c-.622-.296-1.293-.502-2-.603v-3.08h-18v14h11.26c.566.81 1.3 1.49 2.148 2zm2.257-8.669c.402-.206.852-.331 1.335-.331 1.455 0 2.67 1.042 2.941 2.418l1.96-.398c-.456-2.291-2.475-4.02-4.901-4.02-.957 0-1.845.278-2.604.745l-1.396-1.745-1 5h5l-1.335-1.669zm5.335 8.669l-1.396-1.745c-.759.467-1.647.745-2.604.745-2.426 0-4.445-1.729-4.901-4.02l1.96-.398c.271 1.376 1.486 2.418 2.941 2.418.483 0 .933-.125 1.335-.331l-1.335-1.669h5l-1 5z"/>
                </svg>
              </span>
              <ImageListItemBar title="Sync with Web App" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickSmartHome}>
              <span className="grids bg-red">
                <svg width="70" height="70" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                  <path fill="#ffffff" d="M13 23l-9.983-.014v-9.979l8.974-7.995c1.124.999 2.25 1.998 3.378 2.998l2.255 1.999c1.127.999 2.252 1.992 3.376 2.991v10l-5.993-.014-.007-4.986h-2v5zm6-2l.019-7.121-7.028-6.193-6.991 6.218v7.096h6v-5h6v5h2zm-10-5v3h-2v-3h2zm3-15l12 10.654-1.328 1.494-10.672-9.488-10.672 9.488-1.328-1.494 12-10.654z"/>
                </svg>
              </span>
              <ImageListItemBar title="Smart Home System" className={classes.titlename}/>
            </ButtonBase>

            <ButtonBase focusRipple className={classes.image} onClick={props.onClickControl}>
              <span className="grids bg-red">
                <svg width="70" height="70" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                  <path fill="#ffffff" d="M13 23l-9.983-.014v-9.979l8.974-7.995c1.124.999 2.25 1.998 3.378 2.998l2.255 1.999c1.127.999 2.252 1.992 3.376 2.991v10l-5.993-.014-.007-4.986h-2v5zm6-2l.019-7.121-7.028-6.193-6.991 6.218v7.096h6v-5h6v5h2zm-10-5v3h-2v-3h2zm3-15l12 10.654-1.328 1.494-10.672-9.488-10.672 9.488-1.328-1.494 12-10.654z"/>
                </svg>
              </span>
              <ImageListItemBar title="Controls" className={classes.titlename}/>
            </ButtonBase>
            </div>
        </div>
      </React.Fragment>
    );
  };

  const AddAccount = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
         <DialogTitle className="popupHearder">Add Account</DialogTitle>
              <DialogContent >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent:'space-between', alignItems: 'center'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Contact Name"
                  type="text"
                  halfWidth
                  variant="standard"
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Contact Number"
                  type="text"
                  halfWidth
                  variant="standard"
                />
                <Button variant="contained">Add</Button>

                </Box>
              </DialogContent>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="accountTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Action
                      </TableCell>
                      <TableCell align="left">Contact Name</TableCell>
                      <TableCell align="right">Contact Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>                    
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Button variant="contained" color="error">Delete</Button>
                        </TableCell>
                        <TableCell align="left">Rajiv</TableCell>
                        <TableCell align="right ">+91 1245678932</TableCell>                        
                      </TableRow> 
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Button variant="contained" color="error">Delete</Button>
                        </TableCell>
                        <TableCell align="left">Rajiv</TableCell>
                        <TableCell align="right ">+91 1245678932</TableCell>                        
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Button variant="contained" color="error">Delete</Button>
                        </TableCell>
                        <TableCell align="left">Rajiv</TableCell>
                        <TableCell align="right ">+91 1245678932</TableCell>                        
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose}>Add Account</Button>
              </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Wifi = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            WiFi
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <TextField id="standard-basic" label="name" fullWidth />
              <TextField id="standard-basic" label="password" fullWidth />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Analytics = (props) => {
    return (
      <div>
        <Dialog
        fullScreen
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon /> 
              <Typography variant="h6" className={classes.title}>
              Analytics
            </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Analytics
          </DialogTitle>
          <DialogContent className={classes.content}  style={{marginTop:'-50px'}}>
          <Iframe url="http://35.192.72.108/analytics" width="100%" height="500px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
          </DialogContent>
           
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Settings = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Settings
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <FormControlLabel style={{width:'100%'}} control={<Checkbox name="checkedD" />} label="Enable Speech Recognition" />
              <FormControl>
                <InputLabel htmlFor="slide-interval">Exercise Slide Interval</InputLabel>
                <Input id="slide-interval" value="05" />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="cheering-interval">Exercise Cheering Interval</InputLabel>
                <Input id="cheering-interval" value="130" />
              </FormControl>
              <FormControl fullWidth>
              <Button
                variant="contained"
                component="label"
              >
                Upload Exercise Videos
                <input
                  type="file"
                  hidden
                />
              </Button>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
            <Button className={classes.signUpButton}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SpeechSensitivity = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Speech Sensitivity
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
            <FormControl style={{width:'200px'}}>
              <InputLabel id="demo-simple-select-label">Select Speech Sensitivity</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sensitivity}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={1}>High</MenuItem>
                <MenuItem value={2}>Very High</MenuItem>
                <MenuItem value={3}>Medium</MenuItem>
                <MenuItem value={4}>Low</MenuItem>
                <MenuItem value={5}>Very Low</MenuItem>
              </Select>
            </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
            <Button className={classes.signUpButton}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const DialogueDatabase = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Dialogue Database
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <TextField id="standard-basic" label="name" fullWidth />
              <TextField id="standard-basic" label="password" fullWidth />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SynchDialogue = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Synchronise Dialogue
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <TextField id="standard-basic" label="name" fullWidth />
              <TextField id="standard-basic" label="password" fullWidth />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SyncWebApp = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Sync with Web App
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <TextField id="standard-basic" label="name" fullWidth />
              <TextField id="standard-basic" label="password" fullWidth />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SmartHome = (props) => {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Smart Home System
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.text}>
              <TextField id="standard-basic" label="name" fullWidth />
              <TextField id="standard-basic" label="password" fullWidth />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Control = (props) => {
    return (
      <div>
        <Dialog
        fullScreen
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon /> 
              <Typography variant="h6" className={classes.title}>
              Controls
            </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
          <DialogTitle id="alert-dialog-title" className={classes.title}>
          Controls
          </DialogTitle>
          <DialogContent className={classes.content}  style={{marginTop:'-50px'}}>
          <Iframe url="http://35.192.72.108/control" width="100%" height="500px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
          </DialogContent>
           
          <DialogActions>
            <Button onClick={props.handleClose} className={classes.signUpButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
        <TitleBar title="Settings" />
        <div className={classes.root} id="blocks">        
            <div>
            <Top 
              onClickAddAccount={() => setOpenAddAccount(true)} 
              onClickWifi={() => setOpenWifi(true)} 
              onClickAnalytics={() => setOpenAnalytics(true)}
              onClickSettings={() => setOpenSettings(true)}
              onClickSpeechSensitivity={() => setOpenSpeechSensitivity(true)}
              onClickDialogueDatabases={() => setOpenDialogueDatabase(true)}
              onClickSynchDialogue={() => setOpenSynchDialogue(true)}
              onClickSyncWebApp={() => setOpenSyncWebApp(true)}
              onClickSmartHome={() => setOpenSmartHome(true)}
              onClickControl={() => setOpenControl(true)}
            />
            
            <AddAccount open={openAddAccount} handleClose={() => setOpenAddAccount(false)} />
            <Analytics open={openAnalytics} handleClose={() => setOpenAnalytics(false)} />
            <Wifi open={openWifi} handleClose={() => setOpenWifi(false)} />
            <Settings open={openSettings} handleClose={() => setOpenSettings(false)} />
            <SpeechSensitivity open={openSpeechSensitivity} handleClose={() => setOpenSpeechSensitivity(false)} />
            <DialogueDatabase open={openDialogueDatabase} handleClose={() => setOpenDialogueDatabase(false)} />
            <SynchDialogue open={openSynchDialogue} handleClose={() => setOpenSynchDialogue(false)} />
            <SyncWebApp open={openSyncWebApp} handleClose={() => setOpenSyncWebApp(false)} />
            <SmartHome open={openSmartHome} handleClose={() => setOpenSmartHome(false)} />
            <Control open={openControl} handleClose={() => setOpenControl(false)} />
          </div>
        </div>
    </div>
  );
}