import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import translationService from "../../services/translation.service";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
        title = translationService.translateString(title, language);
        console.log("TITLE ", title)
    }
    return title
}

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{computeTitle(column.render('Header'))}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}


const ReceptionistCrud = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [apiURL, setApiURL] = useState("https://cloud-api-389807.ue.r.appspot.com/")
    const [dialogMessage, setDialogMessage] = useState("");
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [unit, setUnit] = useState("");
    const [phone, setPhone] = useState("");
    const [robotId, setRobotId] = useState("");
    const [oldRobotId, setOldRobotId] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    let history = useHistory();

    // Effects
    useEffect(() => {
        if (localStorage.getItem("userRole") !== "ADMIN") {
            history.push("/home");
        }
        axios.get(apiURL + "resident").then(result => {
            setSongs(result.data.data);
            setGotSongs(true);
        });
    }, []);

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',

            },
            {
                Header: 'Unit',
                accessor: 'unit',

            },
            {
                Header: 'Contact number',
                accessor: 'phone',

            },
            {
                Header: 'Robot ID',
                accessor: 'robot_id',

            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="large" variant="outlined" value={cell.row.index} onClick={handleEdit}>Edit</Button>
                        <Button size="large" variant="outlined" value={cell.row.values.robot_id + '|' + cell.row.values.name} onClick={handleDelete}>Delete</Button>
                    </div>
                )

            },


        ],
        []
    )

    //  Functions
    const handleOpen = (e) => {
        if (e.currentTarget.value == "add") {
            setDialogMessage("Add Resident");
        } else {
            setDialogMessage("Update Resident");
        }
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setName("");
        setUnit("");
        setRobotId("");
    }

    const handleEdit = (e) => {
        let target = e.currentTarget.value;
        axios.get(apiURL + "resident").then(result => {
            console.log('joanna ', target, result.data);
            let robo = result.data["data"][target];
            console.log(robo)
            setRobotId(robo["robot_id"]);
            setOldRobotId(robo["robot_id"]);
            setName(robo["name"]);
            setPhone(robo["phone"]);
            setUnit(robo["unit"])
            setShowEdit(true);
            setDialogMessage("Update Robot");
        })
    }

    const handleEditSave = () => {
        let data = JSON.stringify({
          "orig_robot": oldRobotId,
          "new_robot": robotId,
          "name": name,
          "unit": unit,
          "phone": phone
        });
        
        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: 'https://cloud-api-389807.ue.r.appspot.com/resident',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
            setName("");
            setUnit("");
            setRobotId("");
            axios.get(apiURL + "resident").then(result => {
                setSongs(result.data.data);
                setGotSongs(true);
                setShowEdit(false);
            });
        });
        
    }

    const handleSave = () => {
        let data = JSON.stringify({
            "robot_id": robotId,
            "name": name,
            "unit": unit,
            "phone": phone
        });

        let config = {
            method: 'post',
            url: apiURL + 'resident',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setName("");
                setUnit("");
                setRobotId("");
                axios.get(apiURL + "resident").then(result => {
                    setSongs(result.data.data);
                    setGotSongs(true);
                    setShow(false);
                });
            });
    }

    const handleDelete = (e) => {
        const [rob, nam] = e.currentTarget.value.split("|");
        console.log('JOANNA ...', rob, nam)
        let config = {
            method: 'delete',
            url: apiURL + 'resident?robot_id=' + rob.trim() + '&name=' + nam.trim(),
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                axios.get(apiURL + "resident").then(result => {
                    setSongs(result.data.data);
                    setGotSongs(true);
                });
            });

    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = translationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    // Build Page
    return (
        <Styles>
            <TitleBar title={computeTitle("Manage Resident")} />
            <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>
                {computeTitle("Add Resident")}
            </Button>
            <Table columns={columns} data={songs} />
            <Dialog
                open={show}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>{dialogMessage}</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Name"
                        defaultValue={name}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setName(e.target.value); }}
                        value={name}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Unit"
                        defaultValue={unit}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setUnit(e.target.value); }}
                        value={unit}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Contact Number"
                        defaultValue={phone}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setPhone(e.target.value); }}
                        value={phone}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Robot ID"
                        defaultValue={robotId}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setRobotId(e.target.value); }}
                        value={robotId}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showEdit}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose}>{dialogMessage}</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Name"
                        defaultValue={name}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setName(e.target.value); }}
                        value={name}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Unit"
                        defaultValue={unit}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setUnit(e.target.value); }}
                        value={unit}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Contact Number"
                        defaultValue={phone}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setPhone(e.target.value); }}
                        value={phone}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Robot ID"
                        defaultValue={robotId}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setRobotId(e.target.value); }}
                        value={robotId}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleEditSave} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </Styles>
    )
}

export default ReceptionistCrud;