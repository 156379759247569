import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";


class WorkflowDesinger extends Component {
	

	render() {
        return (
            <div>
                <TitleBar title="Work Flow Designer" parentNode={"/technology-tools"}/>
                <Iframe url="https://workflow-397702.ue.r.appspot.com/" width="100%" height="500px" onLoad={()=>{console.log("https://workflow-397702.ue.r.appspot.com/ IRAME ON LOAD")}}></Iframe>
            </div>
        );
    }

	
}

export default WorkflowDesinger;