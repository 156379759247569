import React, { useEffect, useState } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import TranslationService from "../../services/translation.service";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
        title = TranslationService.translateString(title, language);
        console.log("TITLE ", title)
    }
    return title
}

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{computeTitle(column.render('Header'))}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}


const SmartHome = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    let history = useHistory();

    // Effects
    useEffect(() => {
        let name = localStorage.getItem('remoteId');
        let isMounted = false;
        if (!name) {
            history.push('/login')
        }
        if (!gotSongs) {
            var api = `{"api":"getSmartDevices", "action":"get","name": "${name}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
        }

        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("smarthome"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (!isMounted && obj.object_type == "smarthome") {
                        console.log(obj.object_data);
                        setGotSongs(true);
                        setSongs(transformData(obj.object_data));
                    }
                    return () => {
                        isMounted = true;
                    };
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
        };
    }, [gotSongs, songs]);

    const transformData = (obj) => {
        let finalObject = []
        for (let i = 0; i < obj.length; i++) {
            obj[i]["entity_id"] = computeTitle(obj[i]["entity_id"])
            obj[i]["state"] = computeTitle(obj[i]["state"])
            finalObject.push(obj[i])
        }
        return obj
    }

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Entity ID',
                accessor: 'entity_id',

            },
            {
                Header: 'State',
                accessor: 'state',

            },
            {
                Header: 'Last Changed',
                accessor: 'last_changed',

            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="large" variant="outlined" value={cell.row.values.entity_id} onClick={onDeviceOn}>On</Button>
                        <Button size="large" variant="outlined" value={cell.row.values.entity_id} onClick={onDeviceOff}>Off</Button>
                    </div>
                )

            },


        ],
        []
    )

    // Functions
    const onDeviceOn = (e) => {
        var api = `{"api":"deviceOn", "device": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const onDeviceOff = (e) => {
        var api = `{"api":"deviceOff", "device": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    // Build Page
    return (
        <Styles>
            <TitleBar title={computeTitle("Smart Home")} parentNode={"/service-scheduling"} />
            <Table columns={columns} data={songs} />
        </Styles>
    )
}

export default SmartHome;