import React, { Component, useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import styled from 'styled-components'
import { useTable } from 'react-table';
import { useHistory } from "react-router-dom";
import { Input } from "reactstrap";
import { Link } from 'react-router-dom';
//import {vocalise, socket} from '../../socket';
import Button from '@material-ui/core/Button';
import { sendMessage, socket, name, vocalise, waitForSocketConnection, vocaliseV2 } from '../../socket';
import TitleBar from '../Common/TitleBar';
import Translate from '../Common/Translate';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';


const Styles = styled.div`
padding: 0rem;

table {
  width:100%;
  border-collapse: separate;
  border-spacing: 5px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th{
    margin: 0;
    padding: 10px 10px;
    background: #ccc;
    border-radius: 10px;
    font-size: 16px;
  }
  td {
    margin: 0;
    padding: 3px 12px;
    background: #fff;
    font-size: 16px;
    color:#6e6e6e;
    border-radius: 10px;
    :last-child {
      border-right: 0;
    }
  }
}
`


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const NewsApp = () => {

  const [gotSongs, setGotSongs] = useState(false);
  const [songs, setSongs] = useState([]);
  const [show, setShow] = useState(false);
  const [speed, setSpeed] = useState("\\rspd=100\\");
  const [currentPlay, setCurrentPlay] = useState("Select a news to read");
  const [location, setLocation] = useState("Loading...");
  const [condition, setCondition] = useState("Loading...");
  const [lastUpdated, setLastUpdated] = useState("Loading...");
  const [temperature, setTemperature] = useState("Loading...");

  let history = useHistory();
  useEffect(() => {
    const pageLogin = localStorage.getItem('remoteId');
    if (!pageLogin) {
      history.push("/login");
    }

    var request = `{"api":"getNews", "action":"get","name": "${pageLogin}"}`;
    //setTimeout( () =>  sendMessage(request) , 2000 ) 
    waitForSocketConnection(socket, () => sendMessage(request))
    console.log('Hitting Here')


    var getWeath = `{"api":"getWeath", "action":"get","name": "${pageLogin}"}`;
    //setTimeout( () =>  sendMessage(request) , 2000 ) 
    waitForSocketConnection(socket, () => sendMessage(getWeath))



    socket.onmessage = (message) => {
      console.log("INSIDE ON MESSAGE ", message.data)
      if ((message.data) && (message.data.toString().includes("news_jp_all"))) {
        let obj = JSON.parse(message.data);
        console.log("JP NEWS ", obj);
        const newsArticles = obj.news_jp_all;

        // Initialize an array to store dictionaries with title and summary
        const newsData = [];

        // Loop through each news article and construct dictionaries
        for (const article of newsArticles) {
          // Ensure each article has title and description properties
          if (article.title && article.description) {
            // Construct a dictionary with title and summary properties
            const articleData = {
              title: article.title,
              summary: article.description,
            };

            // Push the dictionary into the newsData array
            newsData.push(articleData);
          }
        }
        setSongs(newsData)
      }
      if ((message.data) && (message.data.toString().includes("title")) && !(message.data.toString().includes("news_jp_all"))) {
        setCurrentPlay("Select a news to read")
      }
      if ((message.data) && (message.data.toString().includes("vocalise")) && (message.data.toString().includes("text"))) {
        let obj = JSON.parse(message.data);
        setCurrentPlay("Reading a news")
      }
      if ((message.data) && (message.data.toString().includes("title")) && !(message.data.toString().includes("news_jp_all"))) {
        console.log(JSON.parse(message.data).data)
        setSongs(JSON.parse(message.data).data);
      }
      if ((message.data) && (message.data.toString().includes("weather_update"))) {
        let obj = JSON.parse(message.data);
        console.log("WEATHER ", obj);
        setLocation(obj.weather_update.location.country);
        setCondition(obj.weather_update.current.condition.text);
        setTemperature(obj.weather_update.current.temp_c);
        setLastUpdated(obj.weather_update.location.localtime);
      }
    }


    //      AuthService.getNewsFeed().then(
    //     (response) => {
    //       setSongs(response.data['entries']);
    //       // setFilteredLogEnties(response.data);


    //      console.log(response.data['entries']);
    //     },
    //     (error) => {
    //       const _content = 
    //         (error.response && error.response.data) || 
    //         error.message ||
    //         error.toString();

    //     }
    // );
  }, []);

  const handleResync = () => {
    var getWeath = `{"api":"getWeatForce", "action":"get","name": "${localStorage.getItem('remoteId')}"}`;
    waitForSocketConnection(socket, () => sendMessage(getWeath))
  }

  const handleJPNews = () => {
    var getJPNewAll = `{"api":"getJPNewAll", "action":"get","name": "${localStorage.getItem('remoteId')}"}`;
    waitForSocketConnection(socket, () => sendMessage(getJPNewAll))
  }

  //   useEffect(() => {

  //     let isMounted = false; // note this flag denote mount status
  //     if (!gotSongs)
  //     {
  //       var api = `{"api":"music", "action":"get"}`;
  //       socket.send(api); 
  //     }
  //     socket.onmessage = (message) => {
  //       var str = message.data.substring(0,message.data.length-1);
  //       //console.log(str);
  //       try {
  //         var obj = JSON.parse(str.replace(/\0/g, ''));
  //         if (!isMounted && obj.type == "songs")
  //         {
  //           setGotSongs(true);
  //           setSongs(obj.songlist);
  //           // console.log(obj.songlist);
  //         }
  //         return () => {
  //           isMounted = true;
  //         };
  //     } catch (e) {
  //         return false;
  //     }



  //     };
  // }, [songs]);

  const handleClose = (e) => {
    console.log("CLOSE");
    setShow(false);
  }

  const handleOpen = (e) => {
    console.log("OPEN");
    // UserService.getSpeedList().then((response) => {
    //   console.log("Speed Response: ", response)
    //   setSpeed(response.speed);
    // })
    setShow(true);
  }

  const handleSave = (e) => {
    console.log("SAVE");
    console.log("SPEED ", speed)
    // UserService.saveSpeed(speed).then((response) => {
    //   console.log("Speed Response Creating: ", response)
    // })
    localStorage.setItem("speed", speed)
    setShow(false);
  }

  const handleChange = (e) => {
    console.log("UPDATING ", e.target.value)
    setSpeed(e.target.value);
  };

  const handleStartClick = (e) => {
    //alert(e.currentTarget.value);
    // var api = `{"api":"vocalise", "text":"${e.currentTarget.value}", "emotion":"neutral","sound":"none","language":"en"}`;
    let msg = e.currentTarget.value.replace('"', "").replace("'", "")
    let text = localStorage.getItem("speed") + msg
    text = text.replaceAll('—', '')
    vocaliseV2(text);

  }
  const handleStopClick = (e) => {
    //alert(e.currentTarget.value);
    var api = `{"api":"vocalisationTerminate", "action":"stop", "name": "${name}"}`;

    waitForSocketConnection(socket, () => sendMessage(api))

  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',

      },
      {
        Header: 'Action',
        accessor: 'summary',
        Cell: ({ cell }) => (
          <div>
            <Button size="large" variant="outlined" color="primary" value={cell.row.values.title + '.' + cell.row.values.summary} onClick={handleStartClick}>Read</Button>
            <Button size="large" variant="outlined" color="secondary" value={cell.row.values.summary} onClick={handleStopClick}>Stop</Button>
          </div>
        )

      },


    ],
    []
  )


  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // Create four columns with equal width
    gap: '5px', // Adjust gap between items as needed
  };

  const boxStyle = {
    border: '1px solid #000',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (

    <div className="">

      <TitleBar title="News" />
      <Translate />
      <Styles>
        <Dialog
          open={show}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle id="form-dialog-title" onClose={handleClose}>Update Speech Configuration</DialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <InputLabel htmlFor="service-label">Service</InputLabel>
              <Select defaultValue={speed} value={speed} id="select-service" onChange={handleChange}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem key={'extreme-fast'} value={'\\rspd=130\\'}>Extreme Fast</MenuItem>
                <MenuItem key={'very-fast'} value={'\\rspd=120\\'}>Very Fast</MenuItem>
                <MenuItem key={'fast'} value={'\\rspd=110\\'}>Fast</MenuItem>
                <MenuItem key={'normal'} value={'\\rspd=100\\'}>Normal</MenuItem>
                <MenuItem key={'slow'} value={'\\rspd=90\\'}>Slow</MenuItem>
                <MenuItem key={'very-slow'} value={'\\rspd=80\\'}>Very Slow</MenuItem>
                <MenuItem key={'extreme-slow'} value={'\\rspd=70\\'}>Extreme Slow</MenuItem>
              </Select>

            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Button size="small" type="primary" variant="outlined" value="add" onClick={handleOpen}>Update Configuration</Button>
        <Button size="small" type="primary" variant="outlined" value="add" onClick={handleResync}>Re-sync Weather</Button>
        <Button size="small" type="primary" variant="outlined" value="add" onClick={handleJPNews}>Japan News</Button>
        <br />
        <i><small>{currentPlay}</small></i>
        <div style={containerStyle}>
          <div style={boxStyle}>
            <small>LOCATION</small><br></br>
            <h5>{location}</h5>
          </div>
          <div style={boxStyle}>
            <small>CONDITION</small><br></br>
            <h5>{condition}</h5>
          </div>
          <div style={boxStyle}>
            <small>TEMPERATURE (In Celcius)</small><br></br>
            <h5>{temperature}</h5>
          </div>
          <div style={boxStyle}>
            <small>LAST UPDATED</small><br></br>
            <h5>{lastUpdated}</h5>
          </div>
        </div>
        <Table columns={columns} data={songs} />
      </Styles>
    </div>
  );
}

export default NewsApp;