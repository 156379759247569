import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import AuthService from "../../services/auth.service";
import SelectedList from "./SelectedList";
import Grid from '@material-ui/core/Grid';
import ImageUploadCard from "./ImageUploadCard";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1D3557',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#A8DADC',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: '#1D3557',
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const  ObjectForm = ({question}) =>{
  const [spacing, setSpacing] = React.useState(50);
  const [thequestion, setQuestion] = React.useState(null);
  const classes = useStyles();

  const [value, setValue] = React.useState('female');



  useEffect(() => {
    setQuestion(question);
    // alert(question.text);
}, [question]);


  

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  return (
    
    <div className={classes.root}>
      <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={spacing}>
          
        
            <Grid key={0}>
              <form className={classes.root} noValidate autoComplete="off">
                <div>
                <TextField
                    id="outlined-multiline-static"
                    label="Content"
                    multiline
                    rows={4}
                    defaultValue={question.text}
                    variant="outlined"
                  />
                  
                </div>
                <div>

                <FormLabel component="legend">Options</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                 {
                   question.options.map( (option,index) => 
                   <div>
                    <TextField
                    id={index}
                    label={`Option ${index}`}
                    multiline
                    rows={1}
                    defaultValue={option}
                    variant="outlined"
                    />
                    <FormControlLabel value={index} control={<Radio checked={option === question.answer}/>} label="Correct" />
                    </div>
                   )
                 }
                
                
                 
                </RadioGroup>
                </div>
               
              </form>
            </Grid>
          
            <Grid key={1} >
              <ImageUploadCard url={question.image}/>
            </Grid>

        </Grid>
      </Grid>
     
    </Grid>
      
    </div>
          
  );
}

export default ObjectForm;