import React, { useState, useEffect } from "react";
import TitleBar from "../Common/TitleBar";
import MonitoringV3 from "./MonitoringV3";
import { sendMessage, socket, waitForSocketConnection } from "../../socket";
import { Chart } from 'react-charts';

function CustomLegend({ data }) {
    return (
        <div className="custom-legend">
            <small><strong>Legend</strong></small>
            {data.map((series, index) => (
                <div key={index} className="legend-item">
                    <div
                        className="legend-color"
                        style={{ backgroundColor: series.color }}
                    ></div>
                    <small><span className="legend-label">{series.label}</span></small>
                </div>
            ))}
        </div>
    );
}

const HeartRate = () => {
    const [isCountingDown, setIsCountingDown] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);
    const [isDone, setIsDone] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [heartRate, setHeartRate] = useState([]);
    const [happy, setHappy] = useState([]);
    const [angry, setAngry] = useState([]);
    const [sad, setSad] = useState([]);
    const [surprised, setSurprised] = useState([]);
    const [rate, setRate] = useState([]);
    const [heartRateStatus, setHeartRateStatus] = useState("Stopped");

    socket.onmessage = (message) => {
        console.log('Received ...', message.data);
        if (message.data.toString().includes("HEARTRATEVIDEODONE")) {
            setIsCountingDown(false);
            setTimeLeft(0);
            setIsDone(true)
        }
        else if (message.data.toString().includes("HEARTRATEPROCESSDONE")) {
            let response = JSON.parse(message.data);
            setHeartRate(response.heartRate.map((value, index) => ({ x: index + 1, y: value })));
            setHappy(response.happy);
            setSad(response.sad);
            setAngry(response.angry);
            setSurprised(response.surprised);
            setRate(response.rate);
            setIsCountingDown(false);
            setTimeLeft(0);
            setShowResult(true);
            console.log('Done processing! you may check the logs now.')
        }
    };

    useEffect(() => {
        let timer;
        if (isCountingDown && timeLeft == 1) {
            setIsCountingDown(false);
            setTimeLeft(0);
            setIsDone(true)
        }
        else if (isCountingDown && timeLeft > 0) {
            timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (isCountingDown && timeLeft === 0) {
            console.log('Done');
            setIsCountingDown(false);  // Optionally stop the countdown
        }
        return () => clearTimeout(timer);
    }, [isCountingDown, timeLeft]);

    const startDetection = () => {
        setIsCountingDown(true);
        setTimeLeft(60);
        setIsDone(false);
        setShowResult(false)
        setIsStopped(false)

        var api = `{"api":"hrViCapStart", "name": "${localStorage.getItem("remoteId")}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    };

    const onComputeHeartRate = () => {
        setIsCountingDown(true);
        setTimeLeft(60);
        var api = `{"api":"hrViCapProcess", "name": "${localStorage.getItem("remoteId")}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const stopDetection = () => {
        setIsCountingDown(false);
        setTimeLeft(60);
        setShowResult(false)
        setIsStopped(true)
    };

    const progressBarWidth = isCountingDown ? `${(60 - timeLeft) / 60 * 100}%` : "0%";

    const axes = [
        { primary: true, type: 'linear', position: 'bottom' },
        { type: 'linear', position: 'left' },
    ];

    const data = [
        {
            label: 'Happy',
            data: happy,
            color: 'red',
        },
        {
            label: 'Sad',
            data: sad,
            color: 'blue',
        },
        {
            label: 'Angry',
            data: angry,
            color: 'green',
        },
        {
            label: 'Surprised',
            data: surprised,
            color: 'yellow',
        },
        {
            label: 'Heart Rate',
            data: rate,
            color: 'pink',
        },
    ];

    const [isStopped, setIsStopped] = useState(false)

    return (
        <>
            <TitleBar title="Heart Rate" />
            <div style={styles.container}>
                {/* <div style={styles.buttonContainer}>
                    <button style={styles.button} onClick={startDetection}>Start Detection</button>
                    <button style={styles.button} onClick={stopDetection}>Stop</button>
                    {!isCountingDown && isDone && <button style={styles.button} onClick={onComputeHeartRate}>Get heart rate</button>}
                    {isCountingDown && isDone && <button style={styles.button} onClick={onComputeHeartRate}>Retry get heart rate</button>}
                </div> */}
                {isCountingDown && (
                    <>
                        <div style={styles.progressBarContainer}>
                            <div style={{ ...styles.progressBar, width: progressBarWidth }}></div>
                        </div>
                        <p style={styles.countdown}>Countdown: {timeLeft}s</p>
                    </>
                )}
                { !showResult && <p style={styles.instructions}>Please make sure your face is in the center.</p>}
            </div>
            {!showResult && <MonitoringV3 startDetection={() => startDetection()} stopDetection={stopDetection} isCountingDown={isCountingDown} isDone={isDone} onComputeHeartRate={onComputeHeartRate} />}
            {showResult && <div style={{ height: "300px" }}>                             <Chart data={data} axes={axes} tooltip />
                <CustomLegend data={data} /></div>}
        </>
    );
};

const styles = {
    container: {
        textAlign: "center",
        margin: "20px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginBottom: "20px"
    },
    button: {
        padding: "10px 20px",
        fontSize: "16px",
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#4CAF50", /* Green */
        color: "white",
        cursor: "pointer",
        transition: "background-color 0.3s"
    },
    countdown: {
        fontSize: "18px",
        color: "#333",
        margin: "10px 0"
    },
    instructions: {
        fontSize: "16px",
        color: "#777",
        marginTop: "10px"
    },
    progressBarContainer: {
        width: "80%",
        backgroundColor: "#e0e0e0",
        borderRadius: "5px",
        overflow: "hidden",
        margin: "10px auto",
        height: "20px"
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#4CAF50",
        transition: "width 1s linear"
    }
};

export default HeartRate;
