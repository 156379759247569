import axios from "axios";

const API_URL = "http://192.168.163.30:8082/";

// const API_URL = "http://192.168.163.30:8082/";
//const API_URL = "http://34.92.77.213:8082/api/auth/";
const register = (username, email, password) => {
  return axios.post(API_URL + "api/auth/signup", {
    username,
    email,
    password,
  });
};

const adminregister = (username, email, password, roles) => {
  return axios.post(API_URL + "api/auth/signup", {
    username,
    email,
    password,
    roles
  });
};

const login = (username, password) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post(API_URL + "api/auth/signin", {
      username,
      password,
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const changePassword = (username, oldPassword, newPassword) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post(API_URL + "api/auth/changepassword", {
      username,
      oldPassword,
      newPassword
    },
    {
      headers: headers
    })
    .then((response) => {
      if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
  console.log(JSON.parse(localStorage.getItem("user")));
};

const getLog = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get( API_URL + "loglist");
};

const getQuiz = (id) => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "quiz/"+id);
};

const getNewsFeed = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get("http://192.168.163.30:8000/news");
};

const getRobotStatus = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "robots");
};

const getPhoto = (id) => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "photo/"+id);
};

const getLearningData = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get("https://matilda-api-eb3rxsvgbq-uw.a.run.app/" + "learning?robot=" + localStorage.getItem("remoteId"));
};

const getQuizList = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL +"quiz");
};

const getQuizContent = (id) => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "quiz/"+id);
};

const getLearningContent = (id) => {
  
  // return axios.get(API_URL + "learning/"+id);
  return axios.get("https://matilda-api-eb3rxsvgbq-uw.a.run.app/" + "learning/" + id + "?robot=" + localStorage.getItem("remoteId"));
};

const getBingoContent = (id) => {
  
  return axios.get(API_URL + "bingo/"+id);
};

const getBingoList = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "bingo");
};

const getWorkflowList = () => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get("http://192.168.163.30:8000/workflow");
};

const getLearningPhoto = (id) => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  // return axios.get(API_URL + "learning/"+id);
  return axios.get("https://matilda-api-eb3rxsvgbq-uw.a.run.app/" + "learning/" + id + "?robot=" + localStorage.getItem("remoteId"));
};

const getBingoPhoto = (id) => {
  //return axios.get("http://34.92.77.213:8082/loglist");
  return axios.get(API_URL + "bingo/"+id);
};

const getPrompts = () => {
  return axios.get("http://192.168.163.30:8000/prompt");
};

const sendPrompt = (text, times, interval) => {
  let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');    
    headers.append('Origin','http://192.168.163.30:8082');
  return axios
    .post("http://192.168.163.30:8000/prompt", {
      text,
      times,
      interval
    },
    {
      headers: headers
    })
    .then((response) => {    

      return response.data;
    });
};

const translateString = () => {
  fetch("translateData.json")
      .then(response => response.json())
      .then(json => console.log('Translate', json));
  return "OK"
}


export default {
  register,
  adminregister,
  login,
  changePassword,
  logout,
  getCurrentUser,
  getLog,
  getPhoto,
  getLearningPhoto,
  getRobotStatus,
  getNewsFeed,
  getLearningData,
  getQuizList,
  getQuizContent,
  getLearningContent,
  getWorkflowList,
  getBingoList,
  getBingoContent,
  getBingoPhoto,
  sendPrompt,
  getPrompts,
  translateString
};
