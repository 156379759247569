import React, { Component, useEffect, useState } from "react";

const Translate = () => {

    const googleTranslateElementInit = () => {
      //new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
      new window.google.translate.TranslateElement({ pageLanguage: 'en',layout:window.google.translate.TranslateElement.FloatPosition.TOP_RIGHT}, 'google_translate_element')
      
    }
     
     useEffect(() => {
       var addScript = document.createElement('script');
       addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
       document.body.appendChild(addScript);
       window.googleTranslateElementInit = googleTranslateElementInit;
     }, [])
   
     return (
    //    <div>
    //      <h2 className="title gx-mb-4"><IntlMessages id="sidebar.samplePage"/></h2>
    //      <div id="google_translate_element"></div>
    //      <div className="gx-d-flex justify-content-center">
    //        <h4>Start building your app. Happy Coding!</h4>
    //      </div>
   
    //    </div>
    <div id="google_translate_element"></div>
     );
   };
   
   export default Translate