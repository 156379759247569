import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import itemData from './itemData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 500,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function GameList() {
  const classes = useStyles();
  const history = useHistory();


  const imageClick = (title) => {
    console.log('Click');
    if (title == 'tictactoe')
    {
        window.location.href='/tictactoe'
    }
    else if (title == 'memorygame')
    {
        window.location.href='/memorygame'
    }
    else if (title == 'maths')
    {
        window.location.href='/maths'
    }

    else if (title == 'chess')
    {
        window.location.href='/chess'
    }
   
  } 
  return (
    <div className={classes.root}>
      <ImageList rowHeight={180}  className={classes.imageList}>
        <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">Games</ListSubheader>
        </ImageListItem>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img src={item.img} alt={item.title} onClick={() => imageClick(item.id)} />
            <ImageListItemBar
              title={item.title}
            //   subtitle={<span>by: {item.author}</span>}
            //   actionIcon={
            //     <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
            //       <InfoIcon />
            //     </IconButton>
            //   }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}