import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function QuestionList({questions, selectCallback, editCallback,deleteCallback}) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);   
    selectCallback(index); 
  };

  const handleDelete = (event, index) => {
    
    deleteCallback(index); 
   //alert(index);
  };

  const handleEdit = (event, index) => {
    setSelectedIndex(index);   
    editCallback(index);  
    alert("edit"+index);
  };
  return (
    <div className={classes.root}>
      <Paper style={{maxHeight: 400, overflow: 'auto'}}>
      <List component="nav" aria-label="main mailbox folders">

      {questions.map((item, index) => (
        <ListItem
        button
        selected={selectedIndex == index}
        key = {index+item.text}
        value = {item.text}
        onClick={(event) => handleListItemClick(event, index)}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={item.text} />
          <ListItemSecondaryAction >
            <IconButton edge="end" aria-label="edit" onClick={(event) => handleEdit(event, item)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={(event) => handleDelete(event, item)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}

       
      </List>
      </Paper>
      <Divider />
      
    </div>
  );
}