import React from "react";
import TitleBar from "../Common/TitleBar";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { useTable } from "react-table";
import styled from "styled-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const CognitiveSettings = () => {
    // Variables
    const [isShowAdd, setIsShowAdd] = useState(false);
    const [isShowEdit, setIsShowEdit] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState("");
    const [isShowQuestion, setIsShowQuestion] = useState();
    const [questionIdx, setQuestionIdx] = useState();
    const [position, setPosition] = useState('');

    // Effects
    useEffect(() => {
        getAllQuestions();
    }, []);

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Question',
                accessor: 'question',

            },
            {
                Header: 'Position',
                accessor: 'position',

            },
            {
                Header: 'Is Show',
                accessor: 'is_show',

            },
            {
                Header: 'Action',
                accessor: '_id',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="large" variant="outlined" value={cell.row.values._id.$oid} onClick={deleteQuestion}>Delete</Button>
                        <Button size="large" variant="outlined" value={cell.row.index} onClick={openEdit}>Update</Button>
                    </div>
                )

            },


        ],
        []
    )

    // Functions
    const openAdd = () => {
        console.log('Open Add');
        setIsShowAdd(true);
    }

    const closeAdd = () => {
        console.log('Close Add');
        setIsShowAdd(false);
        setIsShowEdit(false);
    }

    const openEdit = (e) => {
        console.log('Open Edit');
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
            headers: {}
        };
        localStorage.setItem('editID', e.currentTarget.value);
        axios.request(config)
            .then((response) => {
                setQuestion(response.data.data[localStorage.getItem("editID")]?.question);
                setIsShowQuestion(response.data.data[localStorage.getItem("editID")]?.is_show);
                setQuestionIdx(response.data.data[localStorage.getItem("editID")]?._id?.$oid);
                setIsShowEdit(true);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                console.log("Question ", question);
            });
    }

    const deleteQuestion = (e) => {
        console.log("Delete Question");
        let config = {
            method: 'delete',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question?question_id=' + e.currentTarget.value,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                getAllQuestions();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const createQuestion = () => {
        let data = JSON.stringify({
            "question": question,
            "is_deleted": "no",
            "is_show": "yes",
            "position": position,
        });

        let config = {
            method: 'post',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                getAllQuestions();
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setQuestion("");
                setIsShowAdd(false);
            });

    }

    const getAllQuestions = () => {
        console.log("Get All Questions");
        let config = {
            method: 'get',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setQuestions(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateQuestion = () => {
        console.log("Update Question");
        console.log(isShowQuestion, question, questionIdx);
        let data = JSON.stringify({
            "question_id": questionIdx,
            "question": question,
            "is_show": isShowQuestion
        });

        let config = {
            method: 'put',
            url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                getAllQuestions();
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setQuestion("");
                setQuestionIdx("");
                closeAdd();
            });

    }

    // Render Web Application
    return (
        <Styles>
            <TitleBar title="Cognitive and Emotion Assessment Settings" parentNode={"/system-settings"} />
            <Button size="small" color="primary" variant="outlined" onClick={openAdd}>Add Question</Button>
            <Table columns={columns} data={questions} />
            <Dialog
                open={isShowAdd}
                onClose={closeAdd}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>ADD NEW QUESTION</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Question"
                        defaultValue={question}
                        inputProps={{ maxLength: 1000, }}
                        onChange={e => { setQuestion(e.target.value); }}
                        value={question}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Position"
                        defaultValue={position}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setPosition(e.target.value); }}
                        value={position}
                        fullWidth
                    />
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={createQuestion}>Submit</Button>
                    <Button onClick={closeAdd}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isShowEdit}
                onClose={closeAdd}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>EDIT QUESTION</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Question"
                        defaultValue={question}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setQuestion(e.target.value); }}
                        value={question}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Position"
                        defaultValue={position}
                        inputProps={{ maxLength: 200, }}
                        onChange={e => { setPosition(e.target.value); }}
                        value={position}
                        fullWidth
                    />
                    <br />
                    <FormControl>
                        <Typography>Is Show?</Typography>
                        <RadioGroup
                            row
                            label={"Is Show"}
                            defaultValue={isShowQuestion}
                            onChange={e => { setIsShowQuestion(e.target.value); }}
                            value={isShowQuestion}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={updateQuestion}>Submit</Button>
                    <Button onClick={closeAdd}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Styles>
    )
}

export default CognitiveSettings;