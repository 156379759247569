import React from 'react';

function Hero(props){
    return (
        <header className="col-12 hero-header">
            <h1>{props.question.replace(/\s?\\\\voice=(\w+\s?)+\\\\\s?/g, "").replace(/\s?\\\\speed=(\w+\s?)+\\\\\s?/g, "").replace(/\s?\\\\emotion=(\w+\s?)+\\\\\s?/g, "").replace(/\s?\\\\sound=(\w+\s?)+\\\\\s?/g, "").replace(/\s?\\\\head=(\d+,\d+;*)+\\\\\s?/g, "")}</h1>
        </header>
    )
}

export default Hero;