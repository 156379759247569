import React from 'react';

import Option from './Option';
import Timer from './Timer';
import './css/loader.css';


function MainContent(props) {
    return (
        <div className="col-12 main-content clearfix p-0 overflow-hidden">
            <h3>&nbsp;&nbsp;{props.question}</h3>
            <div className="wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="float-left" style={{ height: '400px', width: '500px', alignContent: 'center' }}>
                    {props.hasPhoto
                        ? <img src={props.imageUrl} className="author-image" alt={props.correctAnswer} width="500px" height="100px" />
                        : <div className="loader"></div>
                    }
                </div>
                {props.setOfAnswer
                    ? <div className="col-lg-5 float-right item-options">
                        {props.setOfAnswer.map((item, index) => <Option item={item} key={index} enableContinueBtn={props.enableContinueBtn} answer={props.correctAnswer} />)}
                    </div>
                    : <div className="col-lg-5 float-right item-options option-loader">
                        <div className="loader"></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MainContent;
