import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AppBar, styled } from "@material-ui/core";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   if(localStorage.getItem('remoteId')) {
  //     history.push("/menu");
  //   }
  // }, []);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    localStorage.setItem("userEmail", username);
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      // AuthService.login(username, password).then(
      //   () => {
      //     props.history.push("/profile");
      //     window.location.reload();
      //   },
      //   (error) => {
      //     const resMessage =
      //       (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //       error.message ||
      //       error.toString();

      //     setLoading(false);
      //     setMessage(resMessage);
      //   }
      // );
      let config1 = {
        method: 'get',
        url: 'https://cloud-api-389807.ue.r.appspot.com/user?email=' + username,
        headers: {}
      };

      axios.request(config1)
        .then((response) => {
          console.log(JSON.stringify(response.data), response.data.data["email"], "JOANNAHERE");
          localStorage.setItem("userRole", response.data.data["role"])
          localStorage.setItem("emailAccess", response.data.data["email"])
          localStorage.removeItem("remoteId");
        })
        .catch((error) => {
          console.log(error);
        });

      let data = JSON.stringify({
        "password": password,
        "email": username
      });

      let config = {
        method: 'post',
        url: 'https://cloud-api-389807.ue.r.appspot.com/login',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setLoading(false);
          if (JSON.stringify(response.data) == "\"OK\"") {
            console.log("Good login", response.data)
            // localStorage.setItem("userEmail", username)
            if (username.includes("marie")) {
              history.push("/otp-authenticator")
            } else {
              history.push("/home")
            }
          } else {
            console.log("Failed login")
            setMessage("Failed to login")
          }
          //     setMessage(resMessage);
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      setLoading(false);
    }
  };

  return (
    <div style={{backgroundColor: "#ffefe"}}>
      <AppBar style={{ height: "63px", "background-image": "linear-gradient(to right, #8800c9, #ec4899)" }}>
        <svg viewBox="0 0 8217 1309" cx={10000} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsSerif="http://www.serif.com/" style={{ "fill-rule": "evenodd", "clip-rule": "evenodd", "stroke-linejoin": "round", "stroke-miterlimit": 2, height: "180px", width: "225px", paddingLeft: "75px" }}>
          <g id="matilda-text" serifId="matilda text">
            <path d="M4703.41,1265.01c0,19.638 14.855,31.9 41.309,31.9l166.483,0c22.979,0 44.221,-8.925 44.221,-31.9l-0,-727.25l169.404,0c17.7,-0.42 26.3,-9.937 23.925,-30.425l0,-163.616c3.45,-20.946 -5.221,-34.196 -23.925,-32.988l-169.046,0l0,-265.425c0,-13.7 -9.616,-45.354 -34.158,-45.304c-64.521,0 -218.213,39.417 -218.213,79.75l0,230.979l-100.791,0c-18.904,0 -23.925,11.813 -23.925,33.263l-0,154.291c-0,28.975 8.975,39.734 23.925,39.734l100.4,-0l0.391,726.991Z" style={{ "fill": "currentColor" }}></path>
            <path d="M5728.5,1259.47c-0,19.637 14.854,31.9 41.308,31.9l166.483,-0c22.98,-0 44.221,-8.925 44.221,-31.9c0,-0 0.359,-0 0.359,-1203.75c-0,-34.613 -8.475,-40.005 -70.584,-39.875l-117.037,-0c-60.042,-0 -64.75,3.654 -64.75,39.875l-0,1203.75Z" style={{ "fill": "currentColor" }}></path>
            <path d="M4628.62,462.883l116.35,0" style={{ "fill": "none" }}></path>
            <path d="M1817.43,1272.92c5.058,19.746 16.883,26.3 35.479,22.779l175.942,-4.416c20.208,0.437 33.791,-2.992 36.133,-17.171l0.467,-568.217c-0,-269.575 388.646,-249.183 388.646,-20.904l-0,576.917c-1.096,21.829 8.283,31.625 30.304,30.341l192.754,0c18.817,-0.691 30.137,-7.325 26.625,-30.341l-0,-574.484c-0,-244.387 385.825,-244.387 385.825,0l-0,576.4c0.029,20.259 9.675,29.334 28.946,30.342l183.375,2.746c19.341,-2.159 32.771,-9.629 33.804,-33.088l-0.279,-606.308c-0,-211.854 -140.267,-377.833 -374.788,-377.833c-150.462,5.237 -252.637,67.15 -323.396,165.937c-49.404,-104.85 -141.604,-170.937 -302.958,-170.937c-129.146,-0 -235.279,50.025 -282.937,119.904c-7.725,-39.938 -7.171,-91.429 -47.85,-91.429l-143.955,-0c-35.429,-0 -41.987,7.029 -44.587,31.904l2.45,937.858Z" style={{ "fill": "currentColor" }}></path>
            <path d="M5283.33,1265.01c0,19.638 14.854,31.9 41.308,31.9l166.484,0c22.979,0 44.221,-8.925 44.221,-31.9l0.358,-868.804c-0,-13.7 -6.171,-23.975 -30.713,-23.925l-188.808,0c-25.8,0 -32.85,5.234 -32.85,23.925l0,868.804Zm121.463,-1249.19c69.125,-0 125.241,67.808 125.241,151.329c0,83.521 -56.116,151.329 -125.241,151.329c-69.125,0 -125.246,-67.808 -125.246,-151.329c-0,-83.521 56.121,-151.329 125.246,-151.329Z" style={{ "fill": "currentColor" }}></path>
            <path d="M3919.04,292.05c-637.22,-1.821 -621.304,998.488 7.975,1003.22c55.771,0.421 190.825,1.504 290.225,-90.267c0,54.088 2.517,90.338 56.609,90.338l139.558,1.587c9.308,-1.1 35.896,-8.183 35.896,-41.829l-0,-916.8c-0,-25.525 -4.3,-35.471 -50.475,-35.471c-2.013,-1.441 -124.825,0.184 -124.825,0.184c-45.508,-0 -50.525,49.233 -55.358,101.687c-73.238,-72.008 -145.938,-112.65 -299.605,-112.65Zm6.534,226.104c127.283,0 230.616,124.854 230.616,278.646c0,153.792 -103.333,278.65 -230.616,278.65c-127.279,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.338,-278.646 230.617,-278.646Z" style={{ "fill": "currentColor" }}></path>
            <path d="M7686.41,292.05c-637.22,-1.821 -621.304,998.488 7.975,1003.22c55.767,0.421 190.825,1.504 290.221,-90.267c0,54.088 2.521,90.338 56.609,90.338l139.562,1.587c9.304,-1.1 35.892,-8.183 35.892,-41.829l-0,-916.8c-0,-25.525 -4.296,-35.471 -50.471,-35.471c-2.013,-1.441 -124.825,0.184 -124.825,0.184c-45.513,-0 -50.525,49.233 -55.358,101.687c-73.238,-72.008 -145.938,-112.65 -299.605,-112.65Zm6.534,226.104c127.279,0 230.616,124.854 230.616,278.646c0,153.792 -103.337,278.65 -230.616,278.65c-127.284,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.333,-278.646 230.617,-278.646Z" style={{ "fill": "currentColor" }}></path>
            <path d="M6565.75,292.05c-637.221,-1.821 -621.304,998.488 7.975,1003.22c55.766,0.421 190.821,1.504 290.221,-90.267c-0,54.088 2.52,90.338 56.608,90.338l139.558,1.587c9.309,-1.1 35.896,-8.183 35.896,-41.829l0,-1175.33c0,-42.642 -117.729,-70.004 -146.175,-72.017c-2.008,-1.441 -29.121,0.179 -29.121,0.179c-35.141,0 -55.183,8.067 -55.183,71.838c-0,97.346 4.25,231.979 1.529,295.075c-0.587,13.687 -1.167,24.012 -1.708,29.854c-73.238,-72.008 -145.938,-112.65 -299.6,-112.65Zm6.529,226.104c127.283,0 230.621,124.854 230.621,278.646c-0,153.792 -103.338,278.65 -230.621,278.65c-127.279,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.338,-278.646 230.617,-278.646Z" style={{ "fill": "currentColor" }}></path>
          </g>
          <path d="M89.473,586.55c139.962,-764.354 1253.21,-773.537 1429.64,-2.804c132.838,71.308 105.221,272.775 -4.216,342.417c-14.217,83.82 -43.784,164.937 -82.404,244.458c-338.525,193.658 -1013.07,175.896 -1253.84,-5.125c-38.821,-73.913 -69.283,-155.867 -89.987,-247.221c-121.125,-61.937 -115.55,-280.012 0.804,-331.725Zm441.158,-37.871c-69.554,0 -113.958,79.175 -113.958,163.817c-0,97.237 59.304,163.079 122.671,163.079c63.37,0 117.625,-81.737 117.625,-152.904c-0,-95.092 -40.671,-173.992 -126.338,-173.992Zm531.146,0c-69.554,0 -113.958,79.175 -113.958,163.817c-0,97.237 59.308,163.079 122.675,163.079c63.366,0 117.625,-81.737 117.625,-152.904c-0,-95.092 -40.675,-173.992 -126.342,-173.992Z" style={{ "fill": "currentColor" }}></path>
        </svg>
      </AppBar>
      {/* <div class="container mx-auto max-w-7xl lg:px-8">
        <div class="h-16 flex justify-between items-center">
          <div class="flex items-center justify-between">
            <div class="relative z-20">
              <a href="/">
                <h1 class="sr-only">My Matilda App</h1>
                <span class="w-12">
                  <svg class="w-auto h-6" viewBox="0 0 8217 1309" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsSerif="http://www.serif.com/" style={{"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linejoin":"round","stroke-miterlimit":2}}>
                    <g id="matilda-text" serifId="matilda text">
                      <path d="M4703.41,1265.01c0,19.638 14.855,31.9 41.309,31.9l166.483,0c22.979,0 44.221,-8.925 44.221,-31.9l-0,-727.25l169.404,0c17.7,-0.42 26.3,-9.937 23.925,-30.425l0,-163.616c3.45,-20.946 -5.221,-34.196 -23.925,-32.988l-169.046,0l0,-265.425c0,-13.7 -9.616,-45.354 -34.158,-45.304c-64.521,0 -218.213,39.417 -218.213,79.75l0,230.979l-100.791,0c-18.904,0 -23.925,11.813 -23.925,33.263l-0,154.291c-0,28.975 8.975,39.734 23.925,39.734l100.4,-0l0.391,726.991Z" style={{"fill":"currentColor"}}></path>
                      <path d="M5728.5,1259.47c-0,19.637 14.854,31.9 41.308,31.9l166.483,-0c22.98,-0 44.221,-8.925 44.221,-31.9c0,-0 0.359,-0 0.359,-1203.75c-0,-34.613 -8.475,-40.005 -70.584,-39.875l-117.037,-0c-60.042,-0 -64.75,3.654 -64.75,39.875l-0,1203.75Z" style={{"fill": "currentColor"}}></path>
                      <path d="M4628.62,462.883l116.35,0" style={{"fill":"none"}}></path>
                      <path d="M1817.43,1272.92c5.058,19.746 16.883,26.3 35.479,22.779l175.942,-4.416c20.208,0.437 33.791,-2.992 36.133,-17.171l0.467,-568.217c-0,-269.575 388.646,-249.183 388.646,-20.904l-0,576.917c-1.096,21.829 8.283,31.625 30.304,30.341l192.754,0c18.817,-0.691 30.137,-7.325 26.625,-30.341l-0,-574.484c-0,-244.387 385.825,-244.387 385.825,0l-0,576.4c0.029,20.259 9.675,29.334 28.946,30.342l183.375,2.746c19.341,-2.159 32.771,-9.629 33.804,-33.088l-0.279,-606.308c-0,-211.854 -140.267,-377.833 -374.788,-377.833c-150.462,5.237 -252.637,67.15 -323.396,165.937c-49.404,-104.85 -141.604,-170.937 -302.958,-170.937c-129.146,-0 -235.279,50.025 -282.937,119.904c-7.725,-39.938 -7.171,-91.429 -47.85,-91.429l-143.955,-0c-35.429,-0 -41.987,7.029 -44.587,31.904l2.45,937.858Z" style={{"fill": "currentColor"}}></path>
                      <path d="M5283.33,1265.01c0,19.638 14.854,31.9 41.308,31.9l166.484,0c22.979,0 44.221,-8.925 44.221,-31.9l0.358,-868.804c-0,-13.7 -6.171,-23.975 -30.713,-23.925l-188.808,0c-25.8,0 -32.85,5.234 -32.85,23.925l0,868.804Zm121.463,-1249.19c69.125,-0 125.241,67.808 125.241,151.329c0,83.521 -56.116,151.329 -125.241,151.329c-69.125,0 -125.246,-67.808 -125.246,-151.329c-0,-83.521 56.121,-151.329 125.246,-151.329Z" style={{"fill": "currentColor"}}></path>
                      <path d="M3919.04,292.05c-637.22,-1.821 -621.304,998.488 7.975,1003.22c55.771,0.421 190.825,1.504 290.225,-90.267c0,54.088 2.517,90.338 56.609,90.338l139.558,1.587c9.308,-1.1 35.896,-8.183 35.896,-41.829l-0,-916.8c-0,-25.525 -4.3,-35.471 -50.475,-35.471c-2.013,-1.441 -124.825,0.184 -124.825,0.184c-45.508,-0 -50.525,49.233 -55.358,101.687c-73.238,-72.008 -145.938,-112.65 -299.605,-112.65Zm6.534,226.104c127.283,0 230.616,124.854 230.616,278.646c0,153.792 -103.333,278.65 -230.616,278.65c-127.279,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.338,-278.646 230.617,-278.646Z" style={{"fill": "currentColor"}}></path>
                      <path d="M7686.41,292.05c-637.22,-1.821 -621.304,998.488 7.975,1003.22c55.767,0.421 190.825,1.504 290.221,-90.267c0,54.088 2.521,90.338 56.609,90.338l139.562,1.587c9.304,-1.1 35.892,-8.183 35.892,-41.829l-0,-916.8c-0,-25.525 -4.296,-35.471 -50.471,-35.471c-2.013,-1.441 -124.825,0.184 -124.825,0.184c-45.513,-0 -50.525,49.233 -55.358,101.687c-73.238,-72.008 -145.938,-112.65 -299.605,-112.65Zm6.534,226.104c127.279,0 230.616,124.854 230.616,278.646c0,153.792 -103.337,278.65 -230.616,278.65c-127.284,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.333,-278.646 230.617,-278.646Z" style={{"fill": "currentColor"}}></path>
                      <path d="M6565.75,292.05c-637.221,-1.821 -621.304,998.488 7.975,1003.22c55.766,0.421 190.821,1.504 290.221,-90.267c-0,54.088 2.52,90.338 56.608,90.338l139.558,1.587c9.309,-1.1 35.896,-8.183 35.896,-41.829l0,-1175.33c0,-42.642 -117.729,-70.004 -146.175,-72.017c-2.008,-1.441 -29.121,0.179 -29.121,0.179c-35.141,0 -55.183,8.067 -55.183,71.838c-0,97.346 4.25,231.979 1.529,295.075c-0.587,13.687 -1.167,24.012 -1.708,29.854c-73.238,-72.008 -145.938,-112.65 -299.6,-112.65Zm6.529,226.104c127.283,0 230.621,124.854 230.621,278.646c-0,153.792 -103.338,278.65 -230.621,278.65c-127.279,0 -230.617,-124.858 -230.617,-278.65c0,-153.792 103.338,-278.646 230.617,-278.646Z" style={{"fill": "currentColor"}}></path>
                    </g>
                    <path d="M89.473,586.55c139.962,-764.354 1253.21,-773.537 1429.64,-2.804c132.838,71.308 105.221,272.775 -4.216,342.417c-14.217,83.82 -43.784,164.937 -82.404,244.458c-338.525,193.658 -1013.07,175.896 -1253.84,-5.125c-38.821,-73.913 -69.283,-155.867 -89.987,-247.221c-121.125,-61.937 -115.55,-280.012 0.804,-331.725Zm441.158,-37.871c-69.554,0 -113.958,79.175 -113.958,163.817c-0,97.237 59.304,163.079 122.671,163.079c63.37,0 117.625,-81.737 117.625,-152.904c-0,-95.092 -40.671,-173.992 -126.338,-173.992Zm531.146,0c-69.554,0 -113.958,79.175 -113.958,163.817c-0,97.237 59.308,163.079 122.675,163.079c63.366,0 117.625,-81.737 117.625,-152.904c-0,-95.092 -40.675,-173.992 -126.342,-173.992Z" style={{"fill": "currentColor"}}></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
          <div class="flex gap-5">
          </div>
        </div>
      </div> */}
      <div className="col-md-12" width="30%" style={{ marginTop: "70px" }}>
        <span style={{
          backgroundImage: `url("images/robot.jpg")`,
          transition: '0.5s ease',
          display: 'flex',
          borderRadius: '50%',
          height: '100px',
          width: '100px',
          backgroundSize: 'cover',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }} className="profile-img-card">
          <svg style={{
            width: '65px',
            height: '65px',
            color: '#fff',
          }}
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg" />
        </span>
        {/* <h1 style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "700", lineHeight: "2rem" }}>Log in</h1> */}
        <div className="card" style={{ maxWidth: "600px", marginTop: "25px" }}>
        <h1 style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "700", lineHeight: "2rem" }}>Log in</h1>
          <Form onSubmit={handleLogin} ref={form}>
            {/* <div class="mb-4"> */}
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
              <strong style={{ fontSize: ".875rem" }}>Username</strong>
            </label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
              placeholder="Enter your username"
              style={{ margin: "0rem 0", fontSize: "1rem" }}
            />
            {/* </div> */}

            {/* <div className="form-group"> */}
            <label htmlFor="password"><strong style={{ fontSize: ".875rem" }}>Password</strong></label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
              placeholder="Enter your password"
              style={{ margin: "0rem 0", fontSize: "1rem" }}
            />
            {/* </div> */}
            <br></br>
            <div className="form-group">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;

// <div className="col-md-12">
// <div className="card card-container">
//   <span style={{
//     backgroundImage: `url("images/robot.jpg")`,
//     transition: '0.5s ease',
//     display: 'flex',
//     borderRadius: '50%',
//     height: '100px',
//     width: '100px',
//     backgroundSize: 'cover',
//     alignItems: 'center',
//     justifyContent: 'center',
//     textAlign: 'center'
//   }} className="profile-img-card">
//     <svg style={{
//       width: '65px',
//       height: '65px',
//       color: '#fff',
//     }}
//       width="24"
//       height="24"
//       strokeWidth="1.5"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg" />
//   </span>

//   <Form onSubmit={handleLogin} ref={form}>
//     <Input
//       type="text"
//       className="form-control"
//       name="username"
//       value={username}
//       onChange={onChangeUsername}
//       validations={[required]}
//       placeholder="Username"
//     />
//     <Input
//       type="password"
//       className="form-control"
//       name="password"
//       value={password}
//       onChange={onChangePassword}
//       validations={[required]}
//       placeholder="Password"
//     />

//     <div className="form-group">
//       <button className="btn btn-primary btn-block" disabled={loading}>
//         {loading && (
//           <span className="spinner-border spinner-border-sm"></span>
//         )}
//         <span>Login</span>
//       </button>
//     </div>

//     {message && (
//       <div className="form-group">
//         <div className="alert alert-danger" role="alert">
//           {message}
//         </div>
//       </div>
//     )}
//     <CheckButton style={{ display: "none" }} ref={checkBtn} />
//   </Form>
// </div>
// </div>
