import React, { useRef, useCallback } from 'react';

export default function useRemoteStreams() {

    const remoteStream = useRef(null);

    const addRemoteStream = (stream) => { 
        console.log('addRemoteStream', stream);
        remoteStream.current = stream;
    }

    const removeRemoteStream = () => { 
        remoteStream.current = null;
    }

    return [remoteStream.current, addRemoteStream, removeRemoteStream];
};