import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";


class Chess extends Component {
    constructor() {
        super();
        this.state = {
            showHow: false,
        };
    }

    showHowOpen = (e) => {
        this.setState({ 'showHow': true });
    }

    showHowClose = (e) => {
        this.setState({ 'showHow': false });
    }

    render() {
        return (
            <>
                <TitleBar title={"Chess"} />
                <Button size="large" variant="outlined" onClick={this.showHowOpen}>How to play?</Button>
                <Dialog
                    open={this.state.showHow}
                    onClose={this.showHowClose}
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>How To Play Chess?</DialogTitle>
                    <DialogContent>
                        <p>
                            Hey there, budding chess master! 🤴👸 Get ready to step into the exciting world of chess, where knights ride, kings rule, and pawns march bravely. Let's learn how to play this awesome game:
                        </p>
                        <p>
                            <strong>Step 1: Meet the Chessboard</strong><br />
                            Imagine a checkered board with 64 squares. It's like a battlefield for your chess pieces. Each player has 16 pieces: 1 king, 1 queen, 2 rooks, 2 knights, 2 bishops, and 8 pawns.
                        </p>
                        <p>
                            <strong>Step 2: Set Up the Army</strong><br />
                            Place your pieces on the two rows closest to you. The back row is like a castle wall. Put your rooks in the corners, then knights, bishops, queen, and the mighty king right in the middle.
                        </p>
                        <p>
                            <strong>Step 3: Know Your Moves</strong><br />
                            Each piece moves in its own special way. Pawns go forward, but capture diagonally. Rooks move straight up, down, left, or right. Knights move in an L-shape. Bishops slide diagonally. Queens can go far in any direction, and kings move one step in any direction.
                        </p>
                        <p>
                            <strong>Step 4: Protect the King</strong><br />
                            The king is the boss. You win by putting the opponent's king in checkmate, where it can't move without being captured. But remember, you can't move your king into danger!
                        </p>
                        <p>
                            <strong>Step 5: Capture and Conquer</strong><br />
                            When your piece lands on a square with an opponent's piece, you capture it and remove it from the board. But watch out! Your pieces can be captured too.
                        </p>
                        <p>
                            <strong>Step 6: Castling Magic</strong><br />
                            A special move: Castling! It's like hiding your king behind a rook's shield. It's a great way to keep your king safe and get your rook into the action.
                        </p>
                        <p>
                            <strong>Step 7: En Passant and Pawn Promotions</strong><br />
                            Sometimes, pawns surprise you! They can move two squares forward on their first move and capture "en passant." When a pawn reaches the other side of the board, it becomes a queen, rook, knight, or bishop - you choose!
                        </p>
                        <p>
                            <strong>Step 8: Check and Checkmate</strong><br />
                            If your king is in danger, it's in check. You have to move your king, block the attack, or capture the attacking piece. If you can't do any of those, and your king is still in danger, it's checkmate - you lose!
                        </p>
                        <p>
                            <strong>Step 9: Have Fun and Learn</strong><br />
                            Chess is a game of strategy and thinking. You'll get better the more you play. Don't worry if you make mistakes - even grandmasters do! Learn from each game and have a blast.
                        </p>
                        <p>
                            Now you're ready to march your pieces into the chess battlefield and have epic battles of wits and strategy. May your moves be bold and your victories sweet! 🏰👑🎉
                        </p>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                        <Button onClick={this.showHowClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Iframe url="https://games-384805.de.r.appspot.com/" width="100%" height="1000px" onLoad={() => { console.log("IFRAME ON LOAD") }}></Iframe>
            </>
            // <Iframe url="http://192.168.163.30:8001/chess/chess.html" width="100%" height="1000px" onLoad={()=>{console.log("IFRAME ON LOAD")}}></Iframe>
        );
    }


}

export default Chess;