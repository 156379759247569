import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TitleBar from "../Common/TitleBar";
import { TextField } from "@material-ui/core";
import { Button } from "reactstrap";
import axios from "axios";
import { Middleware } from "./Middleware";

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const ManageProfile = () => {
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState();

    useEffect(() => {
      Middleware();
    }, []);

    const handleSave = () => {
        const email = localStorage.getItem("emailAccess");
        let data = JSON.stringify({
            "email": email,
            "password": password
        });

        let config = {
            method: 'put',
            url: 'https://cloud-api-389807.ue.r.appspot.com/user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setMessage("You profile has been updated!")
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Styles>
            <TitleBar title={"Profile"} />
            <h5>Hi {localStorage.getItem("userEmail")},</h5>
            <br />
            Review your details below
            <br />
            <br />
            Your username <br/>
            <TextField value={localStorage.getItem("userEmail")} disabled/> <br />
            Your email <br />
            <TextField value={localStorage.getItem("emailAccess")} disabled/> <br />
            Update your password <br />
            <TextField value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password"/> <br />
            <br />
            <i>{message}</i>
            <br/>
            <Button color="primary" onClick={handleSave}>Save Changes</Button>
        </Styles>
    )
}

export default ManageProfile;