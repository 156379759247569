
import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import { name, sendMessage, socket, vocalise, vocaliseV2, waitForSocketConnection } from "../../socket";
import { Card, CardHeader } from "reactstrap";
import { CardContent, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { useTable } from "react-table";

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Interview = () => {
  const [isStarted, setIsStarted] = useState(false);
  const [fullName, setFullName] = useState();
  const [position, setPosition] = useState('sales');
  const [screenText, setScreenText] = useState("");
  const [questions, setQuestions] = useState(["Selling is unique in that it provides you with an opportunity to prove your worth, you have got what it takes! it provides you with fast money and growth.",
    "Selling is your way to know yourself, it helps you to transform your potential into actuality, money is not a primary consideration.",
    "Selling is like any other job for you to earn your livelihood?",
    "You like to socialise with people, selling primarily provides you with this opportunity and at the same time earns your livelihood.",
    "You are generally aggressive and assertive with others?",
    "You are not aggressive with others, you are engaging, warm and assertive?",
    "You are generally more cautious and less assertive with others?",
    "You are generally friendly and warm with others, you do not believe in being assertive or non assertive.",
    "You are decisive and self reliant in your actions even though sometimes you are in self doubt and hesitant inside?",
    "You are a cool headed person who makes decisions easily without being in any self doubt?",
    "At times of indecision you let things take their own shape?",
    "You prefer to consult others before making decisions?",
    "You treat every new prospect as a challenge which must be met successfully?",
    "You determine who your best prospects are and devote your time and energy on them only?",
    "When a prospective customer is ready to buy he will let you know, chasing prospects is basically a waste of time and energy?",
    "You believe business follows from friendship, you prospect for friends who will automatically become customers?",
    "You believe the sale depends more upon one's selling skill (which involves among other things, being aggressive and pushy) rather than what you are selling?",
    "To sell the best hope is to make benefit proving presentations based upon sound product knowledge. Prices and delivery are secondary   ",
    "Salespersons are hard pressed to sell products with high prices. A lot depends on price structure and delivery periods rather than the selling skill.",
    "In this business the customer is really buying you. Hence the art of making everyone your friend is more important than being aggessive and pushy.",
    "Customers are of all types but most of them tend to resist buying. My job is to sell our product to them and that is what matters in the end.",
    "Customers buy only when they are convinced that your product can satisfy their needs and you strictly pursue this policy.",
    "You do not sell to customers who tend to resist buying. When they are ready they will let you know. Things like persuasion and likeability do not really work.",
    "Customers do not resist buying as such. They buy from salespersons they like. Buying is simply one way in which people display their natural tendency to be friendly.",
    "In sales the law of the jungle prevails. It is either you or the competitor. You relish defeating your competitors, and fight them hard using every available weapon.",
    "The best way to outwork and outsell competitors is by keeping abreast of competitive activity and having sound product knowledge of your product.",
    "You may not be aggressive but when it comes to competition you are the opposite. You spend a good deal of your time explaining to the customer why he should not buy from the competitor.",
    "You do not believe in being aggressive towards your competitors. Competitors are people like you and there is room for everybody.",
    "I hate to lose or fail. I just don't seem to  be able to digest failure.   ",
    "You take failures in stride. People are important up to a point. Success and progress is the ultimate goal.",
    "You do your job. Failures mostly happen due to product and pricing policies of the company.",
    "It is more important to be associated with people than thinking of success or failure.",
    "You do not like interference in your operations. Boss wants results, he will get them.",
    "You welcome criticism from your boss. You do not support him or her when you feel he or she is wrong and do not hesitate to give your point of view.",
    "You play it safe with the boss and are careful and cautious in dealing with him or her.",
    "You believe that the boss has a hard job and needs strong support from everybody even when he or she is sometimes wrong. You welcome his or her close association with your activities.",
    "You take fellow salespersons as competitors in a competitive world.  ",
    "You do not hesitate to ask your peers for help and at the same time like to help them without going out of your way.",
    "You do not prefer to significantly interact with your fellow sales persons, you want to do your job and that is it.",
    "You do not think of your peers as competitors. You take them as your friends whom you will like to help in any respect even if at times you have to go out of your way.",
    "You believe rules and regulations sometimes tend to get in the way of initiative. In some situations one is forced to deviate a little in interest of making sales.",
    "You think  some  rules can  hamper sales efforts and fight hard with management to bring about changes.",
    "Whether a particular sale is made or not, your job is to go strictly as per the rules and regulations of the company and that is it.",
    "You accept rules and regulations without complaint. But you believe people are more important than rules so you may overspend on customers in order to make friends with them.",
    "People are mostly selfish and out to get what they can. One has to be tough and fight hard to achieve results.",
    "People are both self interested and well meaning.",
    "People are mostly selfish, untrustworthy and exploitative. Rather than being tough, one has to be careful in dealing with them.",
    "People are mostly sincere, decent, dependable and trustworthy.",
    "Your idea of training is to send the trainee into the field to do a days work. If he or she has got what it takes he or she will get by. If not, he or she will be better off in some other field.",
    "You wholeheartedly support training programs. You accept criticism readily and effect changes in your attitude.",
    "You believe that although one may not be setting the world on fire, but as long as one is doing reasonably alright, why tamper with one's attitudes.",
    "Training sessions have seldom anything useful to say. Like, though one hears the familiar refrain Be aggressive in training, you still believe that selling does not have anything to do with aggressiveness.",
    "You are seldom deeply satisfied. You want to move up the ladder quickly. You constantly seek money and power.",
    "You get deep satisfaction from the job as long as you are growing. You tend to change the company if you are unable to progress and the organisational climate is not congenial to your strategy.",
    "You do not like to change jobs frequently. Even if you are not deeply satisfied, you retain a job because it provides you stability and a predictable environment.",
    "You are generally contented. Although you are not eager to climb the managerial ladder, you like the idea of being promoted.",
    "You dislike writing reports and spend minimum time on them. This time could be more effectively spent in developing new clients. Paper work makes you feel like a clerk.",
    "Your spend great deal of time in writing reports as you feel they are important. They are detailed and go beyond the information required.",
    "Your reports are  always complete and up to date. You believe in passing only information which is asked for, nothing more and nothing less.",
    "You believe people are more important than profits and policies. You like human aspects of this job. Paper work and reports drive you crazy.",
    "The salesperson is the real heart and soul of the business. All other departments are basically auxiliaries.",
    "You feel all departments are equally important.",
    "You feel the production department are many times responsible for sales failures. Accountants and administrators are the watchdogs of the company.",
    "All departments, particularly production go through a hard time. One must be sympathetic towards their problems.",
    "You feel the best line of defense is good offense.",
    "You are open minded and deal with people on a one to one basis.",
    "You feel the best line of offense is good deefense.",
    "You feel kindness is repaid by kindness.",
    "You like to gain attention of others to the extent of controlling them.",
    "You do not like to control others as such. You further your own ends by generating respect for your ideas and at the same time displaying genuine respect for others ideas.",
    "You do not like to gain attention of others. You prefer to be left alone to do your job.",
    "You do not believe in this rat race where people fight the survival game. You believe there is room for everybody.",
    "You believe in sales ends are more important than the means. Thus one has to be manipulative at times.",
    "You welcome criticism and accept your weaknesses.",
    "You prefer a well ordered, predictable and stable existence.",
    "You always try to be agreeable with others."]);
  const [questionIdx, setQuestionIdx] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [beginQuestion, setBeginQuestion] = useState(false);
  const [isViewReports, setIsViewReports] = useState(false);
  const [isPositionSet, setIsPositionSet] = useState(false);
  const [isAlready, setIsAlreaydy] = useState(false)

  // For initial load
  useEffect(() => {
    // waitForSocketConnection(socket, () => { vocalise("Welcome to Interview and Training session with Matilda") });
    setTotalQuestions(questions.length);
    // let config = {
    //   method: 'get',
    //   url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log('DATA FROM DB', JSON.stringify(response.data), response.data.data.length);
    //     setTotalQuestions(response.data.data.length);
    //     setQuestions(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    socket.onmessage = (message) => {
      console.log("MESSAGE ", message.data)
      var obj = JSON.parse(message.data);
      if ((message.data) && (message.data.toString().includes("interview-with-position"))) {
        checkFullName();
      } else if ((message.data) && (message.data.toString().includes("interviewFullNameStatus"))) {
        const interviewNameObj = obj["interview-name"]
        if (interviewNameObj && interviewNameObj.status === "ok") {
          setIsPositionSet(true);
          setFullName(interviewNameObj.fullname);
          if (!isPositionSet) {
            getPosition();
          }
        } else {
          setTimeout(checkFullName, 5000);
        }
      } else if (message.data && message.data.toString().includes("interview-position-name")) {
        const interviewPositionObj = obj["interview-position-name"]
        if (interviewPositionObj && interviewPositionObj.status === "ok") {
          console.log("JOanna is setting answer ", interviewPositionObj.position)
          setPosition(interviewPositionObj.position);
          setTimeout(startQuestion, 23000);
        } else {
          setTimeout(checkPosition, 5000);
        }
      } else if (message.data && message.data.toString().includes("interview-position")) {
        if (position === undefined || !position) {
          checkPosition()
        }
        setTimeout(startQuestion, 23000);
      } else if (message.data && message.data.toString().includes("interview-questions")) {
        const interviewQuestions = obj["interview-questions"];
        if (interviewQuestions && interviewQuestions === "done") {
          if (!isAlready) {
            setIsAlreaydy(true)
            isNextQuestion(0);
          }
        }
      } else if (message.data && message.data.toString().includes("interview-next")) {
        if (message.data.toString().includes("false")) {
          setTimeout(isNextQuestion, 5000);
        } else if(message.data.toString().includes("true")) {
          console.log("NEXT QUESTION PLEASE")
          let idxxx = localStorage.getItem("qIdx");
          setTimeout(startQuestionByIndex, 5000);
          localStorage.setItem("qIdx", parseInt(idxxx) + 1);
          setScreenText(questions[parseInt(idxxx)]["question"]);
        }
      }
    };
  }, []);

  // Functions

  const onStartDetection = () => {
    console.log("onStartDetection");
    var api = `{"api":"onStartDetection", "action":"get", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  }

  const onStopDetection = () => {
    console.log("onStopDetection");
    var api = `{"api":"onStopDetection", "action":"get", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  }

  const onHandleStartInterview = () => {
    // onStartDetection();onStopDetection
    setIsStarted(true);
    getFullName();
  }

  const getFullName = () => {
    var api = `{"api":"interview-with-position", "action":"get","name": "${name}"}`
    waitForSocketConnection(socket, () => { sendMessage(api) })
    // let config = {
    //   method: 'get',
    //   url: 'http://192.168.163.30:8000/interview-with-position',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   }).finally(() => {
    //     checkFullName();
    //   });
  }

  const checkFullName = () => {
    var api = `{"api":"interview-name", "action":"get","name": "${name}"}`
    waitForSocketConnection(socket, () => { sendMessage(api) })
    // let config = {
    //   method: 'get',
    //   url: 'http://192.168.163.30:8000/interview-name',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log('FULLNAME', JSON.stringify(response.data));
    //     if (response.data.status === "notok") {
    //       setTimeout(checkFullName, 5000);
    //     } else {
    //       setFullName(response.data.fullname);
    //       getPosition();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  const getPosition = () => {
    var api = `{"api":"interview-position", "action":"get","name": "${name}"}`
    waitForSocketConnection(socket, () => { sendMessage(api) })
    // let config = {
    //   method: 'get',
    //   url: 'http://192.168.163.30:8000/interview-position',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //     checkPosition();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   }).finally(() => {
    //   });
  }

  const checkPosition = () => {
    var api = `{"api":"interview-position-name", "action":"get","name": "${name}"}`
    waitForSocketConnection(socket, () => { sendMessage(api) })
    // let config = {
    //   method: 'get',
    //   url: 'http://192.168.163.30:8000/interview-position-name',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log('position', JSON.stringify(response.data));
    //     if (response.data.status === "notok") {
    //       setTimeout(checkPosition, 5000);
    //     } else {
    //       setPosition(response.data.position);
    //       setTimeout(startQuestion, 23000);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  const startQuestion = () => {
    console.log('Joanna question ', questions.length, questionIdx)
    localStorage.setItem("qIdx", 1);
    setScreenText(questions[questionIdx]["question"]);
    startQuestionByIndex(questionIdx);
    setBeginQuestion(true);
    // let config = {
    //   method: 'get',
    //   url: 'https://cloud-api-389807.ue.r.appspot.com/cognitive-question?position=' + position.replace(/\s+/g, '-'),
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log('DATA FROM DB', JSON.stringify(response.data), response.data.data.length);
    //     setTotalQuestions(response.data.data.length);
    //     setQuestions(response.data.data);
    //     localStorage.setItem("qIdx", 1);
    //     setScreenText(response.data.data[questionIdx]["question"]);
    //     startQuestionByIndex(questionIdx);
    //     setBeginQuestion(true);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  const startQuestionByIndex = (idx) => {
    let idxxx = idx || localStorage.getItem("qIdx");
    console.log("QUESTION ID ", idxxx)
    if (idxxx < questions.length) {
      console.log("STARING QUESTION")
      var api = `{"api":"interview-questions", "action":"get", "name": "${name}", "idx": "${idxxx.toString()}"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) });
      // let config = {
      //   method: 'get',
      //   url: 'http://192.168.163.30:8000/interview-questions?idx=' + idx.toString() + '&position=sales',
      //   headers: {}
      // };

      // axios.request(config)
      //   .then((response) => {
      //     console.log(JSON.stringify(response.data));
      //     isNextQuestion(idx);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      stopInterview();
      setScreenText("End of Interview. Thank you!");
      onStopDetection();
    }
  }

  const isNextQuestion = (idx) => {
    console.log('JOANNA IS NEXT ', idx)
    let idxxx = idx || localStorage.getItem("qIdx");
    var api = `{"api":"interview-next", "action":"get", "name": "${name}", "idx": "${idxxx.toString()}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    // let config = {
    //   method: 'get',
    //   url: 'http://192.168.163.30:8000/interview-next',
    //   headers: {}
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log('NEXT', JSON.stringify(response.data));
    //     if (JSON.stringify(response.data).includes("false")) {
    //       setTimeout(isNextQuestion, 3000);
    //     } else {
    //       console.log("NEXT QUESTION PLEASE", questions)
    //       let idxxx = localStorage.getItem("qIdx");
    //       startQuestionByIndex(idxxx);
    //       localStorage.setItem("qIdx", parseInt(idxxx) + 1);
    //       setScreenText(questions[parseInt(idxxx)]["question"]);
    //     }

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  const stopInterview = () => {
    let config = {
      method: 'get',
      url: 'http://192.168.163.30:8000/interview-stop',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        onStopDetection();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleViewReports = (e) => {
    let config = {
      method: 'get',
      url: 'http://192.168.163.30:8000/srbs/reports',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        setReports(response.data.srbs_report_items);
        setIsViewReports(true);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fullname',
        accessor: 'fullname',

      },
      {
        Header: 'Date',
        accessor: 'dateTime',

      },
      {
        Header: 'Generated?',
        accessor: 'is_done',

      },
      {
        Header: 'Actions',
        accessor: 'file',
        Cell: ({ cell }) => (
          <div>
            <Button size="small" variant="outlined" value={cell.row.values.file} onClick={handleDownload}>View</Button>
            <Button size="small" variant="outlined" value={cell.row.values.file} onClick={handleGenerate}>Generate</Button>
          </div>
        )

      },


    ],
    []
  )

  const [reports, setReports] = useState([])

  const handleGenerate = (e) => {
    console.log("Generate ", e.currentTarget.value);
    let config = {
      method: 'get',
      url: 'http://192.168.163.30:8000/srbs/generate?filename=' + e.currentTarget.value,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleDownload = async (e) => {
    const filename = e.currentTarget.value.split(".")[0]
    console.log("Download ", filename);
    try {
      const response = await fetch('http://192.168.163.30:8000/srbs/download?filename=' + filename);  // Replace with the actual API endpoint
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file', error);
    }
  }
  return (
    <Styles>
      <TitleBar title="Interview and Training" parentNode={"/technology-tools"} />
      {
        !isStarted && !isViewReports && (
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src="images/robot.jpg" className="w-100" />
            </div>
            <div className="col-md-6">
              <h2>Welcome to Interview and Training session with Matilda</h2>
              <Button color="primary" variant="outlined" onClick={onHandleStartInterview}>Start Interview</Button>
              <Button color="primary" variant="outlined" onClick={handleViewReports}>View Reports</Button>
            </div>
          </div>
        )
      }
      {
        isStarted && !beginQuestion && !isViewReports && (
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src="images/robot.jpg" className="w-100" />
            </div>
            <div className="col-md-6">
              <Card>
                <CardHeader>
                  Personal Information
                  <br />
                  <FormControl>
                    <Typography>Fullname</Typography>
                    <TextField
                      defaultValue={fullName}
                      inputProps={{ maxLength: 200, }}
                      onChange={e => { setFullName(e.target.value); }}
                      value={fullName}
                      fullWidth
                    />
                  </FormControl>
                </CardHeader>
                <CardHeader>
                  Positions
                  <FormControl>
                    <Typography>Select your position</Typography>
                    <RadioGroup
                      row
                      value={position} // Set the value prop to the selected position
                      onChange={handlePositionChange} // Handle changes with the handlePositionChange function
                    >
                      <FormControlLabel value="sales" control={<Radio />} label="Sales" />
                      <FormControlLabel value="sales support" control={<Radio />} label="Sales Support" />
                      <FormControlLabel value="customer service" control={<Radio />} label="Customer Service" />
                    </RadioGroup>
                  </FormControl>
                </CardHeader>
                <Button color="primary" variant="outlined" onClick={stopInterview}>Stop Interview</Button>
              </Card>
            </div>
          </div>
        )
      }
      {
        beginQuestion && !isViewReports && (
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src="images/robot.jpg" className="w-100" />
            </div>
            <div className="col-md-6">
              <Card>
                <CardHeader>
                  <h5>{screenText}</h5>
                </CardHeader>
                <Button color="primary" variant="outlined" onClick={stopInterview}>Stop Interview</Button>
              </Card>
            </div>
          </div>
        )
      }
      {
        isViewReports && (
          <div className="row align-items-start">
            <div className="col-md-4">
              <img src="images/robot.jpg" className="w-100" />
            </div>
            <div className="col-md-8">
              <Card>
                <CardHeader>
                  <h5>Reports</h5>
                </CardHeader>
                <CardContent>
                  <Table columns={columns} data={reports} />
                </CardContent>
              </Card>
            </div>
          </div>
        )
      }
    </Styles>
  );
}

export default Interview;