import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";


class Scrabble extends Component {
    constructor() {
        super();
        this.state = {
            showHow: false,
        };
    }

    showHowOpen = (e) => {
        this.setState({ 'showHow': true });
    }

    showHowClose = (e) => {
        this.setState({ 'showHow': false });
    }

    render() {
        return (
            <>
                <TitleBar title={"Scrabble"} />
                <Button onClick={this.showHowOpen}>How to play</Button>
                <Dialog
                    open={this.state.showHow}
                    onClose={this.showHowClose}
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>How To Play Sudoku?</DialogTitle>
                    <DialogContent>
                        <p>
                            Hey there, little word wizard! 🧙‍♂️ Are you ready to dive into the world of Scrabble, where words come to life on the game board? Let's have a blast with words! Here's how you can play:
                        </p>
                        <p>
                            <strong>Step 1: Grab Your Tiles</strong><br />
                            In Scrabble, you have a bunch of letter tiles. Each tile has a letter on it, and some tiles have special points. The letters you have are like your magical ingredients for making words.
                        </p>
                        <p>
                            <strong>Step 2: Create the Game Board</strong><br />
                            Open up the Scrabble board. It's like a crossword puzzle waiting for your words. Each square has a special point value. Some are even bonus squares that give extra points!
                        </p>
                        <p>
                            <strong>Step 3: Start with a Word</strong><br />
                            Put a word on the board using your tiles. It can be any word you want, just as long as it's in the dictionary. Put it across or down. The first word must cover the center square, like a magical starting point.
                        </p>
                        <p>
                            <strong>Step 4: Connect and Build</strong><br />
                            Now it's like a word puzzle! Add words to the board, connecting them to the words already there. Use your tiles to create new words and expand the crossword.
                        </p>
                        <p>
                            <strong>Step 5: Count the Points</strong><br />
                            Each letter has a point value. Add up the points for the letters in your word. Don't forget to count the bonus points from special squares!
                        </p>
                        <p>
                            <strong>Step 6: Swap or Trade</strong><br />
                            If you ever get stuck with tricky letters, you can swap some of your tiles for new ones. But watch out! You'll lose a turn if you do. You can also trade in a turn to exchange tiles.
                        </p>
                        <p>
                            <strong>Step 7: Challenge and Dictionary</strong><br />
                            If someone puts down a word you're not sure about, you can challenge it! Use a dictionary to check if the word is real. If it's not, the player loses a turn.
                        </p>
                        <p>
                            <strong>Step 8: Keep Playing</strong><br />
                            Play goes back and forth. Each time, you make words, score points, and plan your moves. Use the letters on the board to make new words - that's where the fun and strategy come in!
                        </p>
                        <p>
                            <strong>Step 9: The Game Ends</strong><br />
                            Scrabble ends when all the tiles are used and one player uses up all their tiles. Everyone counts the points left in their hand, and those points are subtracted from their total.
                        </p>
                        <p>
                            <strong>Step 10: Count Your Victories</strong><br />
                            Add up all the points from the words you made. The player with the most points wins! It's like a wordy battle where the best wordsmith emerges victorious.
                        </p>
                        <p>
                            Now you're all set to spell out your way to victory in the world of Scrabble. Play with friends and family, create amazing words, and let your vocabulary shine! 🌟📚🎉
                        </p>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                        <Button onClick={this.showHowClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Iframe url="https://scrabble-387707.ue.r.appspot.com/" width="100%" height="1000px" onLoad={() => { console.log("IFRAME ON LOAD") }}></Iframe>
            </>
            // <iframe frameborder="0" scrolling="no" src="https://mczak.com/code/Scrabble/suframe/" style="border:0px solid #eee; overflow:hidden; width:400px; height:500px;" width="400"></iframe>
        );
    }


}

export default Scrabble;