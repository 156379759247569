import React, { Component } from 'react';
import axios from "axios";
import './css/ImageQuiz.css';
import './css/bootstrap-min.css';

import ExerciseMain from './ExerciseMain';
import Hero from './Hero';
import ExerciseList from './exerciselist';
// import Footer from './Footer';
import ImageQuiz from './ImageQuiz';
import PlayAgain from './PlayAgain';
import { vocalise, socket, name, waitForSocketConnection, sendMessage } from '../../socket';
// import {getLearningPhoto} from '../../services/auth.service';
import AuthService from "../../services/auth.service";
var stop = false;


const getLearningPhoto = (quizid, id) => {
    return AuthService.getExercisePhoto(quizid, id);
};

const getDataApi = (_self) => {
    const getRandomItem = (arr) => {
        console.log('rand item ', _self.state.qIndex)
        let item = arr[_self.state.qIndex];


        return item;
    };

    let index = _self.state.qIndex;
    if (index === _self.state.questions.length) {
        _self.setState({ endOfQuiz: true });

    }
    else {
        console.log('rand ', _self.state.qIndex)
        var qObj = getRandomItem(_self.state.questions);



        getQuestionPhoto(_self, _self.state.quizid, qObj.image);

        _self.setState({
            question: qObj.text,
            correctAnswer: '',
            setOfAnswer: [],
            showContinueBtn: false,
            qIndex: index + 1
        })
        console.log('rand ', qObj.text)
        vocalise(qObj.text);
        // var data = { "text": qObj.text };
        // var message = `{"action":"vocalisationCustom","event":${JSON.stringify(data)}, "name":"${name}" }`;
        // waitForSocketConnection(socket, () => { sendMessage(message) });
    }


}

const getQuizContent = (_self, quizid) => {
    console.log('joanna learing item')
    let event = JSON.stringify({ "filename": quizid })
    var api = `{"action":"getExerciseItem","name":"${name}", "event":"${quizid}"}`;
    socket.send(api);
    //   AuthService.getExerciseItem(quizid)
    //     .then( (response) => {
    //       let listOfItem = [];
    //       console.log(response.data);

    //       listOfItem = response.data.learning;
    //       console.log(listOfItem);

    //       _self.setState({
    //         questions:listOfItem
    //       })

    //       getDataApi(_self);
    //   })
}

const getQuestionPhoto = (_self, quizid, photoid) => {

    if (photoid === "") {
        _self.setState({
            hasPhoto: false
        });


    } else {
        var api = `{"action":"getExercisePhoto","name":"${name}", "event":"${quizid}", "photo":"${photoid}"}`;
        socket.send(api);
        // getLearningPhoto(quizid, photoid).then(
        //     (response) => {
        //         _self.setState({
        //             imageUrl: response.data,
        //             hasPhoto: true
        //         })

        //     },
        //     (error) => {
        //         const _content =
        //             (error.response && error.response.data) ||
        //             error.message ||
        //             error.toString();
        //         console.log(_content);
        //     }
        // );
    }


}

class Exercise extends Component {
    constructor(props) {
        super(props);

        this.enableContinueBtn = this.enableContinueBtn.bind(this);
        this.newPuzzle = this.newPuzzle.bind(this);
        this.showResult = this.showResult.bind(this);
    }

    static initialState = () => ({
        imageUrl: '',

        question: '',
        correctAnswer: '',
        setOfAnswer: [],
        savedResult: [],
        showContinueBtn: false,
        timerLimit: 120,
        isTimerReached: false,
        oneQuesAttemptTime: 120,
        questions: [],
        qIndex: 0,
        endOfQuiz: false,
        hasPhoto: false,
        stop: false,
        vocDone: 0,

    })

    state = Exercise.initialState();

    showResult = () => {
        this.setState({
            isTimerReached: true,
            showContinueBtn: false
        })
    }

    resetQuiz = () => {
        var _self = this;
        this.setState(Exercise.initialState());
        getQuizContent(_self, this.props.quizid);
    }

    handler = (val) => {

    }


    newPuzzle = () => {
        var _self = this;
        // const allOptionsObj = document.querySelectorAll('.item-options .option');
        // for (var obj of allOptionsObj) {
        //     obj.classList.remove('red', 'green');
        //     obj.disabled = false;
        // }
        this.setState({
            imageUrl: ''
        })
        getDataApi(_self);
    }

    enableContinueBtn = (isAnswerCorrect) => {
        this.setState((prevState) => ({
            showContinueBtn: true,
            savedResult: prevState.savedResult.concat(isAnswerCorrect)
        }))
    }

    componentDidMount() {
        var _self = this;
        const quizid = this.props.quizid;

        this.setState({
            quizid: quizid

        })
        getQuizContent(_self, quizid);

        socket.onmessage = (message) => {
            var str = message.data.substring(0, message.data.length - 1);

            if (str === 'vocalise_done' && !this.state.stop && !this.state.endOfQuiz) {
                console.log('state ', this.state)
                if (this.state.vocDone % 2 == 1) {
                    setTimeout(function () { //Start the timer
                        this.newPuzzle();
                    }.bind(this), 8000)
                    this.setState({vocDone: this.state.vocDone+1})
                } else {
                    this.setState({vocDone: this.state.vocDone+1})
                }
            } else if ((message.data) && (message.data.toString().includes("learning"))) {
                console.log('joanna exercise item');
                let listOfItem = [];
                var obj = JSON.parse(message.data);
                var flag = obj.learning.map((item, i) => {
                    listOfItem.push(item)
                });

                Promise.all(flag).then(() => {
                    this.setState({
                        questions: listOfItem
                    })
                }).then(() => {
                    getDataApi(this)
                });
            } else if ((message.data) && (message.data.toString().includes("base64data"))) {
                console.log('base64data', message.data);
                var obj = JSON.parse(message.data);
                this.setState({
                    imageUrl: obj.base64data,
                    hasPhoto: true
                })
            }

        }


    }

    componentWillUnmount() {
        socket.onmessage = null;
    }

    render() {
        let { imageUrl, question, correctAnswer, setOfAnswer, showContinueBtn, timerLimit, hasPhoto } = this.state;
        if (this.state.endOfQuiz) {
            var hasQuiz = false;
            AuthService.getQuizList().then(
                (response) => {
                    response.data.quiz.map((item, i) => {

                        if (item == this.state.quizid + '.quiz') {
                            this.setState({ hasQuiz: true });
                            //alert(item + '=' + this.state.quizid+'.quiz');
                        }
                    });

                },
                (error) => {

                });

            if (this.state.hasQuiz) {
                //  alert("start quiz");
                return <ImageQuiz quizid={this.state.quizid + '.quiz'} handler={this.handler} />
            }
            else {
                // alert("return quiz list");
                return <ExerciseList />
            }

        } else
            return (
                <div className="container-fluid">
                    <Hero question={question} />

                    <ExerciseMain
                        imageUrl={imageUrl}
                        correctAnswer={correctAnswer}
                        setOfAnswer={setOfAnswer}
                        showContinueBtn={showContinueBtn}
                        enableContinueBtn={this.enableContinueBtn}
                        timerLimit={timerLimit}
                        showResult={this.showResult}
                        hasPhoto={hasPhoto}
                    />




                    {/* <Footer /> */}
                </div>
            )
    }
}

export default Exercise;
