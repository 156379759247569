import React, { useEffect, useState } from "react";
import { useTable } from 'react-table';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import styled from 'styled-components';
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import TranslationService from "../../services/translation.service";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from 'react-chartjs-2';
import { Legend, Pie, PieChart, Tooltip } from "recharts";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const ServicePreference = () => {
    // Variables
    const [songs, setSongs] = useState([]);
    const [gotSongs, setGotSongs] = useState(false);
    const [apiURL, setApiURL] = useState("http://127.0.0.1:8080/")
    const [value, setValue] = useState("2021-05-04");
    const [contentlist, setContentlist] = useState([]);
    const [content, setContent] = useState("");
    const [dataValue, setDataValue] = useState([[1, 1], [2, 2]]);
    const data01 = [
        {
            "name": "Group A",
            "value": 400,
            "fill": "#2411b2"
        },
        {
            "name": "Group B",
            "value": 300
        },
        {
            "name": "Group C",
            "value": 300
        },
        {
            "name": "Group D",
            "value": 200
        },
        {
            "name": "Group E",
            "value": 278
        },
        {
            "name": "Group F",
            "value": 189
        }
    ];

    // Effects
    useEffect(() => {
        if (!gotSongs) {
            var api = `{"api":"AnaUserList","name": "${name}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
            setGotSongs(true);
        }
        socket.onmessage = (message) => {
            console.log("Message ", message);
            if ((message.data) && (message.data.toString().includes("userlist"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (obj.data_type == "userlist") {
                        setGotSongs(true);
                        setContentlist(transformData(obj.data));
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
            if ((message.data) && (message.data.toString().includes("servicepreference"))) {
                var str = message.data.substring(0, message.data.length - 1);
                console.log(str);
                try {
                    var obj = JSON.parse(message.data);
                    if (obj.data_type == "servicepreference") {
                        setGotSongs(true);
                        setDataValue(obj.data);
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
        };

    }, [contentlist]);

    const transformData = (obj) => {
        let finalObject = []
        for (let i = 0; i < obj.length; i++) {
            obj[i] = computeTitle(obj[i])
            finalObject.push(obj[i])
        }
        return obj
    }

    // Memo
    let data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: dataValue
            }
        ],
        [dataValue]
    )

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ],
        []
    )

    // Functions
    const handleContentChange = (e) => {
        setContent(e.target.value);
        var api = `{"api":"AnaServicePreference","name": "${name}","user": "${e.target.value}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    // Build Page
    return (
        <Styles>
            <TitleBar title={computeTitle("Analytics: Service Preference")} parentNode={"/analytics"}/>
            <FormControl fullWidth>
                <InputLabel htmlFor="content-label">Select User</InputLabel>
                <Select defaultValue={content} value={content} id="select-content" onChange={handleContentChange} fullWidth>
                    {contentlist.map((categoria, i) => (
                        <MenuItem key={1} value={categoria}>{categoria}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div
                style={{
                    width: '100%',
                    height: '400px'
                }}
            >
                <PieChart width={1000} height={500}>
                    <Pie data={dataValue} dataKey="value" nameKey="name"innerRadius={150} outerRadius={200} label />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                </PieChart>
            </div>
        </Styles>
    )
}

export default ServicePreference;