import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Typography } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";
import { Middleware } from "./Middleware";


const styles = (theme) => ({});

class Entertainment extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M9 18V5l12-2v13" stroke="currentColor" stroke-linejoin="round"></path>
                        <circle cx="6" cy="18" r="3" stroke="currentColor" stroke-linejoin="round"></circle>
                        <circle cx="18" cy="16" r="3" stroke="currentColor" stroke-linejoin="round"></circle>
                    `,
                    background: 'linear-gradient(0deg, #a8090a, #d33232)',
                    title: 'Music',
                    slug: '/music',
                    locations: ['home'],
                },
                {
                    svg: `
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-camera-reels" viewBox="0 0 16 16"> <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM1 3a2 2 0 1 0 4 0 2 2 0 0 0-4 0z"/> <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7zm6 8.73V7.27l-3.5 1.555v4.35l3.5 1.556zM1 8v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z"/> <path d="M9 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM7 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/> </svg>
                    `,
                    background: 'linear-gradient(0deg, #550678, #7709a8)',
                    title: 'Videos & Movies',
                    slug: '/videos',
                    locations: ['home'],
                },
                {
                    svg: `
                        <path d="M4 19V5C4 3.89543 4.89543 3 6 3H19.4C19.7314 3 20 3.26863 20 3.6V16.7143" stroke="currentColor"  stroke-linecap="round"/>
                        <path d="M6 17L20 17" stroke="currentColor"  stroke-linecap="round"/>
                        <path d="M6 21L20 21" stroke="currentColor"  stroke-linecap="round"/>
                        <path d="M6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 7L15 7" stroke="currentColor"  stroke-linecap="round"/>
                    `,
                    background: 'linear-gradient(0deg, #741d29, #9c4d58)',
                    title: 'Books',
                    slug: '/book',
                    locations: ['home'],
                },
                {
                    svg: `
                        <path d="M12 14.5C15 19 21 18.9706 21 14C21 10 17 7 12 2C7 7 3 10 3 14C3 18.9706 9 19 12 14.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.4706 15.4926L8.47059 21.1176C8.25743 21.5173 8.54705 22 9 22H15C15.453 22 15.7426 21.5173 15.5294 21.1176L12.5294 15.4926C12.3035 15.0691 11.6965 15.0691 11.4706 15.4926Z" stroke="currentColor" stroke-linecap="round"/>
                    `,
                    background: 'linear-gradient(0deg, #9d8f12, #dfcc22)',
                    title: 'Games',
                    slug: '/new-games',
                    locations: ['home'],
                },
                {
                    img: `
                        <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="25px" viewBox="0 0 24 24" fill="#fff">
                        <path fill="#fff" fill-rule="evenodd" d="M12 3a2 2 0 0 0-1 3.732V8H8c-3.2 0-4 2.667-4 4v7c0 .667.4 2 2 2h1v-4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4h1c1.6 0 2-1.333 2-2v-7c0-3.2-2.667-4-4-4h-3V6.732A2 2 0 0 0 12 3zm3 18v-3h-2v3h2zm-4 0v-3H9v3h2zm10-3v-5c.667 0 2 .4 2 2v1c0 .667-.4 2-2 2zM3 13v5c-1.6 0-2-1.333-2-2v-1c0-1.6 1.333-2 2-2zm6-1a1 1 0 1 0 0 2h.001a1 1 0 1 0 0-2H9zm5 1a1 1 0 0 1 1-1h.001a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1z" clip-rule="evenodd"/>
                        </svg>
                    `,
                    background: 'linear-gradient(0deg, #1b6c72, #2498a1)',
                    title: 'Talk With Matilda',
                    slug: '/talk-with-matilda',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
            allowedAcccess: []
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
    }

    componentDidMount() {
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        Middleware();

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access});
            })
            .catch((error) => {
                console.log(error);
            });
     }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Entertainment")} />
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        this.state.allowedAcccess.includes(app.title) &&                         <ButtonBase
                        onClick={() => this.appClick(app.title, app.slug)}
                        focusRipple
                        key={this.computeTitle(app.title)}
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1rem',
                            color: '#fff',
                            justifyContent: 'flex-start',
                        }}
                        focusVisibleClassName={styles.focusVisible}
                    >
                        {
                            app.img ?
                                <span style={{
                                    backgroundImage: `url("images/robot.jpg")`,
                                    transition: '0.5s ease',
                                    display: 'flex',
                                    borderRadius: '50%',
                                    height: '100px',
                                    width: '100px',
                                    backgroundSize: 'cover',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <svg style={{
                                        width: '65px',
                                        height: '65px',
                                        color: '#fff',
                                    }}
                                        width="24"
                                        height="24"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        dangerouslySetInnerHTML={{ __html: app.svg }} />
                                </span>
                                :
                                <span style={{
                                    background: app.background,
                                    transition: '0.5s ease',
                                    display: 'flex',
                                    borderRadius: '50%',
                                    height: '100px',
                                    width: '100px',
                                    backgroundSize: 'cover',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <svg style={{
                                        width: '65px',
                                        height: '65px',
                                        color: '#fff',
                                    }}
                                        width="24"
                                        height="24"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        dangerouslySetInnerHTML={{ __html: app.svg }} />
                                </span>
                        }
                        <span style={{
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '1.1rem'
                        }}>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                style={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem'
                                }}
                            >
                                {this.computeTitle(app.title)}
                            </Typography>
                        </span>
                    </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(Entertainment);