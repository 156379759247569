import axios
    from "axios";
import "./translateData.json"
function translateString(wordToTranslate, language) {
    let fromLocalStorage = localStorage.getItem(wordToTranslate);
    let srcMapping = {
        "Mandarin": "zh-CN",
        "Japanese": "ja",
        "Greek": "el",
        "Italian": "it",
        "Russian": 'ru',
        'Serbian': 'sr-RS',
        "Cantonese": "zh-TW",
        "Korean": 'ko',
        "Arabic": 'ar',
        "Hindi": 'hi-IN',
        "Polish": 'pl',
        'Sinhala': 'si',
        'Filipino': 'tl',
        "Macedonian": 'mk'
    }

    if (fromLocalStorage === null) {
        fromLocalStorage = {}
        fromLocalStorage[wordToTranslate] = {}
        saveWord(fromLocalStorage, wordToTranslate, language, srcMapping)
    } else {
        fromLocalStorage = JSON.parse(fromLocalStorage);
        if (fromLocalStorage[wordToTranslate] == undefined) {
            fromLocalStorage[wordToTranslate] = {}
            saveWord(fromLocalStorage, wordToTranslate, language, srcMapping)
        } else if (fromLocalStorage[wordToTranslate][language] == undefined) {
            saveWord(fromLocalStorage, wordToTranslate, language, srcMapping)
        }
    }
    return fromLocalStorage[wordToTranslate][language];
}

const saveWord = (fromLocalStorage, wordToTranslate, language, srcMapping) => {
    const baseUrl = 'https://translation.googleapis.com/language/translate/v2?key='
    const apiKey = 'AIzaSyDorIA2OOmZLAzNRIKEAVeO2Gv7TQzfBEQ&q='
    let config = {
        method: 'get',
        url: baseUrl + apiKey + wordToTranslate + '&target=' + srcMapping[language],
        headers: {}
    };

    axios.request(config)
        .then((response) => {
            let translated = response.data.data.translations[0]["translatedText"]
            fromLocalStorage[wordToTranslate][language] = translated
            localStorage.setItem(wordToTranslate, JSON.stringify(fromLocalStorage))
        })
        .catch((error) => {
            console.log(error);
        });
}

const translateData = {
    "My Way.mp3": "{\"My Way.mp3\":{\"Mandarin\":\"我的路.mp3\"}}",
    "Example - Sam": "{\"Example - Sam\":{\"Mandarin\":\"示例 - 山姆\"}}",
    "Start": "{\"Start\":{\"Mandarin\":\"开始\"}}",
    "Justine Clarke - Imagination.mp3": "{\"Justine Clarke - Imagination.mp3\":{\"Mandarin\":\"贾斯汀·克拉克 - Imagination.mp3\"}}",
    "Track 2": "{\"Track 2\":{\"Mandarin\":\"轨道 2\"}}",
    "Patty's First Day of School": "{\"Patty's First Day of School\":{\"Mandarin\":\"帕蒂上学的第一天\"}}",
    "Maya": "{\"Maya\":{\"Mandarin\":\"玛雅人\"}}",
    "BOOKPLAY": "{\"BOOKPLAY\":{\"Mandarin\":\"书本\"}}",
    "The Hold My Hand Rap - Slower Version": "{\"The Hold My Hand Rap - Slower Version\":{\"Mandarin\":\"握住我的手说唱 - 慢速版本\"}}",
    "General Quiz - Copy": "{\"General Quiz - Copy\":{\"Mandarin\":\"一般测验 - 副本\"}}",
    "Japanese": "{\"Japanese\":{\"Mandarin\":\"日本人\"}}",
    "Australian Phonics Song.mp3": "{\"Australian Phonics Song.mp3\":{\"Mandarin\":\"澳大利亚拼音歌曲.mp3\"}}",
    "Videos & Movies": "{\"Videos & Movies\":{\"Mandarin\":\"影片\"}}",
    "Freeze Dance Freeze Song Freeze Dance for Kids Music for Kids The Kiboomers.mp3": "{\"Freeze Dance Freeze Song Freeze Dance for Kids Music for Kids The Kiboomers.mp3\":{\"Mandarin\":\"Freeze Dance Freeze Song Freeze Dance for Kids 儿童音乐 The Kiboomers.mp3\"}}",
    "balang ganha bungangbu burrami.mp3": "{\"balang ganha bungangbu burrami.mp3\":{\"Mandarin\":\"Balang ganha bungangbu burrami.mp3\"}}",
    "Music": "{\"Music\":{\"Mandarin\":\"音乐\"}}",
    "Connect": "{\"Connect\":{\"Mandarin\":\"连接\"}}",
    "Calendar based Service Scheduling": "{\"Calendar based Service Scheduling\":{\"Mandarin\":\"基于日历的服务调度\"}}",
    "Sudoku": "{\"Sudoku\":{\"Mandarin\":\"数独\"}}",
    "Frank": "{\"Frank\":{\"Mandarin\":\"坦率\"}}",
    "小星": "{\"小星\":{\"Mandarin\":\"小星\"}}",
    "Dazzle Duckling": "{\"Dazzle Duckling\":{\"Mandarin\":\"炫小鸭\"}}",
    "Select Language": "{\"Select Language\":{\"Mandarin\":\"选择语言\"}}",
    "STARTMOTION": "{\"STARTMOTION\":{\"Mandarin\":\"启动运动\"}}",
    "Safety": "{\"Safety\":{\"Mandarin\":\"安全\"}}",
    "Choose Video Call Type": "{\"Choose Video Call Type\":{\"Mandarin\":\"选择视频通话类型\"}}",
    "Shapes": "{\"Shapes\":{\"Mandarin\":\"形状\"}}",
    "CommunicationMode": "{\"CommunicationMode\":{\"Mandarin\":\"通讯方式\"}}",
    "Scrabble": "{\"Scrabble\":{\"Mandarin\":\"拼字游戏\"}}",
    "Service Scheduling And Management": "{\"Service Scheduling And Management\":{\"Mandarin\":\"服务调度与管理\"}}",
    "MUSICLIST": "{\"MUSICLIST\":{\"Mandarin\":\"音乐列表\"}}",
    "Teacher": "{\"Teacher\":{\"Mandarin\":\"老师\"}}",
    "Calendar": "{\"Calendar\":{\"Mandarin\":\"日历\"}}",
    "Appointment": "{\"Appointment\":{\"Mandarin\":\"预约\"}}",
    "Cast to TV": "{\"Cast to TV\":{\"Mandarin\":\"投射到电视\"}}",
    "Before We Cross The Street": "{\"Before We Cross The Street\":{\"Mandarin\":\"在我们过马路之前\"}}",
    "Sam": "{\"Sam\":{\"Mandarin\":\"他自己\"}}",
    "Toileting Hygiene": "{\"Toileting Hygiene\":{\"Mandarin\":\"如厕卫生\"}}",
    "A Safe Place to Play": "{\"A Safe Place to Play\":{\"Mandarin\":\"一个安全的地方玩\"}}",
    "Service Pattern": "{\"Service Pattern\":{\"Mandarin\":\"服务模式\"}}",
    "Penny and Marie": "{\"Penny and Marie\":{\"Mandarin\":\"佩妮和玛丽\"}}",
    "Select Action": "{\"Select Action\":{\"Mandarin\":\"选择动作\"}}",
    "Helmet Rap": "{\"Helmet Rap\":{\"Mandarin\":\"头盔说唱\"}}",
    "Sample MP4 Video File for Testing": "{\"Sample MP4 Video File for Testing\":{\"Mandarin\":\"用于测试的示例 MP4 视频文件\"}}",
    "Chinese folk music": "{\"Chinese folk music\":{\"Mandarin\":\"中国民族音乐\"}}",
    "RABI": "{\"RABI\":{\"Mandarin\":\"一半\"}}",
    "Buckle Up": "{\"Buckle Up\":{\"Mandarin\":\"系好安全带\"}}",
    "General Quiz": "{\"General Quiz\":{\"Mandarin\":\"一般测验\"}}",
    "When ThingleToodle Came to School": "{\"When ThingleToodle Came to School\":{\"Mandarin\":\"当 ThingleToodle 来到学校\"}}",
    "Technology Support": "{\"Technology Support\":{\"Mandarin\":\"技术支持\"}}",
    "Bingo": "{\"Bingo\":{\"Mandarin\":\"答对了\"}}",
    "STARTTIMER": "{\"STARTTIMER\":{\"Mandarin\":\"启动定时器\"}}",
    "Copy Cat": "{\"Copy Cat\":{\"Mandarin\":\"山寨\"}}",
    "Stop": "{\"Stop\":{\"Mandarin\":\"停止\"}}",
    "心心": "{\"心心\":{\"Mandarin\":\"心心\"}}",
    "Donimo Addition": "{\"Donimo Addition\":{\"Mandarin\":\"多尼莫加法\"}}",
    "voice command": "{\"voice command\":{\"Mandarin\":\"语音控制\"}}",
    "Jingle Bell Rock": "{\"Jingle Bell Rock\":{\"Mandarin\":\"铃儿响叮当\"}}",
    "Harry": "{\"Harry\":{\"Mandarin\":\"哈利\"}}",
    "Quizzes": "{\"Quizzes\":{\"Mandarin\":\"测验\"}}",
    "Home": "{\"Home\":{\"Mandarin\":\"家\"}}",
    "Select a song": "{\"Select a song\":{\"Mandarin\":\"选择一首歌曲\"}}",
    "Scan Devices": "{\"Scan Devices\":{\"Mandarin\":\"扫描设备\"}}",
    "Smart Home": "{\"Smart Home\":{\"Mandarin\":\"智能家居\"}}",
    "Emotional Well Being": "{\"Emotional Well Being\":{\"Mandarin\":\"情感福祉\"}}",
    "localhost": "{\"localhost\":{\"Mandarin\":\"本地主机\"}}",
    "James": "{\"James\":{\"Mandarin\":\"詹姆士\"}}",
    "Row Row Row Your Boat with Lyrics | LIV Kids Nursery Rhymes and Songs | HD": "{\"Row Row Row Your Boat with Lyrics | LIV Kids Nursery Rhymes and Songs | HD\":{\"Mandarin\":\"Row Row Row Your Boat 歌词LIV 儿童童谣和歌曲 |高清\"}}",
    "Fire Safety Tools": "{\"Fire Safety Tools\":{\"Mandarin\":\"消防安全工具\"}}",
    "When I'm Feeling Happy by Trace Moroney - Read Aloud.mp3": "{\"When I'm Feeling Happy by Trace Moroney - Read Aloud.mp3\":{\"Mandarin\":\"当我感到快乐时 Trace Moroney - Read Aloud.mp3\"}}",
    "Select a book": "{\"Select a book\":{\"Mandarin\":\"选择一本书\"}}",
    "Submit": "{\"Submit\":{\"Mandarin\":\"提交\"}}",
    "Call": "{\"Call\":{\"Mandarin\":\"称呼\"}}",
    "Cast to TV with TV audio": "{\"Cast to TV with TV audio\":{\"Mandarin\":\"通过电视音频投射到电视\"}}",
    "End Call": "{\"End Call\":{\"Mandarin\":\"结束通话\"}}",
    "Cast to TV with Matilda audio": "{\"Cast to TV with Matilda audio\":{\"Mandarin\":\"使用 Matilda 音频投射到电视\"}}",
    "MUSICPLAY": "{\"MUSICPLAY\":{\"Mandarin\":\"音乐播放\"}}",
    "Log ID": "{\"Log ID\":{\"Mandarin\":\"日志编号\"}}",
    "Add Books": "{\"Add Books\":{\"Mandarin\":\"添加书籍\"}}",
    "The Very Busy Spider": "{\"The Very Busy Spider\":{\"Mandarin\":\"非常忙碌的蜘蛛\"}}",
    "NEWSREAD": "{\"NEWSREAD\":{\"Mandarin\":\"新闻阅读\"}}",
    "Track 6": "{\"Track 6\":{\"Mandarin\":\"轨道 6\"}}",
    "Technology Tools": "{\"Technology Tools\":{\"Mandarin\":\"技术工具\"}}",
    "Matlda1": "{\"Matlda1\":{\"Mandarin\":\"玛特达1\"}}",
    "The Ugly Duckling": "{\"The Ugly Duckling\":{\"Mandarin\":\"丑小鸭\"}}",
    "Video Call Recording": "{\"Video Call Recording\":{\"Mandarin\":\"视频通话录音\"}}",
    "At The Store": "{\"At The Store\":{\"Mandarin\":\"在商店\"}}",
    "Which is it": "{\"Which is it\":{\"Mandarin\":\"是哪一个\"}}",
    "Add Video": "{\"Add Video\":{\"Mandarin\":\"添加视频\"}}",
    "Ugly Duckling": "{\"Ugly Duckling\":{\"Mandarin\":\"丑小鸭\"}}",
    "AUDIOBOOKSEARCH": "{\"AUDIOBOOKSEARCH\":{\"Mandarin\":\"有声读物搜索\"}}",
    "User ID": "{\"User ID\":{\"Mandarin\":\"用户身份\"}}",
    "Telehealth": "{\"Telehealth\":{\"Mandarin\":\"远程医疗\"}}",
    "Action": "{\"Action\":{\"Mandarin\":\"行动\"}}",
    "Walking Down The Street": "{\"Walking Down The Street\":{\"Mandarin\":\"走在街上\"}}",
    "老師": "{\"老師\":{\"Mandarin\":\"老师\"}}",
    "Jack's Shopping List": "{\"Jack's Shopping List\":{\"Mandarin\":\"杰克的购物清单\"}}",
    "Derek": "{\"Derek\":{\"Mandarin\":\"德里克\"}}",
    "12345 Once I Caught A Fish Alive _ Nursery Rhyme _ Hooplakidz.mp3": "{\"12345 Once I Caught A Fish Alive _ Nursery Rhyme _ Hooplakidz.mp3\":{\"Mandarin\":\"12345 曾经我钓到一条活鱼_童谣_Hooplakidz.mp3\"}}",
    "Counting 2 Dollar Coints": "{\"Counting 2 Dollar Coints\":{\"Mandarin\":\"数 2 美元硬币\"}}",
    "unknown": "{\"unknown\":{\"Mandarin\":\"未知\"}}",
    "Yazan": "{\"Yazan\":{\"Mandarin\":\"经过\"}}",
    "house that jack built": "{\"house that jack built\":{\"Mandarin\":\"杰克建造的房子\"}}",
    "VideoFile": "{\"VideoFile\":{\"Mandarin\":\"视频文件\"}}",
    "媽媽": "{\"媽媽\":{\"Mandarin\":\"妈妈\"}}",
    "Books": "{\"Books\":{\"Mandarin\":\"图书\"}}",
    "Please Enter ID to call": "{\"Please Enter ID to call\":{\"Mandarin\":\"请输入电话号码\"}}",
    "Receptionist": "{\"Receptionist\":{\"Mandarin\":\"接待员\"}}",
    "Scan Cast Devices": "{\"Scan Cast Devices\":{\"Mandarin\":\"扫描投射设备\"}}",
    "5 little ducks": "{\"5 little ducks\":{\"Mandarin\":\"5只小鸭子\"}}",
    "Head Shoulders Knees & Toes (Sing It).mp3": "{\"Head Shoulders Knees & Toes (Sing It).mp3\":{\"Mandarin\":\"头肩膀膝盖\"}}",
    "Date": "{\"Date\":{\"Mandarin\":\"日期\"}}",
    "Good Morning Song": "{\"Good Morning Song\":{\"Mandarin\":\"早安歌\"}}",
    "Brown Bear What Do You See": "{\"Brown Bear What Do You See\":{\"Mandarin\":\"棕熊你看到了什么\"}}",
    "Count and Add": "{\"Count and Add\":{\"Mandarin\":\"计数并添加\"}}",
    "The Wiggles Taba Naba Style! (feat. Christine Anu) Kids Songs.mp3": "{\"The Wiggles Taba Naba Style! (feat. Christine Anu) Kids Songs.mp3\":{\"Mandarin\":\"Wiggles Taba Naba 风格！ (feat. Christine Anu) 儿童歌曲.mp3\"}}",
    "Means of Transportation": "{\"Means of Transportation\":{\"Mandarin\":\"交通方式\"}}",
    "Where in the World": "{\"Where in the World\":{\"Mandarin\":\"在世界的哪个地方\"}}",
    "Interaction Logs": "{\"Interaction Logs\":{\"Mandarin\":\"交互日志\"}}",
    "John": "{\"John\":{\"Mandarin\":\"约翰\"}}",
    "Scheduler": "{\"Scheduler\":{\"Mandarin\":\"调度器\"}}",
    "Big rain coming": "{\"Big rain coming\":{\"Mandarin\":\"大雨来了\"}}",
    "Configuration": "{\"Configuration\":{\"Mandarin\":\"配置\"}}",
    "Brain Training": "{\"Brain Training\":{\"Mandarin\":\"大脑训练\"}}",
    "Entertainment": "{\"Entertainment\":{\"Mandarin\":\"娱乐\"}}",
    "how are you": "{\"how are you\":{\"Mandarin\":\"你好吗\"}}",
    "Cheeky Tunes - Children's Music - Ngaya Naba Aboriginal songs for children.mp3": "{\"Cheeky Tunes - Children's Music - Ngaya Naba Aboriginal songs for children.mp3\":{\"Mandarin\":\"Cheeky Tunes - 儿童音乐 - Ngaya Naba 原住民儿童歌曲.mp3\"}}",
    "The Hold My Hand Rap": "{\"The Hold My Hand Rap\":{\"Mandarin\":\"握住我的手说唱\"}}",
    "STOPTIMER": "{\"STOPTIMER\":{\"Mandarin\":\"停止定时器\"}}",
    "Health and Fitness": "{\"Health and Fitness\":{\"Mandarin\":\"健康和健身\"}}",
    "Add Music": "{\"Add Music\":{\"Mandarin\":\"添加音乐\"}}",
    "迪迪": "{\"迪迪\":{\"Mandarin\":\"迪迪\"}}",
    "[object Object]": "{\"[object Object]\":{\"Mandarin\":\"[对象对象]\"}}",
    "Games": "{\"Games\":{\"Mandarin\":\"游戏\"}}",
    "Cookie Please": "{\"Cookie Please\":{\"Mandarin\":\"请饼干\"}}",
    "Superstar": "{\"Superstar\":{\"Mandarin\":\"超级明星\"}}",
    "YOUTUBEPLAYSOLO": "{\"YOUTUBEPLAYSOLO\":{\"Mandarin\":\"YouTube独奏\"}}",
    "A Ride in The Car": "{\"A Ride in The Car\":{\"Mandarin\":\"坐车\"}}",
    "Songs": "{\"Songs\":{\"Mandarin\":\"歌曲\"}}",
    "Counting": "{\"Counting\":{\"Mandarin\":\"数数\"}}",
    "dr froster": "{\"dr froster\":{\"Mandarin\":\"弗罗斯特博士\"}}",
    "Facial Expression": "{\"Facial Expression\":{\"Mandarin\":\"表情\"}}",
    "綠豆": "{\"綠豆\":{\"Mandarin\":\"绿豆\"}}",
    "pageLanguage": "English",
    "ROW ROW ROW YOUR BOAT _ Classic Nursery Rhymes _ English Songs For Kids _ Nursery Rhymes TV.mp3": "{\"ROW ROW ROW YOUR BOAT _ Classic Nursery Rhymes _ English Songs For Kids _ Nursery Rhymes TV.mp3\":{\"Mandarin\":\"ROW ROW ROW YOUR BOAT_经典童谣_英文儿歌_童谣TV.mp3\"}}",
    "[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]": "{\"[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]\":{\"Mandarin\":\"[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象]\"}}",
    "VIDEOCAST": "{\"VIDEOCAST\":{\"Mandarin\":\"视频广播\"}}",
    "twinkle star": "{\"twinkle star\":{\"Mandarin\":\"闪烁的星星\"}}",
    "Quiz": "{\"Quiz\":{\"Mandarin\":\"测验\"}}",
    "Analytics": "{\"Analytics\":{\"Mandarin\":\"分析\"}}",
    "Jeff's Bike": "{\"Jeff's Bike\":{\"Mandarin\":\"杰夫的自行车\"}}",
    "Time": "{\"Time\":{\"Mandarin\":\"时间\"}}",
    "Description": "{\"Description\":{\"Mandarin\":\"描述\"}}",
    "YOUTUBESEARCH": "{\"YOUTUBESEARCH\":{\"Mandarin\":\"优酷搜索\"}}",
    "Famous Faces": "{\"Famous Faces\":{\"Mandarin\":\"名人面孔\"}}",
    "Service Preference": "{\"Service Preference\":{\"Mandarin\":\"服务偏好\"}}",
    "Exercise": "{\"Exercise\":{\"Mandarin\":\"锻炼\"}}",
    "SELECTSERVICE": "{\"SELECTSERVICE\":{\"Mandarin\":\"选择服务\"}}",
    "Healthy vs Unhealthy": "{\"Healthy vs Unhealthy\":{\"Mandarin\":\"健康与不健康\"}}",
    "speech": "{\"speech\":{\"Mandarin\":\"演讲\"}}",
    "Analytics: Service Pattern": "{\"Analytics: Service Pattern\":{\"Mandarin\":\"分析：服务模式\"}}",
    "Child": "{\"Child\":{\"Mandarin\":\"孩子\"}}",
    "Justine Clarke - Dancing Face (Official Video).mp3": "{\"Justine Clarke - Dancing Face (Official Video).mp3\":{\"Mandarin\":\"Justine Clarke - Dancing Face（官方视频）.mp3\"}}",
    "BOOKLIST": "{\"BOOKLIST\":{\"Mandarin\":\"书单\"}}",
    "Jack's shopping list": "{\"Jack's shopping list\":{\"Mandarin\":\"杰克的购物清单\"}}",
    "Activity": "{\"Activity\":{\"Mandarin\":\"活动\"}}",
    "Mandarin": "{\"Mandarin\":{\"Mandarin\":\"普通话\"}}",
    "Title": "{\"Title\":{\"Mandarin\":\"标题\"}}",
    "Old MacDonald Had A Farm | Nursery Rhymes | Super Simple Songs": "{\"Old MacDonald Had A Farm | Nursery Rhymes | Super Simple Songs\":{\"Mandarin\":\"老麦克唐纳有一个农场 |儿歌 |超级简单的歌曲\"}}",
    "Words and Letters": "{\"Words and Letters\":{\"Mandarin\":\"文字和字母\"}}",
    "Penny Ann Marie": "{\"Penny Ann Marie\":{\"Mandarin\":\"潘妮·安·玛丽\"}}",
    "Tammy's Day Out": "{\"Tammy's Day Out\":{\"Mandarin\":\"塔米的一天\"}}",
    "Friends And Relatives": "{\"Friends And Relatives\":{\"Mandarin\":\"朋友和亲戚\"}}",
    "Talk With Matilda": "{\"Talk With Matilda\":{\"Mandarin\":\"与玛蒂尔达交谈\"}}",
    "YOUTUBEPLAY": "{\"YOUTUBEPLAY\":{\"Mandarin\":\"优酷播放\"}}",
    "U3A  Partnerships": "{\"U3A  Partnerships\":{\"Mandarin\":\"U3A 合作伙伴\"}}",
    "Bring it": "{\"Bring it\":{\"Mandarin\":\"带上它\"}}",
    "ocr": "{\"ocr\":{\"Mandarin\":\"文字识别\"}}",
    "Simon Says Song for Children by Patty Shukla.mp3": "{\"Simon Says Song for Children by Patty Shukla.mp3\":{\"Mandarin\":\"Simon Says Song for Children by Patty Shukla.mp3\"}}",
    "Analytics: Interactions": "{\"Analytics: Interactions\":{\"Mandarin\":\"分析：交互\"}}",
    "Chess": "{\"Chess\":{\"Mandarin\":\"棋\"}}",
    "WebApp": "{\"WebApp\":{\"Mandarin\":\"网络应用程序\"}}",
    "undefined": "{\"undefined\":{\"Mandarin\":\"不明确的\"}}",
    "English": "{\"English\":{\"Mandarin\":\"英语\"}}",
    "React Data: 1": "{\"React Data: 1\":{\"Mandarin\":\"反应数据：1\"}}",
    "Is That True": "{\"Is That True\":{\"Mandarin\":\"真的吗\"}}",
    "One, Two, Buckle My Shoe - The Best Songs for Children _ LooLoo Kids.mp3": "{\"One, Two, Buckle My Shoe - The Best Songs for Children _ LooLoo Kids.mp3\":{\"Mandarin\":\"一，二，扣我的鞋 - 最好听的儿童歌曲_LooLoo Kids.mp3\"}}",
    "pat a cake": "{\"pat a cake\":{\"Mandarin\":\"拍个蛋糕\"}}",
    "The Very Hungry Caterpillar": "{\"The Very Hungry Caterpillar\":{\"Mandarin\":\"好饿的毛毛虫\"}}"
}

export default { translateString }