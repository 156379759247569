import React, { useState } from 'react';
import TitleBar from '../Common/TitleBar';

const QuizUploadPage = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [message, setMessage] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event) => {
		setMessage('');
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('quiz', selectedFile);

		fetch(
			'http://localhost:8080/quiz/upload',
			{
				method: 'POST',
				body: formData,
			}
		)
			// .then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
				setMessage("Uploaded sucessfully");

			})
			.catch((error) => {
				console.error('Error:', error);
				setMessage("Upload error!");

			});
	};


	return (
		<div>
			<TitleBar title={"Quiz Upload"} parentNode={"/technology-tools"}/>
			<input type="file" name="quiz" onChange={changeHandler} />
			{message ? (
				<div>
					<p>{message}</p>

				</div>
			) : (
				<p></p>
			)}

			{isFilePicked ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}
			<div>
				<button onClick={handleSubmission}>Submit</button>
			</div>
		</div>
	)
}

export default QuizUploadPage;