import React, { useEffect, useState } from "react";
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import styled from 'styled-components';
import TitleBar from '../Common/TitleBar';
import { Button } from "reactstrap";
import { MenuItem, Select } from "@material-ui/core";

const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const Translation = () => {
    const [status, setStatus] = useState("");
    const [isStart, setIsStart] = useState(true);

    useEffect(() => {
        var api = `{"api":"getStatus","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });

        socket.onmessage = (message) => {
            console.log('Translation ', message.data);
            if ((message.data) && (message.data.toString().includes("Stop"))) {
                if (!message.data.toString().includes("Cast")) {
                    setStatus(message.data);
                }
            }
        }
    }, []);

    const handleEnToCh = (e) => {
        localStorage.setItem("TRANSLATION_LANGUAGE", "EnToCh");
        var api = `{"api":"enToChTranslation","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const handleChToEn = (e) => {
        localStorage.setItem("TRANSLATION_LANGUAGE", "ChToEn");
        var api = `{"api":"chToEnTranslation","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const handleStart = (e) => {
        var api = `{"api":"startTranslation","name": "${name}", "language": "${selectedLanguage}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setStatus("RUNNING");
        setIsStart(false);
    }

    const handleStop = (e) => {
        var api = `{"api":"stopTranslation","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setStatus("STOPPED");
        setIsStart(true);
    }

    const handlePause = (e) => {
        var api = `{"api":"getPauseTrans","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setStatus("PAUSED");
        setIsStart(false);
    }

    const handleResume = (e) => {
        let currLang = localStorage.getItem("TRANSLATION_LANGUAGE");
        var api = `{"api":"getResumeTrans","name": "${name}", "currLang": "${currLang}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setStatus("RUNNING");
        setIsStart(false);
    }

    const handleLastAudio = (e) => {
        var api = `{"api":"getLastAudioTrans","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const [selectedLanguage, setSelectedLanguage] = useState("mandarin");

    const onChangeLanguage = (e) => {
        setSelectedLanguage(e.target.value);
    }

    const [languages, setLanguages] = useState([
        { 'label': 'Mandarin', 'value': 'mandarin' },
        { 'label': 'Japanese', 'value': 'japanese' },
        { 'label': 'Greek', 'value': 'greek' },
        { 'label': 'Italian', 'value': 'italian' },
        { 'label': 'Russian', 'value': 'russian' },
        { 'label': 'Serbian', 'value': 'serbian' },
        { 'label': 'Cantonese', 'value': 'cantonese' },
        { 'label': 'Korean', 'value': 'korean' },
        { 'label': 'Arabic', 'value': 'arabic' },
        { 'label': 'Hindi', 'value': 'hindi' },
        { 'label': 'Polish', 'value': 'polish' },
        { 'label': 'Sinhala', 'value': 'sinhala' },
        { 'label': 'Filipino', 'value': 'filipino' },
        { 'label': 'Macedonian', 'value': 'macedonian' }
    ]);

    // Build Page
    return (
        <Styles>
            <TitleBar title={'Translation'} parentNode={"/technology-tools"} />
            <h4>Translation Controls</h4>
            <h5>Status: &nbsp;&nbsp;<i>{status}</i></h5>
            <Select value={selectedLanguage} id="select-content" fullWidth onChange={onChangeLanguage}>
                {languages.map((categoria, i) => (<MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>))}
            </Select>
            <br />
            <br/>
            {
                isStart ?
                    ""
                    :
                    (
                        <div>
                            <b>Toggle Translation</b><br />
                            <Button size="small" variant="outlined" color="secondary" onClick={handleEnToCh}> {"English to " + selectedLanguage} </Button>&nbsp;
                            <Button size="small" variant="outlined" color="secondary" onClick={handleChToEn}> {selectedLanguage + " to English"} </Button>
                            <br />
                            <br />
                        </div>
                    )
            }
            <Button size="small" variant="outlined" color="primary" onClick={handleStart}>
                {isStart ? "Start" : "Restart"}
            </Button>&nbsp;
            {!isStart && <Button size="small" variant="outlined" color="primary" onClick={handlePause}>Pause</Button>}
            {status === "PAUSED" && <Button size="small" variant="outlined" color="primary" onClick={handleResume}>Resume</Button>}
            {status === "RUNNING" && <Button size="small" variant="outlined" color="primary" onClick={handleStop}>Stop</Button>}
            <Button size="small" variant="outlined" color="primary" onClick={handleLastAudio}>Last Audio</Button>
        </Styles>
    )
}

export default Translation;