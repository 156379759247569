import React, { Component } from "react";
import Iframe from 'react-iframe'
import TitleBar from "../Common/TitleBar";
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid } from "@material-ui/core";
import { Autorenew, Lock, Wifi } from "@material-ui/icons";


function CardData({ wifiData }) {
    return (
        <>
            <br /><br />
            <Grid container spacing={2}>
                {wifiData.map((data, i) => {
                    return (
                        <Grid xs={3}>
                            <Card>
                                <CardContent>
                                    <h5><strong>WiFi</strong></h5>
                                    <CardMedia><Wifi fontSize={"large"} />&nbsp;&nbsp;{data["ssid"]}</CardMedia>
                                    <CardMedia><Lock fontSize={"large"}/>&nbsp;&nbsp;{data["#psk"]}</CardMedia>
                                </CardContent>
                                <CardActionArea>
                                    &nbsp; &nbsp; <Button onClick={connect} value={i}>Connect</Button>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

const connect = (e) => {
    console.log("On Connect ", e.currentTarget.value);
    var api = `{"api":"connectWifi", "idx":"${e.currentTarget.value.toString()}","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
}

const WifiSetting = () => {
    const [wifis, setWifis] = useState([]);
    const [topMsg, setTopMsg] = useState("");

    useEffect(() => {
        var api = `{"api":"wifiList", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }, []);

    socket.onmessage = (message) => {
        console.log("MESSAGE ", message.data);
        if ((message.data) && (message.data.toString().includes("wifiData"))) {
            try {
                var obj = JSON.parse(message.data);
                setWifis(obj.wifiData);
            } catch (error) {
                console.log("Error ", error);
            }
        }
    }

    const handleReboot = () => {
        var api = `{"api":"rebootMatilda", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        alert("Reboot Successful")
    }

    return (
        <>
            <TitleBar title={"WiFi Setting"} />
            <Button onClick={handleReboot}><Autorenew /> Reboot</Button>
            <CardData wifiData={wifis} />
        </>
    );
}

export default WifiSetting;