import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ImageQuiz from "./ImageQuiz";
import AuthService from "../../services/auth.service";
import TitleBar from "../Common/TitleBar";
import TranslationService from "../../services/translation.service";
import { waitForSocketConnection, socket, sendMessage, name } from "../../socket";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1D3557',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#A8DADC',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: '#1D3557',
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function QuizList() {

  const [selectquiz, setQuizId] = useState('');
  const [isSelected, setSelected] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    var message = `{"action":"getQuizList", "name":"${name}" }`;
    waitForSocketConnection(socket, () => { sendMessage(message) });

    var mylist = [];
    socket.onmessage = (message) => {
      if ((message.data) && (message.data.toString().includes("At The Store.quiz"))) {
        var obj = JSON.parse(message.data);
        obj.quiz.map((item, i) => {
          mylist.push({
            url: '/images/button.png',
            title: item,
            width: '30%',
          });
        });
        setData(mylist);
      }
    }
  }, []);


  const imageClick = (title) => {
    setSelected(true);
    setQuizId(title + '.quiz');


  }

  const handler = (val) => {
    setSelected(val);
  }

  const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }


  if (isSelected) {
    //alert(selectquiz);
    //setSelected(false);
    return (<div className={classes.root}>{<ImageQuiz quizid={selectquiz.replace(".quiz", "")} handler={handler} />}</div>);

  } else
    return (
      <>
        <TitleBar title={computeTitle("Quiz")} />
        <div className={classes.root}>
          {data.map((image) => (
            <ButtonBase
              onClick={() => imageClick(image.title)}
              focusRipple
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: image.width,
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {computeTitle(image.title)}
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
      </>
    );
}
